import { useOrganizationContext } from 'page/Organization/context/OrganizationDataProvider';
import { Member } from 'types';
import { TableRow } from './TableRow';
export const TableBody = () => {
  const { organizationData } = useOrganizationContext();
  const { members } = organizationData || {};
  return (
    <tbody>
      {Array.isArray(members) && members.map((item: Member, i: number) => <TableRow member={item} /* isSuperuser={true} */ key={`${item.id} - ${i}`} />)}
    </tbody>
  );
};
