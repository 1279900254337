import React, { FC, useContext, useEffect, useState } from 'react';

// Components
import { Icon } from 'atoms';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import { RoomPillar } from 'page/Editor/configuration/RoomPillar';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Context
interface Props {}

type Pillar = {
  width: number;
  depth: number;
};

export const Pillars: FC<Props> = () => {
  const { selection, setErrorAlert, setRoomView } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  const [pillar, setPillar] = useState<Pillar>({
    width: selection instanceof RoomPillar ? selection.getWidth() : 0,
    depth: selection instanceof RoomPillar ? selection.getDepth() : 0
  });

  useEffect(() => {
    if (selection && selection instanceof RoomPillar) {
      setPillar({ width: selection instanceof RoomPillar ? selection.getWidth() : 0, depth: selection instanceof RoomPillar ? selection.getDepth() : 0 });
    }
  }, [selection]);

  return (
    <div key="room-pillars-menu">
      <ContextMenu mode="back" onClick={() => setRoomView('home')} />
      {selection && selection instanceof RoomPillar ? (
        <>
          <div className="Submenu-Headline"> {Localizations['pillars'][lang]}</div>
          <div key={`room-pillars-submenu`}>
            {/* <div className="Submenu-Attribute">
          <div>
            <RadioButton
              checked={pillar.shape === 'edgy'}
              label={Localizations['square'][lang]}
              onChange={() => {
                setPillar({ ...pillar, shape: 'edgy' });
              }}
              value={pillar.shape}
            />
          </div>
          <div style={{ marginLeft: '3rem' }}>
            <RadioButton
              checked={pillar.shape === 'round'}
              label={Localizations['round'][lang]}
              onChange={() => {
                setPillar({ ...pillar, shape: 'round' });
              }}
              value={pillar.shape}
            />
          </div>
        </div> */}
            <div className="Submenu-Attribute">
              <label className="Submenu-Label">
                <input
                  className="Submenu-Input"
                  min={100}
                  onChange={event => {
                    const val = parseInt(event.target.value);
                    if (val >= 0) {
                      setPillar({
                        ...pillar,
                        width: val
                      });
                      if (selection instanceof RoomPillar) {
                        selection.setWidth(val);
                      }
                    }
                  }}
                  step={100}
                  type="number"
                  value={pillar.width}
                />
                {Localizations['width'][lang]} (mm)
              </label>
            </div>
            <div className="Submenu-Attribute">
              <label className="Submenu-Label">
                <input
                  className="Submenu-Input"
                  min={100}
                  onChange={event => {
                    const val = parseInt(event.target.value);
                    if (val >= 0) {
                      setPillar({
                        ...pillar,
                        depth: val
                      });
                      if (selection instanceof RoomPillar) {
                        selection.setDepth(val);
                      }
                    }
                  }}
                  step={100}
                  type="number"
                  value={pillar.depth}
                />
                {Localizations['depth'][lang]} (mm)
              </label>
            </div>
            <div className="DeviceDetails-Small-Bottom">
              <Icon
                color="medium"
                onClick={() => {
                  setErrorAlert(['delete']);
                }}
                size="1.5rem"
                type="trash"
                stroke={3}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="mt-4 text-sm bold">{Localizations['selectPillar'][lang]}</div>
      )}
    </div>
  );
};
