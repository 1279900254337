const LLogin = {
  "password": {
    "fr": "mot de passe",
    "de": "Passwort",
    "en": "password",
    "cz": "Heslo"
  },
  "register": {
    "fr": "S'inscrire",
    "de": "Registrierung beantragen",
    "en": "Apply registration",
    "cz": "Požádat o registraci"
  },
  "customerNumber": {
    "fr": "Numéro de client",
    "de": "Kundennummer",
    "en": "Customer Number",
    "cz": "Zákaznické číslo"
  },
  "passwordforgotten": {
    "fr": "Mot de passe oublié ?",
    "de": "Passwort vergessen?",
    "en": "Forgot Password?",
    "cz": "Zapomněli jste heslo?"
  },
  "login": {
    "fr": "Connexion",
    "de": "Anmelden",
    "en": "Login",
    "cz": "Přihlásit"
  },
  "logout": {
    "fr": "déconnexion",
    "de": "Abmelden",
    "en": "Logout",
    "cz": "Odhlásit"
  },
  "notFound": {
    "fr": "introuvable",
    "de": "nicht gefunden",
    "en": "not found",
    "cz": "nenalezeno"
  },
  "loginError": {
    "fr": "erreur de connexion",
    "de": "Login fehlgeschlagen",
    "en": "Could not log in",
    "cz": "Přihlášení se nezdařilo"
  },
  "resetPassword": {
    "fr": "réinitialiser le mot de passe",
    "de": "Passwort zurücksetzen",
    "en": "Reset password",
    "cz": "Resetovat heslo"
  },
  "sendPasswordEmail": {
    "fr": "Envoyer le mot de passe par courriel",
    "de": "Passwort versenden",
    "en": "Send password email",
    "cz": "Send password email"
  },
  "registerSent": {
    "fr": "Envoi de la demande. Vous recevrez de plus amples informations par courrier électronique.",
    "de": "Wir haben Ihre Anfrage erhalten. Nach Bearbeitung erhalten Sie weitere Informationen per E-Mail.",
    "en": "Application sent. You will receive further information via email.",
    "cz": "Application sent. You will receive further information via email."
  },
  "registerError": {
    "fr": "Un problème s'est produit, veuillez réessayer.",
    "de": "Etwas ist falsch gelaufen, bitte versuchen Sie es erneut.",
    "en": "Something went wrong, please try again.",
    "cz": "Something went wrong, please try again."
  },
  "createNewPassword": {
    "fr": "Créer un mot de passe",
    "de": "Neues Passwort",
    "en": "Create password",
    "cz": "Nové heslo"
  },
  "invalidPassword": {
    "fr": "Les mots de passe doivent comporter au moins 9 caractères, des lettres minuscules et majuscules, un chiffre et un caractère spécial.",
    "de": "Passwort muss mindestens 9 Zeichen lang sein und mindestens einen Klein- und Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten",
    "en": "Passwords must be at least 9 characters long, contain lower- and uppercase letters, one digit and one special character.",
    "cz": "Heslo musí mít minimálně 9 znaků a obsahovat minimálně jedno malé a velké písmeno, jednu číslici a jeden speciální znak"
  },
  "passwordsDontMatch": {
    "fr": "Les mots de passe ne correspondent pas",
    "de": "Passwörter stimmen nicht überein",
    "en": "Passwords do not match",
    "cz": "Hesla nesouhlasí"
  },
  "changePasswordSuccess": {
    "fr": "Mot de passe modifié",
    "de": "Passwort geändert",
    "en": "Password changed",
    "cz": "Heslo změněno"
  },
  "changePasswordError": {
    "fr": "Le mot de passe n'a pas pu être modifié",
    "de": "Passwort konnte nicht geändert werden",
    "en": "Password could not be changed",
    "cz": "Heslo nebylo možné změnit"
  },
  "profileUpdated": {
    "fr": "Profil mis à jour",
    "de": "Profil geändert",
    "en": "Profile updated",
    "cz": "Profil změněn"
  },
  "profileUpdateError": {
    "fr": "Impossible de mettre à jour le profil",
    "de": "Profil konnte nicht geändert werden",
    "en": "Could not update profile",
    "cz": "Profil nebylo možné změnit"
  }
};

export default LLogin;