import React, { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';

// Components
import { AEditorMenuItem } from 'atoms';
import { LoadingSpinner } from 'components';
import { ErrorMessage, SearchResults, SelectedCategory, SelectedCategoryFull, SelectedDevice } from 'page/Editor/Menu/Shared';
import FreeItem from '../../../configuration/FreeItem';
import BlockItem from '../../../configuration/BlockItem';

// Context
import { EditorContext, LangContext } from 'context';

// Languages
import Localizations from 'languages';

// Styles
import './style.scss';

// Types
import { Category } from 'types';

// Utils
import { getCategories, hasOwnProperty, isDevice } from 'utils';
import { Substructures } from './Partials/Substructures';

export const FlexichefMenu = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>(null);
  const [fullscreenIsMenu, setFullscreenIsMenu] = useState<boolean>(false);

  const { lang } = useContext(LangContext);
  const { configuration, errorAlert, flexiMenuView, fullscreen, selection, setSelection, menuView, scene, searchResults, setErrorAlert, setSeriesFilter } =
    useContext(EditorContext);

  const style: CSSProperties = { height: '100%', position: 'relative' };
  const left: string = '5%';

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Delete') {
        if (selection && selection instanceof FreeItem) {
          setErrorAlert(['delete']);
        }
      }
    },
    [selection]
  );

  useEffect(() => {
    if (selection) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selection]);

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await getCategories('flexiChef');
      if (data) {
        setCategories(data);
        setSelectedCategory(data[0]);
      }
      if (error) {
        console.log(error);
      }
    };
    fetchCategories();
    setSeriesFilter('all');
    return () => {
      setSelectedCategory(null);
      if (fullscreenIsMenu) setFullscreenIsMenu(false);
    };
  }, []);

  useEffect(() => {
    if (fullscreen === 'menu') {
      setFullscreenIsMenu(true);
    }
    if (fullscreen !== 'menu' && fullscreenIsMenu) {
      if (scene && scene.getSelected && scene.getSelected() instanceof BlockItem) {
        setSelectedCategory(null);
        setSelection(scene.getSelected());
      }
      setFullscreenIsMenu(false);
    }
  }, [fullscreen, fullscreenIsMenu]);

  if (!(menuView === 'flexiChef')) {
    return <></>;
  }

  if (loading) {
    return <LoadingSpinner color="blue" />;
  }

  if (searchResults && !errorAlert?.includes('size') && !selection && menuView === 'flexiChef') {
    return (
      <AEditorMenuItem
        key="Flexichef-Searchresults"
        isVisible={searchResults && !errorAlert?.includes('size') && !selection && menuView === 'flexiChef'}
        left={left}
        style={style}
      >
        <SearchResults />
      </AEditorMenuItem>
    );
  }

  return (
    <>
      {/* Search Results */}
      {!fullscreen && searchResults && !errorAlert?.includes('size') && !selection && flexiMenuView === 'main' && (
        <AEditorMenuItem key="Flexichef-ErrorMessage" left={left} style={style}>
          <SearchResults />
        </AEditorMenuItem>
      )}

      {/* Selected Category */}
      {!fullscreen &&
        !searchResults &&
        selectedCategory &&
        (!selection || (selection && isDevice(selection))) &&
        menuView === 'flexiChef' &&
        flexiMenuView === 'main' && (
          <AEditorMenuItem key="Flexichef-selected-Category" left={left} style={{ ...style, overflow: 'hidden' }}>
            <SelectedCategory noSeries extraGoBack setSelection={setSelection} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
          </AEditorMenuItem>
        )}

      {/* Delete Confirm */}
      {!fullscreen && !searchResults && errorAlert && errorAlert.length > 0 && errorAlert.includes('delete') && flexiMenuView === 'main' && (
        <AEditorMenuItem key="Flexichef-ErrorMessageDelete" style={style}>
          <ErrorMessage
            title={Localizations['delete'][lang]}
            message={Localizations['confirmDelete'][lang]}
            confirm={() => {
              if (configuration) {
                const flexiChefs = configuration.getFlexiChefs();
                if (flexiChefs && flexiChefs.length > 0 && selection instanceof FreeItem) {
                  const index = flexiChefs.findIndex((sC: FreeItem) => sC.getUniqueId() === selection.getUniqueId());
                  if (index !== -1) {
                    configuration.removeFlexiChef(index);
                  }
                }
              }
              setSelection(null);
              setErrorAlert(null);
              scene.setSelected(null);
            }}
            goBack={() => setErrorAlert(null)}
          />
        </AEditorMenuItem>
      )}

      {!fullscreen &&
        /* !searchResults && */ (!errorAlert || !(errorAlert.length > 0)) &&
        selection &&
        selection instanceof FreeItem &&
        flexiMenuView === 'main' && (
          <AEditorMenuItem key="Free-Flexichef-1" left={left} style={{ ...style, position: 'absolute', top: 0, zIndex: 9001 }}>
            <SelectedDevice isOverlay selectedDevice={[selection.getDeviceObject()]} />
          </AEditorMenuItem>
        )}

      {!fullscreen &&
        /* !searchResults && */ (!errorAlert || !(errorAlert.length > 0)) &&
        selection &&
        hasOwnProperty(selection, 'type') &&
        isDevice(selection) &&
        flexiMenuView === 'main' && (
          <AEditorMenuItem key="Free-Flexichef-2" style={{ ...style, position: 'absolute', top: 0, zIndex: 9001 }}>
            {/* @ts-ignore */}
            <SelectedDevice isOverlay selectedDevice={[selection]} />
          </AEditorMenuItem>
        )}

      {/* Selected Category Full */}

      {selectedCategory && !selection && fullscreenIsMenu && menuView === 'flexiChef' && flexiMenuView === 'main' && (
        <AEditorMenuItem key="Flex-Fullscreen">
          <SelectedCategoryFull setSelection={setSelection} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
        </AEditorMenuItem>
      )}

      {flexiMenuView === 'substructures' && selection instanceof FreeItem && (
        <AEditorMenuItem key="Flex-SubStructures">
          <Substructures />
        </AEditorMenuItem>
      )}
    </>
  );
};
