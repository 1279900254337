import { Device } from '../types/Device';
import FreeItem from '../page/Editor/configuration/FreeItem';
import Configuration from '../page/Editor/configuration/Configuration';

export const addFreeDevice = (scene: any, device: Device, configuration: Configuration) => {
  if (scene) {
    let addedDevice: FreeItem;

    addedDevice = new FreeItem(device, configuration);

    if (configuration.isShowLabels()) {
      addedDevice.setShowLabels(true)
    }
    scene.setSelected(device);
  }
};

