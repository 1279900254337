import Block from 'page/Editor/configuration/Block';
import BlockItem from 'page/Editor/configuration/BlockItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import { Device } from '../types/Device';
import { DevicePosition } from 'types/DevicePosition';

type Position = {
  device: Device;
  amount: number;
};

export const createDeviceList = (block: Block, setDeviceList: (dL: DevicePosition[]) => void) => {
  const devices: Position[] = [];
  // TOP Row
  if (block && block.getRowTop()) {
    const topDevices: Position[] = [];
    block
      .getRowTop()
      .getItems()
      .forEach((item: BlockItem | BlockGroup) => {
        if (item instanceof BlockItem) {
          const existingIndex = topDevices.findIndex(pos => pos.device.code === item.getDeviceObject().code);
          if (existingIndex < 0) {
            topDevices.push({ device: item.getDeviceObject(), amount: 1 });
          } else {
            topDevices[existingIndex].amount += 1;
          }
        } else if (item instanceof BlockGroup) {
          const groupItems = item.getItems();
          const items = groupItems.map(item => item.getDeviceObject());
          items.forEach(i => {
            const existingIndex = topDevices.findIndex(pos => pos.device.code === i.code);
            if (existingIndex < 0) {
              topDevices.push({ device: i, amount: 1 });
            } else {
              topDevices[existingIndex].amount += 1;
            }
          });
        }
      });
    devices.push(...topDevices);
  }
  // Bottom Row
  if (block && block.getRowBottom()) {
    const bottomdevices: Position[] = [];
    block
      .getRowBottom()
      .getItems()
      .forEach((item: BlockItem | BlockGroup) => {
        if (item instanceof BlockItem) {
          const existingIndex = bottomdevices.findIndex(pos => pos.device.code === item.getDeviceObject().code);
          // Add new Item
          if (existingIndex < 0) {
            bottomdevices.push({ device: item.getDeviceObject(), amount: 1 });
          }
          // Item of that type already in List
          else {
            bottomdevices[existingIndex].amount += 1;
          }
          const equipment = item.getEquipments();
          if (equipment.length > 0) {
            equipment.forEach(e => {
              const existingIndex = bottomdevices.findIndex(pos => pos.device.code === e.getDeviceId());
              if (existingIndex < 0) {
                bottomdevices.push({ device: e.getDeviceObject(), amount: 1 });
              }
              // Item of that type already in List
              else {
                bottomdevices[existingIndex].amount += 1;
              }
            });
          }
        } else if (item instanceof BlockGroup) {
          const groupItems = item.getItems();
          const items = groupItems.map(item => item.getDeviceObject());
          items.forEach(i => {
            const existingIndex = bottomdevices.findIndex(pos => pos.device.code === i.code);

            if (existingIndex < 0) {
              bottomdevices.push({ device: i, amount: 1 });
            } else {
              bottomdevices[existingIndex].amount += 1;
            }
            const equipment = item.getEquipments();
            if (equipment.length > 0) {
              equipment.forEach(e => {
                const existingIndex = bottomdevices.findIndex(pos => pos.device.code === e.getDeviceId());
                if (existingIndex < 0) {
                  bottomdevices.push({ device: e.getDeviceObject(), amount: 1 });
                }
                // Item of that type already in List
                else {
                  bottomdevices[existingIndex].amount += 1;
                }
              });
            }
          });
        }
      });

    // Merge Rows
    bottomdevices.forEach(bD => {
      const existingIndex = devices.findIndex(d => d.device.code === bD.device.code);
      if (existingIndex < 0) {
        devices.push(bD);
      } else {
        devices[existingIndex].amount += 1;
      }
    });
  }
  if (devices.length > 0) {
    setDeviceList(devices);
  }
};