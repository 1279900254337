const LHome = {
  configurations: {
    fr: 'Configurations',
    de: 'Konfigurationen',
    en: 'Configurations',
    cz: 'Konfigurace'
  },
  enterCode: {
    fr: 'Entrer le code',
    de: 'Code eingeben',
    en: 'Enter Code',
    cz: 'Zadat kód'
  },
  newConfig: {
    fr: 'Nouvelle configuration',
    de: 'Neue Konfiguration',
    en: 'New Configuration',
    cz: 'Nová konfigurace'
  },
  date: {
    fr: 'Date',
    de: 'Datum',
    en: 'Date',
    cz: 'Datum'
  },
  customer: {
    fr: 'Client',
    de: 'Kunde',
    en: 'Customer',
    cz: 'Zákazník'
  },
  object: {
    fr: 'Projet',
    de: 'Objekt',
    en: 'Project',
    cz: 'Umístění'
  },
  location: {
    fr: 'Lieu',
    de: 'Ort',
    en: 'Location',
    cz: 'Objekt'
  },
  public: {
    fr: 'Public',
    de: 'Öffentlich',
    en: 'Public',
    cz: 'Veřejný'
  },
  delete: {
    fr: 'Supprimer',
    de: 'Löschen',
    en: 'Delete',
    cz: 'Smazat'
  },
  open: {
    fr: 'Ouvrir',
    de: 'Öffnen',
    en: 'Open',
    cz: 'Otevření'
  },
  start: {
    fr: 'Démarrage',
    de: 'Starten',
    en: 'Start',
    cz: 'Spustit'
  },
  cancel: {
    fr: 'Annuler',
    de: 'Abbrechen',
    en: 'Cancel',
    cz: 'Storno'
  },
  myConfig: {
    fr: 'Ma configuration',
    de: 'Meine Konfiguration',
    en: 'My Configuration',
    cz: 'Moje konfigurace'
  },
  room: {
    fr: 'pièce',
    de: 'Raum',
    en: 'Room',
    cz: 'Místnost'
  },
  modular: {
    fr: 'Modulaire',
    de: 'Modular',
    en: 'Modular',
    cz: 'Modulární'
  },
  masterline: {
    fr: 'Masterline',
    de: 'Masterline',
    en: 'Masterline',
    cz: 'Masterline'
  },
  configuration: {
    fr: 'Configuration ',
    de: 'Konfiguration',
    en: 'Configuration',
    cz: 'Konfigurace'
  },
  noCode: {
    fr: 'Veuillez saisir un code.',
    de: 'Bitte geben Sie einen Code an.',
    en: 'Please enter a code.',
    cz: 'Zadejte kód.'
  },
  search: {
    fr: 'Recherche',
    de: 'Durchsuchen',
    en: 'Search',
    cz: 'Prohledat'
  },
  discountMasterline: {
    fr: 'Remise MasterLine',
    de: 'Rabatt MasterLine',
    en: 'Discount MasterLine',
    cz: 'Sleva MasterLine přístroj'
  },
  discountModular: {
    fr: 'Remise Modular',
    de: 'Rabatt Modular',
    en: 'Discount Modular',
    cz: 'Sleva modulární přístroj'
  },
  discountCombi: {
    fr: 'Remise Flexicombi / Spacecombi',
    de: 'Rabatt CombiDämpfer',
    en: 'Discount Combi Steamer',
    cz: 'Sleva konvektomat'
  },
  surcharge: {
    fr: 'Coef de vente',
    de: 'Aufpreis',
    en: 'Surcharge',
    cz: 'Příplatek'
  },
  active: {
    fr: 'Actif',
    de: 'aktiv',
    en: 'active',
    cz: 'active'
  }
};

export default LHome;
