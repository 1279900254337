import BlockBoardNode from 'components/babylon/node/BlockBoardNode';
import { Device } from 'types/Device';
import { Subtype } from 'types/DeviceEnum';
import Block from './Block';
import { isDeviceCompatibleWithSubtype } from './Utils';

export type RenderStyle = 'None' | 'Error';
export type RenderItem = 'Salamander' | 'Board' | 'Piller' | 'Faucet';

export default class BlockBoard {
  public _minWidth = 1000;
  public _maxWidth = 4500;

  public clazz = 'Board';

  private device: string;
  private _device: Device;

  private marginLeft: number = 0;
  private marginRight: number = 0;

  private salamanderLeft: boolean;
  private salamanderRight: boolean;

  private left: Array<BlockBoardDevice> = [];
  private right: Array<BlockBoardDevice> = [];

  private _parent: Block;
  private _node: BlockBoardNode;
  private _renderArray: Array<{
    width: number;
    position: number;
    item: string;
    style: RenderStyle;
    source: 'Base' | 'Generic';
  }>;

  constructor(parent: Block, device: Device) {
    this._parent = parent;
    if (!device) {
      this._minWidth = 0;
      this._maxWidth = 10000;
    } else if (device?.style === 'Tray') {
      this._minWidth = 1000;
      this._maxWidth = 2700;
    } else if (device?.style === 'Tray600') {
      this._minWidth = 1000;
      this._maxWidth = 4500;
    }
    this.setDevice(device);
    this.setMarginRight(0, false);
    this.setMarginLeft(0, false);
    this._node = new BlockBoardNode(this);
  }

  public getDeviceId() {
    return this.device;
  }

  public getDeviceObject() {
    return this._device;
  }

  public setDevice(device: Device) {
    if (!device) return;
    this.device = device.id;
    this._device = device;
  }

  public getMarginLeft() {
    return this.marginLeft;
  }

  public setMarginLeft(value: number, bake = true) {
    if (typeof value !== 'number') return false;
    value = round(value);
    this._renderArray = undefined;
    const newWidth = this._parent.getWidth() - value - this.getMarginRight();
    const diff = Math.abs(this.marginLeft - value);
    // console.log('marginLeft', 'newWidth', newWidth);
    if (newWidth < this._minWidth) {
      if (this.marginRight - diff >= 0) {
        this.marginRight -= diff;
      } else return false;
    }
    if (newWidth > this._maxWidth) {
      this.marginRight = this.getBlock().getWidth() - this._maxWidth - value;
    }
    this.marginLeft = value;
    if (bake) this.getNode().bake();
    return true;
  }

  public getMarginRight() {
    return this.marginRight;
  }

  public setMarginRight(value: number, bake = true) {
    if (typeof value !== 'number') return false;
    value = round(value);
    this._renderArray = undefined;
    const newWidth = this._parent.getWidth() - value - this.getMarginLeft();
    const diff = Math.abs(this.marginRight - value);
    // console.log('marginRight', 'newWidth', newWidth, 'diff', diff);
    if (newWidth < this._minWidth) {
      if (this.marginLeft - diff >= 0) {
        this.marginLeft -= diff;
      } else return false;
    }
    if (newWidth > this._maxWidth) {
      this.marginLeft = this.getBlock().getWidth() - this._maxWidth - value;
    }
    this.marginRight = value;
    if (bake) this.getNode().bake();
    return true;
  }

  public getWidth() {
    return this._parent.getWidth() - this.getMarginLeft() - this.getMarginRight();
  }

  public checkWidthChange() {
    const pWidth = this._parent.getWidth();
    const newWidth = pWidth - this.marginLeft - this.marginRight;
    if (newWidth < this._minWidth) {
      const widthWithoutLeft = pWidth - this.marginLeft;
      this.marginRight = widthWithoutLeft - (widthWithoutLeft - this._minWidth);
    } else if (newWidth > this._maxWidth) {
      const widthWithoutLeft = pWidth - this.marginLeft;
      this.marginRight = widthWithoutLeft - this._maxWidth;
    }
  }

  public checkMinMaxWitdh() {
    if (!this._device) {
      this._minWidth = 0;
      this._maxWidth = 10000;
    } else if (this._device?.style === 'Tray') {
      this._minWidth = 1000;
      this._maxWidth = 2700 + (this.isSalamanderLeft() ? 600 : 0) + (this.isSalamanderRight() ? 600 : 0);
    } else if (this._device?.style === 'Tray600') {
      this._minWidth = 1000;
      this._maxWidth = 4500 + (this.isSalamanderLeft() ? 600 : 0) + (this.isSalamanderRight() ? 600 : 0);
    }
  }

  public isSalamanderLeft() {
    return this.salamanderLeft;
  }

  public setSalamanderLeft(value: boolean, bake = true) {
    this._renderArray = undefined;
    this.salamanderLeft = value;
    if (bake) this.getNode().bake();

    this.checkMinMaxWitdh();
  }

  public isSalamanderRight() {
    return this.salamanderRight;
  }

  public setSalamanderRight(value: boolean, bake = true) {
    this._renderArray = undefined;
    this.salamanderRight = value;
    if (bake) this.getNode().bake();

    this.checkMinMaxWitdh();
  }

  public getLeft(device?: Device | string) {
    if (device) {
      let deviceId: string;
      if (typeof device === 'string') deviceId = device;
      else deviceId = device.id;
      for (let i = 0; i < this.left.length; i++) {
        const d = this.left[i];
        if (d.getDeviceId() === deviceId) return d;
      }
      return null;
    }
    return this.left;
  }

  public addLeft(device: Device, bake = true) {
    return this.add(this.left, device, bake);
  }

  public removeLeft(device: Device | BlockBoardDevice, bake = true) {
    return this.remove(this.left, device, bake);
  }

  public getRight(device?: Device | string) {
    if (device) {
      let deviceId: string;
      if (typeof device === 'string') deviceId = device;
      else deviceId = device.id;
      for (let i = 0; i < this.right.length; i++) {
        const d = this.right[i];
        if (d.getDeviceId() === deviceId) return d;
      }
      return null;
    }
    return this.right;
  }

  public addRight(device: Device, bake = true) {
    return this.add(this.right, device, bake);
  }

  public removeRight(device: Device | BlockBoardDevice, bake = true) {
    return this.remove(this.right, device, bake);
  }

  private add(array: Array<BlockBoardDevice>, device: Device, bake = true) {
    if (!device) return null;
    const d = new BlockBoardDevice(device);
    array.push(d);
    if (bake) this.rerender();
    return d;
  }

  private remove(array: Array<BlockBoardDevice>, device: Device | BlockBoardDevice, bake = true) {
    let deviceId: string;
    if (device instanceof BlockBoardDevice) deviceId = device.getDeviceId();
    else deviceId = device.id;
    for (let i = 0; i < array.length; i++) {
      const d = array[i];
      if (d.getDeviceId() === deviceId) {
        array.splice(i, 1);
        d.cleanResources();
        if (bake) this.rerender();
        return true;
      }
    }
    return false;
  }

  public getParent() {
    return this._parent;
  }

  public getBlock() {
    return this._parent;
  }

  public getNode() {
    return this._node;
  }

  public rerender() {
    this._renderArray = undefined;
    this._node.bake();
  }

  public getRenderArray() {
    if (!this._renderArray) {
      this._renderArray = [];
      if (this.getBlock().getType() === 'Double') {
        const width = this.getBlock().getWidth();
        const itemsTop = this.getBlock().getRowTop().getWidthDeviceMap();
        const itemsBottom = this.getBlock().getRowBottom().getWidthDeviceMap();

        let trayStart = this.marginLeft;
        let trayEnd = width - this.marginRight;

        const salamanderWidth = 800;
        if (this.salamanderLeft) {
          let start = this.marginLeft;
          let end = start + salamanderWidth;
          let style: RenderStyle = 'None';
          for (let i = start; i < end; i += 100) {
            const itemTop = itemsTop.get(i);
            const itemBottom = itemsBottom.get(i);
            if (
              (itemTop && !isDeviceCompatibleWithSubtype(itemTop, Subtype.Shelf)) ||
              (itemBottom && !isDeviceCompatibleWithSubtype(itemBottom, Subtype.Shelf))
            ) {
              style = 'Error';
              break;
            }
          }
          this._renderArray.push({
            width: salamanderWidth,
            position: start,
            item: 'Salamander:Left',
            style: style,
            source: 'Generic'
          });
          trayStart += salamanderWidth - 100;
        } else if (this.salamanderRight) {
          let start = width - this.marginRight - salamanderWidth;
          let end = start + salamanderWidth;
          let style: RenderStyle = 'None';
          for (let i = start; i < end; i += 100) {
            const itemTop = itemsTop.get(i);
            const itemBottom = itemsBottom.get(i);
            if (
              (itemTop && !isDeviceCompatibleWithSubtype(itemTop, Subtype.Shelf)) ||
              (itemBottom && !isDeviceCompatibleWithSubtype(itemBottom, Subtype.Shelf))
            ) {
              style = 'Error';
              break;
            }
          }
          this._renderArray.push({
            width: salamanderWidth,
            position: end,
            item: 'Salamander:Right',
            style: style,
            source: 'Generic'
          });
          trayEnd -= salamanderWidth - 100;
        }

        let tray = 'Tray';
        //if (this.getDeviceObject()) tray = this.getDeviceObject().style;

        if (tray) {
          for (let i = trayStart; i <= trayEnd; i += 100) {
            const itemTop = itemsTop.get(i);
            const itemBottom = itemsBottom.get(i);

            let style: RenderStyle = 'None';
            if (
              (itemTop && !isDeviceCompatibleWithSubtype(itemTop, Subtype.Shelf)) ||
              (itemBottom && !isDeviceCompatibleWithSubtype(itemBottom, Subtype.Shelf))
            )
              style = 'Error';

            let item = tray + ':Center';
            let position = i;
            if (i == trayStart) {
              item = tray + ':Left';

              if (!this.salamanderLeft) {
                this._renderArray.push({
                  width: 0,
                  position: i + 400,
                  item: 'Piller:Left',
                  style: 'None',
                  source: 'Base'
                });
              }
            } else if (i == trayEnd) {
              item = tray + ':Right';

              if (!this.salamanderRight) {
                this._renderArray.push({
                  width: 0,
                  position: i - 400,
                  item: 'Piller:Right',
                  style: 'None',
                  source: 'Base'
                });
              }
            } else {
              // Weil aus irgendeinem grund das Mittelstück um 10cm verschoben ist im 3D Modell
              position -= 100;
            }

            this._renderArray.push({
              width: 100,
              position: position,
              item: item,
              style: style,
              source: 'Base'
            });
          }

          const trayWidth = width - this.marginLeft - this.marginRight;
          if (trayWidth > 3000) {
            this._renderArray.push({
              width: 0,
              position: this.marginLeft + trayWidth / 2,
              item: 'Piller:Center',
              style: 'None',
              source: 'Base'
            });
          }
        }

        this.left.forEach(e => {
          let style: RenderStyle = 'None';
          let item = e.getDeviceObject().style + ':Left';
          let position = e.getMargin();

          switch (e.getDeviceObject().style) {
            case 'FaucetTray':
              const itemBottom = itemsBottom.get(e.getMargin());
              if (!isDeviceCompatibleWithSubtype(itemBottom, Subtype.ShelfMixingFaucet)) {
                style = 'Error';
              } else if (this.salamanderLeft && e.getMargin() < 800) style = 'Error';
              else if (this.salamanderRight && e.getMargin() > width - 800) style = 'Error';
              break;
            case 'Faucet':
              if (this.salamanderLeft) {
                let start = this.marginLeft;
                let end = start + salamanderWidth;
                if (position >= start && e.getMargin() <= end) style = 'Error';
              }
              if (this.salamanderRight) {
                let start = width - this.marginRight - salamanderWidth;
                let end = start + salamanderWidth;
                if (position >= start && position <= end) style = 'Error';
              }
              break;
          }

          this._renderArray.push({
            width: 0,
            position: position,
            item: item,
            style: style,
            source: 'Generic'
          });
        });

        this.right.forEach(e => {
          let style: RenderStyle = 'None';
          let item = e.getDeviceObject().style + ':Right';
          let position = width - e.getMargin();

          switch (e.getDeviceObject().style) {
            case 'FaucetTray':
              const itemTop = itemsTop.get(width - (e.getMargin() + 100));
              if (!isDeviceCompatibleWithSubtype(itemTop, Subtype.ShelfMixingFaucet)) {
                style = 'Error';
              } else if (this.salamanderLeft && e.getMargin() > width - 800) style = 'Error';
              else if (this.salamanderRight && e.getMargin() < 800) style = 'Error';
              break;
            case 'Faucet':
              if (this.salamanderLeft) {
                let start = this.marginLeft;
                let end = start + salamanderWidth;
                if (position >= start && position <= end) style = 'Error';
              }
              if (this.salamanderRight) {
                let start = width - this.marginRight - salamanderWidth;
                let end = start + salamanderWidth;
                if (position >= start && position <= end) style = 'Error';
              }
              break;
          }

          this._renderArray.push({
            width: 0,
            position: position,
            item: item,
            style: style,
            source: 'Generic'
          });
        });
      }
    }
    // console.log('_renderArray', this._renderArray);
    return this._renderArray;
  }

  public containsErrors() {
    const array = this.getRenderArray();
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.style === 'Error') return true;
    }
    return false;
  }

  public cleanResources() {
    this.left.forEach(bbd => bbd.cleanResources());
    this.right.forEach(bbd => bbd.cleanResources());
    this._node.dispose();
  }
}

export class BlockBoardDevice {
  private margin: number = 0;
  private device: string;
  private _device: Device;

  constructor(device: Device) {
    this.device = device.id;
    this._device = device;
  }

  public getMargin() {
    return this.margin;
  }

  public setMargin(value: number) {
    if (typeof value !== 'number') return;
    this.margin = round(value);
  }

  public getDeviceId() {
    return this.device;
  }

  public getDeviceObject() {
    return this._device;
  }

  public cleanResources() {
    //this._node.dispose();
  }
}

const round = (value: number) => {
  const excess = value % 100;
  return value + (excess >= 50 ? 100 - excess : -excess);
};
