import RoomWindow from 'page/Editor/configuration/RoomWindow';
import Scene from 'page/Editor/Scene';
import MaterialUtils from '../util/MaterialUtils';
import WallClippingNode from './helper/WallClippingNode';

export default class RoomWindowNode extends WallClippingNode {
  private _roomWindow: RoomWindow;

  constructor(roomWindow: RoomWindow) {
    super(roomWindow, 'door', Scene.CURRENT_SCENE);
    this._roomWindow = roomWindow;

    this._topping.material = MaterialUtils.MATERIAL_WALL_TOPPING_WINDOW;
  }

  /**
   * Updates height based on RoomWindow
   */
  public updateHeight() {
    const height = this._roomWindow.getHeight() / 10;
    const bottom = this._roomWindow.getBottom() / 10;

    this._clipping.scaling.y = height;
    this._clipping.position.y = bottom + height / 2;
  }

  /**
   * Updates bottom distance based on RoomWindow
   */
  public updateBottom() {
    const height = this._roomWindow.getHeight() / 10;
    const bottom = this._roomWindow.getBottom() / 10;

    this._clipping.position.y = bottom + height / 2;
  }

  /**
   * @returns RoomWindow Object
   */
  public getRoomWindow() {
    return this._roomWindow;
  }
}
