import RoomDoorNode from 'components/babylon/node/RoomDoorNode';
import Room, { WallClipping } from './Room';

export default class RoomDoor extends WallClipping {
  public clazz = 'RoomDoor';

  private height: number;
  private _node: RoomDoorNode;

  constructor(room: Room) {
    super(room);
    this._node = new RoomDoorNode(this);

    this.setWall('Top');
    this.setWidth(1000);
    this.setHeight(2000);
  }

  /**
   * @returns height in mm
   */
  public getHeight() {
    return this.height;
  }

  /**
   * @param height height in mm
   */
  public setHeight(height: number) {
    this.height = height;

    this._node.updateHeight();
    this._room.getNode().bake();
  }

  public getNode() {
    return this._node;
  }
}
