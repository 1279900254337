import Block from '../page/Editor/configuration/Block';
import BlockItem from '../page/Editor/configuration/BlockItem';
export const hasDimSum = (block: Block, free: boolean) => {
  let includesDimSum = false;
  block.forAllItems(item => {
    if (item instanceof BlockItem && item._device?.style?.includes('exclude:SingleFree')) {
      if (free) {
        item.setError(true);
      } else {
        item.setError(false);
      }
      includesDimSum = true;
    }
  });
  return includesDimSum;
};
