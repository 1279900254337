import { UsersTable } from './components';
import { OrganizationDataProvider } from 'page/Organization/context';
import Localizations from 'languages';

import './style.scss';
import { useContext } from 'react';
import { LangContext } from 'context';

export const Organization = () => {
  const { lang } = useContext(LangContext);

  return (
    <div className="Organization">
      <h1>{Localizations['organization'][lang]}</h1>
      <h2>{Localizations['users'][lang]}</h2>
      <OrganizationDataProvider>
        <UsersTable />
      </OrganizationDataProvider>
    </div>
  );
};
