import * as BABYLON from 'babylonjs';
import BlockGroupNode from 'components/babylon/node/BlockGroupNode';
import BlockNode from 'components/babylon/node/BlockNode';
import DeviceNode, { PropertyType as DNPT } from 'components/babylon/node/DeviceNode';
import BasicUtils from 'components/babylon/util/BasicUtils';
import MaterialUtils from 'components/babylon/util/MaterialUtils';
import { Subtype } from 'types';
import { Device } from 'types/Device';
import { getDevice, getDevicesForCodeMaybe, post } from 'utils';
import Dimensions from 'utils/Dimensions';
import { getDevicesForCode } from 'utils/getDevice';
import { isSubtype } from 'utils/subtype';
import Scene from '../Scene';
import BlockBoard from './BlockBoard';
import BlockGroup from './BlockGroup';
import BlockItem from './BlockItem';
import BlockRow from './BlockRow';
import Equipment, { EquipmentHelper } from './Equipment';
import RGB from './RGB';
import { isDeviceCompatibleWithDepth } from './Utils';
import Vector3 from './Vector3';

export type HandleType = 'Top' | 'Bottom' | 'Both' | 'Full';
export type BlockType = 'Masterline' | 'Modular' | 'MarineMeister';

export default class Block {
  public clazz = 'Block';

  private id: string;
  private name: string;
  private type: 'Single' | 'Double' = 'Single';
  private singleType: 'Wall' | 'Free' = 'Wall';
  private rowTop: BlockRow = null;
  private rowBottom: BlockRow = null;
  private board: BlockBoard = null;
  private depthExtension = 0;

  private sideBoardLeft: string = null;
  private _sideBoardLeft: Device = null;
  private sideBoardLeftWidth: number;
  private sideBoardRight: string = null;
  private _sideBoardRight: Device = null;
  private sideBoardRightWidth: number;

  private blockType: BlockType = 'Masterline';
  private flex: boolean = false;
  private width: number = 3600;

  private borderLeft: string = null;
  private _borderLeft: Device = null;
  private borderRight: string = null;
  private _borderRight: Device = null;
  private handle: string = null;
  private _handle: Device = null;
  private handleType: HandleType = 'Both';
  private bottom: 'BaseMKN' | 'Base' | 'Feet' | 'None' = 'Base';
  private bottomHeight: number = 15;

  private blendColor: number = 0;
  private doorColor: number = 0;

  private _fullBlendColor: boolean = true;

  private position: Vector3 = new Vector3();
  private rotation: Vector3 = new Vector3();
  private sideCover: boolean = false;
  private steelKnob: boolean = false;
  private _node: BlockNode;

  private _showLabels: boolean;
  private _showConnections: boolean;
  private _showCutLabels: boolean;

  private cutX: number = 0;
  private cutY: number = 0;

  private installationWall: string;
  private _installationWall: Device;
  private installationWallDepth: number = 100;
  private installationWallHeight: number = 0;

  private _blendColorMaterial: BABYLON.PBRMaterial;
  private _doorColorMaterial: BABYLON.PBRMaterial;
  private _steelKnobMaterial: BABYLON.PBRMaterial;

  private equipments: Array<Equipment> = [];

  private upturn: number = null;
  private upturnL: number = null;
  private upturnR: number = null;
  private voltage: number = null;

  constructor(params: { name?: string; blockType?: BlockType }) {
    this.blockType = params?.blockType || 'Masterline';
    this.bottom = this.blockType === 'MarineMeister' ? 'None' : 'Base';
    this._blendColorMaterial = MaterialUtils.MATERIAL_METAL.clone('_block_blend_' + BasicUtils.generateRandomString(16));
    this._doorColorMaterial = MaterialUtils.MATERIAL_METAL.clone('_block_door_' + BasicUtils.generateRandomString(16));
    this._steelKnobMaterial = MaterialUtils.MATERIAL_MASTER_KNOB.clone('_block_masterknob_' + BasicUtils.generateRandomString(16));
    if (this.blockType === 'MarineMeister') {
      this.voltage = 400;
      this.upturn = 100;
      this.upturnL = 100;
      this.upturnR = 100;
    }
    this._node = new BlockNode(params?.name ? params?.name : `${this.blockType}_new`, this);
    this.rowBottom = new BlockRow('Bottom', this, this._node.getBottom());
    this.rowBottom.setDepth(850);
    this._node.updateDimension();
  }

  public cleanResources() {
    this.setShowLabels(false);
    this.setShowCutLabels(false);
    if (this.rowTop) this.rowTop.cleanResources();
    if (this.rowBottom) this.rowBottom.cleanResources();
    this._node.dispose();

    // Clean Materials
    this._blendColorMaterial.dispose();
    this._doorColorMaterial.dispose();
    this._steelKnobMaterial.dispose();
  }

  public setShowLabels(value: boolean) {
    this._showLabels = value;
    this.setItemProperty(DNPT.Label, value);
    this._node.updateDimension();
    this._node.setShowLabel(value);
    if (this.rowTop) {
      {
        const label = this.rowTop.getNode().getLabel();
        if (label) label.setEnabled(value);
      }
      {
        const label = this.rowTop.getNode().getUnusedSpaceLabel();
        if (label) label.setEnabled(value);
      }
    }
    if (this.rowBottom) {
      {
        const label = this.rowBottom.getNode().getLabel();
        if (label) label.setEnabled(value);
      }
      {
        const label = this.rowBottom.getNode().getUnusedSpaceLabel();
        if (label) label.setEnabled(value);
      }
    }
  }

  public isShowLabels() {
    return this._showLabels;
  }

  public isShowCutLabels() {
    return this._showCutLabels;
  }

  public setShowCutLabels(value: boolean) {
    this._showCutLabels = value;
    this._node.showCutLabel(value);
  }

  public getCutX() {
    if (this.type !== 'Double') return 0;
    return this.cutX;
  }

  public setCutX(cut: number) {
    if (typeof cut !== 'number' || this.type !== 'Double') cut = 0;
    this.cutX = cut;
    this._node.updateCutLabel();
  }

  public getCutY() {
    return this.cutY;
  }

  public setCutY(cut: number) {
    if (typeof cut !== 'number' || cut > 2) cut = 0;
    this.cutY = cut;
    this._node.updateCutLabel();
  }

  public setShowConnections(value: boolean) {
    this._showConnections = value;
    if (this.rowTop) {
      for (let i = 0; i < this.rowTop.getItems().length; i++) {
        const item = this.rowTop.getItems()[i];
        if (item instanceof BlockGroup) item.getNode().setConnectLabel(value);
      }
    }
    if (this.rowBottom) {
      for (let i = 0; i < this.rowBottom.getItems().length; i++) {
        const item = this.rowBottom.getItems()[i];
        if (item instanceof BlockGroup) item.getNode().setConnectLabel(value);
      }
    }
  }

  public isShowConnections() {
    return this._showConnections;
  }

  private setItemProperty(property: DNPT, value: string | number | boolean, bake: boolean = false) {
    if (this.rowTop) {
      for (let i = 0; i < this.rowTop.getItems().length; i++) {
        const item = this.rowTop.getItems()[i];
        item.getNode().set(property, value, bake);
      }
    }
    if (this.rowBottom) {
      for (let i = 0; i < this.rowBottom.getItems().length; i++) {
        const item = this.rowBottom.getItems()[i];
        item.getNode().set(property, value, bake);
      }
    }
  }

  public forAllItems(callbackfn: (item: BlockGroup | BlockItem) => void) {
    if (this.rowTop) this.rowTop.getItems().forEach(callbackfn);
    if (this.rowBottom) this.rowBottom.getItems().forEach(callbackfn);
  }

  public moveItem(item: BlockItem | BlockGroup) {
    if (item.getParent() instanceof BlockRow) {
      let rowObject = item.getParent() as BlockRow;
      // console.log('moveItem', item)
      let row: 'Top' | 'Bottom' = rowObject.getType();
      let rowSwitch = false;
      if (this.getType() === 'Double') {
        switch (rowObject.getType()) {
          case 'Top':
            if (item.getNode().position.z < -(Dimensions.CM(rowObject.getDepth()) / 2)) {
              // console.log('To Bottom')
              row = 'Bottom';
              rowSwitch = true;
            }
            break;
          case 'Bottom':
            if (item.getNode().position.z > Dimensions.CM(rowObject.getDepth()) / 2) {
              // console.log('To Top')
              row = 'Top';
              rowSwitch = true;
            }
            break;
        }
      }

      // Position
      let x = 0;
      let pos;
      let items;
      switch (row) {
        case 'Top':
          rowObject = this.getRowTop();
          pos = this.getRowTop().guessItemPositionByX(item, item.getNode().position.x);
          items = this.getRowTop().getItems();
          break;
        case 'Bottom':
          rowObject = this.getRowBottom();
          pos = this.getRowBottom().guessItemPositionByX(item, item.getNode().position.x);
          items = this.getRowBottom().getItems();
          break;
      }

      for (let i = 0; i < items.length; i++) {
        const e = items[i];
        if (i < pos.to) x += Dimensions.CM(e.getWidth());
        else break;
      }

      if (!rowSwitch && pos.to > pos.from) x += Dimensions.CM(items[pos.from + 1].getWidth());
      if (
        rowSwitch &&
        items.length > 0 &&
        item.getNode().position.x > Dimensions.CM(rowObject.getWidth()) - Dimensions.CM(items[items.length - 1].getWidth()) / 2
      )
        x = Dimensions.CM(rowObject.getWidth());

      this.getNode().setDropMark(row, x);

      // Color
      let green = this.canItemBeDropped(item, rowObject);
      this.getNode().setDropMarkGreen(green);
    }
  }

  public canItemBeDropped(item: BlockItem | BlockGroup | Device, row: BlockRow) {
    if (row) {
      if (item instanceof BlockItem || item instanceof BlockGroup) {
        if (item.getParent() instanceof BlockRow) {
          let rowObject = item.getParent() as BlockRow;
          if (rowObject.getType() !== row.getType()) {
            if (!item.compatibleWithDepth(row.getDepth())) return false;
            if (row.getWidth() + item.getWidth() > this.getWidth()) return false;
          }
          return true;
        }
      } else {
        if (!isDeviceCompatibleWithDepth(item, row.getDepth())) return false;
        if (row.getWidth() + item.model.width > this.getWidth()) return false;
        return true;
      }
    }
    return false;
  }

  public dropItem(item: BlockItem | BlockGroup) {
    // console.log('dropItem', item)
    this.getNode().setDropMarkEnabled(false);
    if (item.getParent() instanceof BlockRow) {
      let rowObject = item.getParent() as BlockRow;
      // Check if Row has changes on a Double Configuration
      if (this.getType() === 'Double') {
        switch (rowObject.getType()) {
          case 'Top':
            if (item.getNode().position.z < -(Dimensions.CM(rowObject.getDepth()) * 0.5)) {
              if (!this.canItemBeDropped(item, this.getRowBottom())) {
                this.getRowTop().repositionItems();
                return;
              }
              this.getRowTop().removeItem(item);
              this.getRowBottom().addItem(item);
              // console.log('To Bottom')
            }
            break;
          case 'Bottom':
            if (item.getNode().position.z > Dimensions.CM(rowObject.getDepth()) * 0.5) {
              if (!this.canItemBeDropped(item, this.getRowTop())) {
                this.getRowBottom().repositionItems();
                return;
              }
              this.getRowBottom().removeItem(item);
              this.getRowTop().addItem(item);
              // console.log('To Top')
            }
            break;
        }
        item.onDepthChange();
      }
      (item.getParent() as BlockRow).moveItemToPosition(item, item.getNode().position.x);
    }
  }

  public isComplete() {
    switch (this.getType()) {
      case 'Single':
        return true;

      case 'Double':
        let wt = 0;
        let wb = 0;
        if (this.getRowTop()) {
          wt = this.getRowTop().getWidth();
        }
        if (this.getRowBottom()) {
          wb = this.getRowBottom().getWidth();
        }
        return wt === wb;
    }
    return true;
  }

  public checkDeviceStatus() {
    // Masterline has to contain more than 1 item in total
    if (this.isMasterline()) {
      let items = 0;
      this.getRowBottom()
        .getItems()
        .forEach(i => {
          if (i instanceof BlockItem) items++;
          else if (i instanceof BlockGroup) items += i.getItems().length;
        });
      this.getRowTop()
        ?.getItems()
        ?.forEach(i => {
          if (i instanceof BlockItem) items++;
          else if (i instanceof BlockGroup) items += i.getItems().length;
        });
      if (items === 1) {
        this.getRowBottom()
          .getItems()
          .forEach(i => {
            i.setError(true);
          });
        this.getRowTop()
          ?.getItems()
          ?.forEach(i => {
            i.setError(true);
          });
      } else {
        this.getRowBottom()
          .getItems()
          .forEach(i => {
            const row = this.getRowBottom();
            if (i instanceof BlockItem) row._specialAddItemCheck(i);
            else if (i instanceof BlockGroup) i.getItems().forEach(j => row._specialAddItemCheck(j));
          });
        this.getRowTop()
          ?.getItems()
          ?.forEach(i => {
            const row = this.getRowTop();
            if (i instanceof BlockItem) row._specialAddItemCheck(i);
            else if (i instanceof BlockGroup) i.getItems().forEach(j => row._specialAddItemCheck(j));
          });
      }
    }
  }

  static async importJSON(json: string | any): Promise<Block> {
    if (typeof json === 'string') json = JSON.parse(json);

    let blockType: BlockType = json.blockType;
    if (!blockType) {
      blockType = json.masterline || false ? 'Masterline' : 'Modular';
    }

    const block = new Block({ name: json.id, blockType });
    block.setId(json.id);
    block.setType(json.type);
    block.setSingleType(json.singleType);
    block.setBlockType(blockType);

    block.setBottom(json.bottom);
    block.setBottomHeight(json.bottomHeight);

    block.setBorderLeft(await getDevice(json.borderLeft));
    block.setBorderRight(await getDevice(json.borderRight));

    block.setHandle(await getDevice(json.handle));
    block.setHandleType(json.handleType || 'Both');
    block.setSideCover(json.sideCover);
    block.setSteelKnob(json.steelKnob);

    block.setCutX(json.cutX);
    block.setCutY(json.cutY);

    block.setUpturn(json.upturn || (blockType === 'MarineMeister' ? 100 : null));
    block.setUpturnL(json.upturnL || (blockType === 'MarineMeister' ? 100 : null));
    block.setUpturnR(json.upturnR || (blockType === 'MarineMeister' ? 100 : null));
    block.setVoltage(json.voltage);

    if (json.position) {
      const v3 = new Vector3(json.position.x, json.position.y, json.position.z);
      block.setPosition(v3);
      block.getNode().setAbsolutePosition(v3.get());
    }
    if (json.rotation) {
      const v3 = new Vector3(json.rotation.x, json.rotation.y, json.rotation.z);
      block.setRotation(v3);
    }

    const prepareRow = async (targetRow: BlockRow, sourceRow: any): Promise<void> => {
      for (let i = 0; i < sourceRow.items.length; i++) {
        const item = sourceRow.items[i];
        if (item.clazz === 'Item') {
          const device = await getDevice(item.device);
          if (device == null) continue;
          const blockItem = targetRow.addItem(device) as BlockItem;
          if (item.dimensions) {
            blockItem.setWidth(item.dimensions.x, false, true);
            blockItem.setHeight(item.dimensions.y, false);
            blockItem.setDepth(item.dimensions.z, false);
          }
          await prepareEquipment(blockItem, item);
        } else if (item.clazz === 'Group') {
          const arr = [];
          for (let j = 0; j < item.items.length; j++) {
            const groupItem = item.items[j];
            const device = await getDevice(groupItem.device);
            if (device == null) continue;
            const blockItem = new BlockItem(targetRow, device);
            await prepareEquipment(blockItem, groupItem);
            arr.push(blockItem);
          }
          const blockGroup = targetRow.addItem(new BlockGroup(targetRow, arr));
          await prepareEquipment(blockGroup, item);
        }
      }
    };
    const prepareEquipment = async (target: BlockItem | BlockGroup, sourceItem: any) => {
      if (sourceItem.equipments) {
        for (let i = 0; i < sourceItem.equipments.length; i++) {
          const equipment = sourceItem.equipments[i];
          if (equipment.clazz === 'Equipment') {
            const device = await getDevice(equipment.device);
            EquipmentHelper.addEquipment(target, device, equipment.index || 0);
          }
        }
      }
    };
    if (json.rowTop) {
      block.getRowTop().setDepth(json.rowTop.depth);
      await prepareRow(block.getRowTop(), json.rowTop);
    }
    if (json.rowBottom) {
      block.getRowBottom().setDepth(json.rowBottom.depth);
      await prepareRow(block.getRowBottom(), json.rowBottom);
    }

    if (json.blendColor) {
      if (typeof json.blendColor === 'number') block.setBlendColor(json.blendColor);
      else if (typeof json.blendColor.r === 'number') block.setBlendColorValue({ r: json.blendColor.r, g: json.blendColor.g, b: json.blendColor.b });
    }

    if (json.doorColor) {
      if (typeof json.doorColor === 'number') block.setDoorColor(json.doorColor);
      else if (typeof json.doorColor.r === 'number') block.setDoorColorValue({ r: json.doorColor.r, g: json.doorColor.g, b: json.doorColor.b });
    }

    block.setWidth(block.getMinWidth());
    if (typeof json.installationWall === 'string') block.setInstallationWall(await getDevice(json.installationWall));
    else if (typeof json.installationWall === 'boolean' && json.installationWall) {
      let iw: Device = null;
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
        data: {
          type: block.getType(),
          subtypes: ['InstallationWall']
        }
      });
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          if (e.style === 'Default') {
            iw = e;
            break;
          }
        }
      }
      block.setInstallationWall(iw);
    }
    block.setInstallationWallDepth(json.installationWallDepth);
    block.setInstallationWallHeight(json.installationWallHeight);

    if (json.board && json.board.clazz === 'Board') {
      block.board = new BlockBoard(block, json.board.device ? await getDevice(json.board.device) : null);
      if (json.board.salamanderLeft) block.board.setSalamanderLeft(true, false);
      if (json.board.salamanderRight) block.board.setSalamanderRight(true, false);
      if (json.board.marginLeft >= 0) block.board.setMarginLeft(json.board.marginLeft, false);
      if (json.board.marginRight >= 0) block.board.setMarginRight(json.board.marginRight, false);
      if (json.board.left && Array.isArray(json.board.left))
        for (let i = 0; i < json.board.left.length; i++) {
          const e = json.board.left[i];
          block.board.addLeft(await getDevice(e.device), false).setMargin(e.margin);
        }
      if (json.board.right && Array.isArray(json.board.right))
        for (let i = 0; i < json.board.right.length; i++) {
          const e = json.board.right[i];
          block.board.addRight(await getDevice(e.device), false).setMargin(e.margin);
        }
      block.board.getNode().bake();
      block.depthExtension = 100;
    }

    if (json.depthExtension && json.depthExtension > 0) {
      block.setDepthExtension(json.depthExtension);
    }

    if (json.sideBoardLeft) {
      block.setSideBoardLeft(await getDevice(json.sideBoardLeft));
      if (json.sideBoardLeftWidth) block.setSideBoardLeftWidth(json.sideBoardLeftWidth);
    }
    if (json.sideBoardRight) {
      block.setSideBoardRight(await getDevice(json.sideBoardRight));
      if (json.sideBoardRightWidth) block.setSideBoardRightWidth(json.sideBoardRightWidth);
    }

    if (json.equipments) {
      for (let i = 0; i < json.equipments.length; i++) {
        const e = json.equipments[i];
        block.setEquipmentCount(await getDevice(e.device), e.count);
      }
    }

    block.getNode().updateDimension();
    block.getNode().bake();

    return block;
  }

  public exportJSON(): string {
    return JSON.stringify(this, (key, value) => {
      if (key.startsWith('_')) return undefined;
      return value;
    });
  }

  public getId() {
    return this.id;
  }

  public setId(id: string) {
    this.id = id;
  }

  public getName() {
    return this.name;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getType() {
    return this.type;
  }

  public setType(type: 'Single' | 'Double', singleType: 'Wall' | 'Free' = 'Free') {
    if (this.type !== type || this.singleType !== singleType) {
      this.type = type;
      switch (type) {
        case 'Single':
          if (this.rowTop) this.rowTop.cleanResources();
          this.rowTop = null;
          this.setSingleType(singleType);
          break;
        case 'Double':
          this.rowTop = new BlockRow('Top', this, this._node.getTop());
          this.singleType = null;
          break;

        default:
          break;
      }
      this.forAllItems(item => item.getNode().bake());
      this._node.updateDimension();
    }
  }

  public getSingleType() {
    if (!this.singleType) return 'Free';
    return this.singleType;
  }

  public setSingleType(singleType: 'Wall' | 'Free') {
    if (this.singleType !== singleType) {
      this.singleType = singleType;
      switch (singleType) {
        case 'Free':
          this.setDepthExtension(0);
          break;
        case 'Wall':
          if (this.depthExtension > 100) this.setDepthExtension(100);
          break;
      }
      this.forAllItems(item => item.getNode().bake());
      this._node.updateDimension();
    }
  }

  public getRowTop() {
    return this.rowTop;
  }

  public getRowBottom() {
    return this.rowBottom;
  }

  public getBoard() {
    return this.board;
  }

  public enableBoard(value: boolean, device?: Device) {
    if (value) {
      // Enable
      if (this.canBoardBeEnabled()) {
        if (this.board) this.board.cleanResources();
        this.board = new BlockBoard(this, device);
        this._node.updateBottomBase();
        if (this.depthExtension < 100) {
          this.setDepthExtension(100);
        }
      }
    } else {
      // Disable
      if (this.board) {
        this.board.cleanResources();
        this.board = null;
        this._node.updateBottomBase();
      }
    }
    this._node.updateLabels();
  }

  public canBoardBeEnabled() {
    return this.type === 'Double';
  }

  public getDepthExtension() {
    return this.depthExtension;
  }

  public setDepthExtension(depthExtension: number) {
    this.depthExtension = depthExtension;
    this._node.updateDimension();
    this._node.updateLabels();
    if (this.board) this.board.getNode().reposition();
    if (depthExtension < 100) this.enableBoard(false);
    if (this.type === 'Single' && this.singleType === 'Wall') this.rowBottom.getNode().foreachDeviceNode(node => node.bake());
  }

  private bakeSideItems() {
    let item: BlockItem | BlockGroup = null;
    if (this.getRowTop()) {
      item = this.getRowTop().getItems()[0];
      if (item) item.getNode().bake();
      item = this.getRowTop().getItems()[this.getRowTop().getItems().length - 1];
      if (item) item.getNode().bake();
    }
    if (this.getRowBottom()) {
      item = this.getRowBottom().getItems()[0];
      if (item) item.getNode().bake();
      item = this.getRowBottom().getItems()[this.getRowBottom().getItems().length - 1];
      if (item) item.getNode().bake();
    }
  }

  public getSideBoardLeftId() {
    if (this.type === 'Single' && this.singleType === 'Wall') return null;
    return this.sideBoardLeft;
  }

  public getSideBoardLeftObject() {
    if (this.type === 'Single' && this.singleType === 'Wall') return null;
    return this._sideBoardLeft;
  }

  public setSideBoardLeft(value: Device) {
    if (value) {
      this.sideBoardLeft = value.id;
      this._sideBoardLeft = value;
    } else {
      this.sideBoardLeft = null;
      this._sideBoardLeft = null;
    }
    this._node.triggerSideBoardLeftChange();
    this._node.updateLabels();
    this._node.bake();
    this.bakeSideItems();
  }

  public getSideBoardLeftWidth() {
    if (!this.sideBoardLeftWidth) return 300;
    return this.sideBoardLeftWidth;
  }

  public setSideBoardLeftWidth(value: number) {
    if (typeof value !== 'number') return;
    this.sideBoardLeftWidth = value;
    this._node.updateLabels();
    this._node.bake();
  }

  public getSideBoardRightId() {
    if (this.type === 'Single' && this.singleType === 'Wall') return null;
    return this.sideBoardRight;
  }

  public getSideBoardRightObject() {
    if (this.type === 'Single' && this.singleType === 'Wall') return null;
    return this._sideBoardRight;
  }
  public setSideBoardRight(value: Device) {
    if (value) {
      this.sideBoardRight = value.id;
      this._sideBoardRight = value;
    } else {
      this.sideBoardRight = null;
      this._sideBoardRight = null;
    }
    this._node.triggerSideBoardRightChange();
    this._node.updateLabels();
    this._node.bake();
    this.bakeSideItems();
  }

  public getSideBoardRightWidth() {
    if (!this.sideBoardRightWidth) return 300;
    return this.sideBoardRightWidth;
  }

  public setSideBoardRightWidth(value: number) {
    if (typeof value !== 'number') return;
    this.sideBoardRightWidth = value;
    this._node.updateLabels();
    this._node.bake();
  }

  public isMasterline() {
    return this.blockType === 'Masterline';
  }

  public setMasterline(masterline: boolean) {
    this.blockType = masterline ? 'Masterline' : 'Modular';
  }

  public getBlockType() {
    return this.blockType;
  }

  public setBlockType(blockType: BlockType) {
    this.blockType = blockType;
  }

  public isFlex() {
    return this.flex;
  }

  public setFlex(flex: boolean) {
    this.flex = flex;
  }

  public getWidth() {
    return this.width;
  }

  public setWidth(width: number, skipBoardUpdate?: boolean) {
    const grid = Dimensions.MM(Dimensions.GRID);
    const modWidth = width % grid;
    width += modWidth > grid / 2 ? grid - modWidth : -modWidth;
    width = Math.max(this.getMinWidth(), width);
    width = Math.min(width, 10000); // Max 10 Meter
    if (this.width !== width) {
      this.width = width;
      // console.log('width', width);
      this._node.updateDimension();
      if (this.board) {
        this.board.checkWidthChange();
        if (!skipBoardUpdate) {
          this.board.getNode().bake();
        }
      }
    }
  }

  public getMinWidth() {
    let width = 400;
    if (this.getRowTop()) {
      width = Math.max(width, this.getRowTop().getWidth());
    }
    if (this.getRowBottom()) {
      width = Math.max(width, this.getRowBottom().getWidth());
    }
    if (this.getBoard()) {
      width = Math.max(1800, width);
    }
    return width;
  }

  public getBorderLeftId() {
    return this.borderLeft;
  }

  public getBorderLeftObject() {
    return this._borderLeft;
  }

  public getBorderLeftStyle() {
    if (this.blockType === 'Modular') return 'BorderTrench';
    if (!this._borderLeft) return 'BorderAngular';
    else return this._borderLeft.style;
  }

  public isBorderLeftOverflow() {
    return Block.isBorderOverflow(this.getBorderLeftStyle());
  }

  public setBorderLeft(border: Device) {
    if (border) {
      this.borderLeft = border.id;
      this._borderLeft = border;
    } else {
      this.borderLeft = null;
      this._borderLeft = null;
    }

    if (this.board) this.board.getNode().bake();
    this.forAllItems(item => item.getNode().bake());
    this._node.updateLabels();
    this._node.bake();
  }

  public getBorderRightId() {
    return this.borderRight;
  }

  public getBorderRightObject() {
    return this._borderRight;
  }

  public getBorderRightStyle() {
    if (this.blockType === 'Modular') return 'BorderTrench';
    if (!this._borderRight) return 'BorderAngular';
    else return this._borderRight.style;
  }

  public isBorderRightOverflow() {
    return Block.isBorderOverflow(this.getBorderRightStyle());
  }

  public setBorderRight(border: Device) {
    if (border) {
      this.borderRight = border.id;
      this._borderRight = border;
    } else {
      this.borderRight = null;
      this._borderRight = null;
    }

    if (this.board) this.board.getNode().bake();
    this.forAllItems(item => item.getNode().bake());
    this._node.updateLabels();
    this._node.bake();
  }

  public static isBorderOverflow(style: string) {
    switch (style) {
      case 'BorderAngular':
      case 'Border45':
      case 'BorderRound':
      case 'BorderRoundRound':
      case 'BorderRoundRound5cm':
        return true;
      default:
        return false;
    }
  }

  public getHandleId() {
    return this.handle;
  }

  public getHandle() {
    return this._handle;
  }

  public setHandle(device: Device) {
    if (device) {
      this.handle = device.id;
      this._handle = device;
    } else {
      this.handle = null;
      this._handle = null;
    }
    if (this.rowTop) this.rowTop.repositionItems();
    if (this.rowBottom) this.rowBottom.repositionItems();
  }

  public getHandleType() {
    return this.handleType;
  }

  public setHandleType(handleType: HandleType) {
    if (this.handleType != handleType) {
      this.handleType = handleType;
      if (this.rowTop) this.rowTop.repositionItems(true);
      if (this.rowBottom) this.rowBottom.repositionItems(true);
      this._node.bake();
    }
  }

  public getBottomHeight() {
    return this.bottomHeight;
  }

  public setBottomHeight(bottomHeight: number) {
    this.bottomHeight = bottomHeight;

    this.setItemProperty(DNPT.BottomHeight, bottomHeight, true);
    this._node.updateBottomBase();
    this._node.updateLabels();
    this._node.scaleInstallationWall();
  }

  public getBottom() {
    return this.bottom;
  }

  public setBottom(bottom: 'BaseMKN' | 'Base' | 'Feet') {
    this.bottom = bottom;

    switch (bottom) {
      case 'BaseMKN':
        {
          const material = Scene.CURRENT_SCENE.getMaterialByName('_metal_blue_PCover') as BABYLON.PBRMaterial;
          material.metallic = 0.7;
          material.roughness = 0.1;
          material.clearCoat.isEnabled = true;
        }
        break;
      case 'Base':
        {
          const material = Scene.CURRENT_SCENE.getMaterialByName('_metal_blue_PCover') as BABYLON.PBRMaterial;
          material.metallic = 0.25;
          material.roughness = 0.7;
          material.clearCoat.isEnabled = false;
        }
        break;
      case 'Feet':
        break;
    }

    this._node.updateBottomBase();
    this.setItemProperty(DNPT.Bottom, bottom, true);
  }

  public getBlendColor() {
    return this.blendColor;
  }

  public getBlendColorRGB() {
    return RGB.fromRAL(this.blendColor);
  }

  public setBlendColor(blendColor: number | RGB) {
    if (blendColor instanceof RGB) this.blendColor = blendColor.getRAL();
    else this.blendColor = blendColor;
    this.updateBlendColor();
  }

  public setBlendColorValue(value = { r: 0, g: 0, b: 0 }) {
    if (value.r !== 155 && value.g != 155 && value.b !== 160) this.blendColor = new RGB(value.r, value.g, value.b).getRAL();
    else this.blendColor = null;
    this.updateBlendColor();
  }

  public updateBlendColor() {
    const rgb = RGB.fromRAL(this.blendColor);
    if (this._blendColorMaterial) this._blendColorMaterial.albedoColor = rgb ? rgb.get() : new RGB(155, 155, 160).get();
  }

  public getBlendColorMaterial() {
    return this._blendColorMaterial;
  }

  public isFullBlendColor() {
    return this._fullBlendColor;
  }

  public checkFullBlendColor() {
    let colored = true;

    if (colored && this.rowTop) {
      colored = this._isFullBlendColorRow(this.rowTop);
    }
    if (colored && this.rowBottom) {
      colored = this._isFullBlendColorRow(this.rowBottom);
    }

    if (colored !== this._fullBlendColor) {
      this._fullBlendColor = colored;
      if (this.rowTop) this.rowTop.getItems().forEach(i => this._changeFullBlendColor(i.getNode()));
      if (this.rowBottom) this.rowBottom.getItems().forEach(i => this._changeFullBlendColor(i.getNode()));
      this._changeFullBlendColor(this._node);
    }
  }

  private _changeFullBlendColor(node: BlockNode | DeviceNode | BlockGroupNode) {
    node
      ?.getOutputNode()
      ?.getChildMeshes()
      .forEach(n => {
        if (n.name === 'mesh-_metal_blue_panel') {
          if (this.isMasterline() && this.isFullBlendColor()) n.material = this.getBlendColorMaterial();
          else n.material = MaterialUtils.MATERIAL_METAL;
        }
      });

    if (node instanceof BlockGroupNode)
      node
        .getBlockGroup()
        .getItems()
        .forEach(i => this._changeFullBlendColor(i.getNode()));
  }

  private _isFullBlendColorRow(row: BlockRow) {
    for (let i = 0; i < row.getItems().length; i++) {
      const item = row.getItems()[i];
      if (item instanceof BlockGroup) {
        for (let j = 0; j < item.getItems().length; j++) {
          const groupItem = item.getItems()[j];
          // if (!groupItem.getDeviceObject().dependency.colorBlend) {
          //   console.log('No Blend Color: ', groupItem);
          //   return false;
          // } else {
          for (let e = 0; e < groupItem.getEquipments().length; e++) {
            const equipment = groupItem.getEquipments()[e];
            // if (!equipment.getDeviceObject().dependency.colorBlend) {
            // console.log('No Blend Color: ', groupItem, equipment);
            if (isSubtype(equipment.getDeviceObject().subtype, [Subtype.EOven, Subtype.GOven])) {
              return false;
            }
          }
          //}
        }
      } // else {
      // if (!item.getDeviceObject().dependency.colorBlend) {
      //   console.log('No Blend Color: ', item);
      //   return false;
      // } else {
      for (let e = 0; e < item.getEquipments().length; e++) {
        const equipment = item.getEquipments()[e];
        // if (!equipment.getDeviceObject().dependency.colorBlend) {
        // console.log('No Blend Color: ', item, equipment);
        if (isSubtype(equipment.getDeviceObject().subtype, [Subtype.EOven, Subtype.GOven])) {
          return false;
        }
      }
      // }
      // }
    }
    return true;
  }

  public getDoorColor() {
    return this.doorColor;
  }

  public getDoorColorRGB() {
    return RGB.fromRAL(this.doorColor);
  }

  public setDoorColor(doorColor: number | RGB) {
    if (doorColor instanceof RGB) this.doorColor = doorColor.getRAL();
    else this.doorColor = doorColor;
    this.updateDoorColor();
  }

  public setDoorColorValue(value = { r: 0, g: 0, b: 0 }) {
    if (value.r !== 155 && value.g != 155 && value.b !== 160) this.doorColor = new RGB(value.r, value.g, value.b).getRAL();
    else this.doorColor = null;
    this.updateDoorColor();
  }

  public updateDoorColor() {
    const rgb = RGB.fromRAL(this.doorColor);
    if (this._doorColorMaterial) this._doorColorMaterial.albedoColor = rgb ? rgb.get() : new RGB(155, 155, 160).get();
  }

  public getDoorColorMaterial() {
    return this._doorColorMaterial;
  }

  public getPosition() {
    return this.position;
  }

  public setPosition(value: Vector3) {
    this.position = value;
  }

  public getRotation() {
    return this.rotation;
  }

  public setRotation(rotation: Vector3) {
    this.rotation = rotation;
    this.getNode().rotation = rotation.get();
  }

  public isSideCover() {
    return this.sideCover;
  }

  public setSideCover(value: boolean) {
    if (this.type === 'Single' && this.singleType === 'Wall') value = false;
    if (this.sideCover !== value) {
      this.sideCover = value;

      this.bakeSideItems();
      // Extension
      this.getNode().bake();
    }
  }

  public isSteelKnob() {
    return this.steelKnob;
  }

  public setSteelKnob(value: boolean) {
    MaterialUtils.masterknob(this._steelKnobMaterial, value);
    this.steelKnob = value;
  }

  public getSteelKnobMaterial() {
    return this._steelKnobMaterial;
  }

  public isInstallationWall() {
    return !!this.installationWall;
  }

  public getInstallationWallId() {
    return this.installationWall;
  }

  public getInstallationWallDevice() {
    return this._installationWall;
  }

  public setInstallationWall(value: Device) {
    this.installationWall = value?.id;
    this._installationWall = value;
    this._node.setInstallationWall(!!value);
  }

  public getInstallationWallDepth() {
    if (!this.installationWall) return 0;
    if (!this.installationWallDepth || this.installationWallDepth < Dimensions.GRID) return Dimensions.GRID;
    return this.installationWallDepth;
  }

  public setInstallationWallDepth(value: number) {
    this.installationWallDepth = value;
    this._node.scaleInstallationWall();
    this._node.updateDimension();
  }

  public getInstallationWallHeight() {
    if (!this.installationWall) return 0;
    if (this.installationWall !== 'lwNZp') return 0;
    return this.installationWallHeight;
  }

  public setInstallationWallHeight(value: number) {
    this.installationWallHeight = value;
    this._node.scaleInstallationWall();
    this._node.updateDimension();
  }

  public getEquipments() {
    return this.equipments;
  }

  public addEquipment(device: Device) {
    const e = new Equipment(null, device);
    this.equipments.push(e);
    return e;
  }

  public getEquipment(device: Device | string) {
    let id = '';

    if (typeof device === 'string') id = device;
    else id = device.id;

    for (let i = 0; i < this.equipments.length; i++) {
      const e = this.equipments[i];
      if (e.getDeviceId() == id) return e;
    }
    return null;
  }

  public removeEquipment(device: Device | string) {
    let id = '';

    if (typeof device === 'string') id = device;
    else id = device.id;

    for (let i = 0; i < this.equipments.length; i++) {
      const e = this.equipments[i];
      if (e.getDeviceId() == id) {
        this.equipments.splice(i, 1);
        return e;
      }
    }

    return null;
  }

  public getEquipmentCount(device: Device | string) {
    const e = this.getEquipment(device);
    if (e) return e.getCount();
    else return 0;
  }

  public setEquipmentCount(device: Device, count: number) {
    if (count < 1) return this.removeEquipment(device);

    let e = this.getEquipment(device);
    if (e) e.setCount(count);
    else (e = this.addEquipment(device)).setCount(count);

    return e;
  }

  public getNode() {
    return this._node;
  }

  public setNode(node: BlockNode) {
    this._node = node;
  }

  public getVoltage() {
    return this.voltage;
  }

  public setVoltage(voltage: number) {
    if (typeof voltage !== 'number') voltage = Number(voltage);
    if (this.voltage != voltage) {
      const checkVoltage = (item: BlockItem, ov: number, nv: number) => {
        // console.log('checkVoltage', 'item', item._device.energy.voltage, ov, nv);
        if (item._device.energy.voltage === ov) {
          item.setError(false);
          let deviceCouldBeReplaced = false;
          getDevicesForCode(item._device.code)
            .then(devices => {
              for (let i = 0; i < devices.length; i++) {
                const device = devices[i];
                // console.log('checkVoltage', 'device', device.energy.voltage, ov, nv);
                if (device.energy.voltage === nv) {
                  deviceCouldBeReplaced = true;
                  item.setDeviceObject(device);
                  break;
                }
              }
              if (!deviceCouldBeReplaced) item.setError(true);
            })
            .catch(error => console.log('setVoltage:checkVoltage:getDevicesForCode', error));
        }
      };
      this.forAllItems(item => {
        if (item instanceof BlockGroup) {
          item.getItems().forEach(subItem => {
            checkVoltage(subItem, this.voltage, voltage);
          });
        } else {
          checkVoltage(item, this.voltage, voltage);
        }
      });
      this.voltage = voltage;
    }
  }

  public getUpturn() {
    return this.upturn;
  }

  public setUpturn(upturn: number) {
    this.upturn = upturn;
    this.forAllItems(item => item.getNode().bake());
  }

  public getUpturnL() {
    return this.upturnL;
  }

  public setUpturnL(upturnL: number) {
    this.upturnL = upturnL;
    this.forAllItems(item => item.getNode().bake());
  }

  public getUpturnR() {
    return this.upturnR;
  }

  public setUpturnR(upturnR: number) {
    this.upturnR = upturnR;
    this.forAllItems(item => item.getNode().bake());
  }
}
