import { Selectbox } from 'atoms';
import { EditorContext, LangContext } from 'context';
import Localizations from 'languages';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { ContextMenu, EquipmentSwitch } from 'page/Editor/Menu/Shared';
import { useContext } from 'react';
import { colors } from 'styles/theme';
import { Device, Subtype } from 'types';
import { isSubtype } from 'utils';
import { useHandleSelection } from './hooks';

export const MarineMeisterUpperStructures: React.FC = () => {
  const { setMarineMeisterView, setSelection, selection, scene } = useContext(EditorContext);
  const { lang, langId } = useContext(LangContext);

  const {
    availableSubtypes,
    availableSockets,
    availableDevices,
    mergeMode,
    selectionType,
    usedDevices,
    setUsedDevices,
    setSelectedUpperPowerSocket,
    setSelectedUpperPowerSocket_Second,
    selectedUpperPowerSocket,
    selectedUpperPowerSocket_Second
  } = useHandleSelection();

  return (
    <div>
      <ContextMenu
        mode="back"
        onClick={() => {
          setMarineMeisterView('home');
          setSelection(null);
          scene.setSelected(null);
        }}
      />
      {selectionType && !(selectionType === 'BlockItem' && mergeMode) && availableSubtypes.length > 1 && (
        <div
          className="bold uppercase text-xs mb-1"
          style={{
            color: colors['greyText']
          }}
        >
          {Localizations['specialEquipment'][lang]}
        </div>
      )}

      {!selection && <div className="mt-4 text-sm bold">{Localizations['pickADevice'][lang]}</div>}
      {selection && availableSubtypes.length < 1 && <div className="mt-4 text-sm bold">{Localizations['noUpperDevices'][lang]}</div>}
      {selection && selection instanceof BlockItem && (
        <div className="Custom-Scroll" style={{ maxHeight: 'calc(100% - 80px)' }}>
          {availableSubtypes.includes(Subtype.UpperPowerSocket) && (
            <Selectbox
              label={'1. ' + Localizations['upperPowerSocket'][lang]}
              disabled={!EquipmentHelper.canHaveUpperPowerSocket(selection)}
              options={[Localizations['none'][lang], ...availableSockets]}
              state={selectedUpperPowerSocket}
              setState={(value: string) => {
                EquipmentHelper.setUpperPowerSocket(
                  selection,
                  availableDevices.find((s: Device) => s.translations[langId].name === value)
                );
                setSelectedUpperPowerSocket(value);
              }}
            />
          )}
          {availableSubtypes.includes(Subtype.UpperPowerSocket) && (
            <div className="mt-1">
              <Selectbox
                label={'2. ' + Localizations['upperPowerSocket'][lang]}
                disabled={!EquipmentHelper.canHaveUpperPowerSocket(selection, 1)}
                options={[Localizations['none'][lang], ...availableSockets]}
                state={selectedUpperPowerSocket_Second}
                setState={(value: string) => {
                  EquipmentHelper.setUpperPowerSocket(
                    selection,
                    availableDevices.find((s: Device) => s?.translations[langId].name === value),
                    1
                  );
                  setSelectedUpperPowerSocket_Second(value);
                }}
              />
            </div>
          )}
          {availableSubtypes.includes(Subtype.MixingFaucet) && (
            <EquipmentSwitch
              disabled={!EquipmentHelper.canHaveMixingFaucet(selection)}
              description={
                availableDevices.find(d => isSubtype(d.subtype, ['MixingFaucet']))
                  ? availableDevices.find(d => isSubtype(d.subtype, ['MixingFaucet'])).translations[langId].name
                  : ''
              }
              status={usedDevices.MixingFaucet}
              label={Localizations['mixingFaucet'][lang]}
              toggleStatus={() => {
                if (!usedDevices.MixingFaucet) {
                  EquipmentHelper.setMixingFaucet(
                    selection,

                    availableDevices.find(d => isSubtype(d.subtype, ['MixingFaucet']))
                  );
                } else {
                  EquipmentHelper.setMixingFaucet(selection, null);
                }
                setUsedDevices({ ...usedDevices, MixingFaucet: !usedDevices.MixingFaucet });
              }}
              type="first"
            />
          )}
          {availableSubtypes.includes(Subtype.ColdWaterInlet) && (
            <EquipmentSwitch
              disabled={!EquipmentHelper.canHaveColdWaterInlet(selection)}
              description={
                availableDevices.find(d => isSubtype(d.subtype, ['ColdWaterInlet']))
                  ? availableDevices.find(d => isSubtype(d.subtype, ['ColdWaterInlet'])).translations[langId].name
                  : ''
              }
              status={usedDevices.ColdWaterInlet}
              label={Localizations['coldWaterInlet'][lang]}
              toggleStatus={() => {
                if (!usedDevices.ColdWaterInlet) {
                  EquipmentHelper.setColdWaterInlet(
                    selection,

                    availableDevices.find(d => isSubtype(d.subtype, ['ColdWaterInlet']))
                  );
                } else {
                  EquipmentHelper.setColdWaterInlet(selection, null);
                }
                setUsedDevices({ ...usedDevices, ColdWaterInlet: !usedDevices.ColdWaterInlet });
              }}
              type="first"
            />
          )}
          {availableSubtypes.includes(Subtype.DoubleSidedOperation) && (
            <EquipmentSwitch
              disabled={!EquipmentHelper.canHaveDoubleSidedOperation(selection)}
              description={
                availableDevices.find(d => isSubtype(d.subtype, ['DoubleSidedOperation']))
                  ? availableDevices.find(d => isSubtype(d.subtype, ['DoubleSidedOperation'])).translations[langId].name
                  : ''
              }
              status={usedDevices.DoubleSidedOperation}
              label={Localizations['doubleSidedOperation'][lang]}
              toggleStatus={() => {
                if (!usedDevices.DoubleSidedOperation) {
                  EquipmentHelper.setDoubleSidedOperation(
                    selection,
                    availableDevices.find(d => isSubtype(d.subtype, ['DoubleSidedOperation']))
                  );
                } else {
                  EquipmentHelper.setDoubleSidedOperation(selection, null);
                }
                setUsedDevices({ ...usedDevices, DoubleSidedOperation: !usedDevices.DoubleSidedOperation });
              }}
              type="first"
            />
          )}
        </div>
      )}
    </div>
  );
};
