import React, { CSSProperties, useEffect, useState } from 'react';

import './style.scss';

interface Props {
  className?: string;
  onClick?: (e: any) => void;
  delay?: number;
  duration?: number;
  style?: CSSProperties;
}

export const ASimple: React.FC<Props> = ({ children, className = '', duration, delay, onClick, style }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className={className + ' ASimple' + (mounted ? ' ASimple-Enter' : '')} onClick={onClick} style={style}>
      {children}
    </div>
  );
};
