import React, { FC, useContext } from 'react';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// ===================================================================
interface Props {}

// ===================================================================
export const MasterlineHome: FC<Props> = () => {
  const { configuration, canAdvanceMasterline, selectedMasterline, setMasterlineView } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  const deviceAdded =
    selectedMasterline &&
    (selectedMasterline.getRowBottom().getItems().length > 0 || (selectedMasterline.getRowTop() && selectedMasterline.getRowTop().getItems().length > 0));

  return (
    <div className="flex-col h-100 justify-between">
      {/* <ContextMenu /> */}
      <div style={{ marginTop: '3rem' }}>
        <div className="Submenu-Category" onClick={() => setMasterlineView('structure')}>
          {`01 ${Localizations['structure'][lang]}`}
        </div>
        <div
          className={`Submenu-Category${
            !canAdvanceMasterline && (!configuration || (configuration && configuration.getMasterline() && !(configuration.getMasterline().length > 0)))
              ? '-Disabled'
              : ''
          }`}
          onClick={() => {
            if (configuration && configuration.getMasterline()) {
              setMasterlineView('functional');
            }
          }}
        >
          {`02 ${Localizations['functionalDevices'][lang]}`}
        </div>
        <div
          className={`Submenu-Category${!deviceAdded ? '-Disabled' : ''}`}
          onClick={() => {
            if (deviceAdded) setMasterlineView('upperStructures');
          }}
        >
          {`03 ${Localizations['upperStructures'][lang]}`}
        </div>
        <div
          className={`Submenu-Category${!deviceAdded ? '-Disabled' : ''}`}
          onClick={() => {
            if (deviceAdded) setMasterlineView('substructures');
          }}
        >
          {`04 ${Localizations['substructureOptions'][lang]}`}
        </div>
        <div
          className={`Submenu-Category${!deviceAdded ? '-Disabled' : ''}`}
          onClick={() => {
            if (deviceAdded) setMasterlineView('global');
          }}
        >
          {`05 ${Localizations['globalSettings'][lang]}`}
        </div>
      </div>
    </div>
  );
};
