import { useContext, useEffect, useState } from 'react';
import { EditorContext } from 'context/editor-context';
import BlockItem from '../../../configuration/BlockItem';

const MIN = 100;

export const useExternalDevice = () => {
  const { selection } = useContext(EditorContext);
  const [initialized, setInitialized] = useState(false);
  const [isExternal, setIsExternal] = useState<boolean>(null);
  const [externalWidth, setExternalWidth] = useState(100);
  const [externalHeight, setExternalHeight] = useState(100);
  const [externalDepth, setExternalDepth] = useState(100);

  useEffect(() => {
    setInitialized(false);
  }, [selection]);

  useEffect(() => {
    if (selection instanceof BlockItem && selection.getDeviceObject()?.style === 'Dummy') {
      if (!initialized) {
        setIsExternal(true);
        setExternalDepth(selection.getDepth());
        setExternalHeight(selection.getHeight());
        setExternalWidth(selection.getWidth());
      }
    } else {
      setIsExternal(false);
    }
    if (!initialized) {
      setInitialized(true);
    }
  }, [selection, initialized]);

  const setHeight = (height: number) => {
    if (selection instanceof BlockItem && selection.getDeviceObject()?.style === 'Dummy') {
      if (height >= MIN) {
        selection.setHeight(height);
        setExternalHeight(selection.getHeight());
      } else {
        setExternalHeight(height);
      }
    }
  };

  const setWidth = (width: number) => {
    if (selection instanceof BlockItem && selection.getDeviceObject()?.style === 'Dummy') {
      if (width >= MIN) {
        selection.setWidth(width);
        setExternalWidth(selection.getWidth());
      } else {
        setExternalWidth(width);
      }
    }
  };

  const setDepth = (depth: number) => {
    if (selection instanceof BlockItem && selection.getDeviceObject()?.style === 'Dummy') {
      if (depth >= MIN) {
        selection.setDepth(depth);
        setExternalDepth(selection.getDepth());
      } else {
        setExternalDepth(depth);
      }
    }
  };

  return {
    isExternal,
    externalDepth,
    externalHeight,
    externalWidth,
    setExternalDepth: setDepth,
    setExternalHeight: setHeight,
    setExternalWidth: setWidth
  };
};
