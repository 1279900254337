import RoomDoor from 'page/Editor/configuration/RoomDoor';
import Scene from 'page/Editor/Scene';
import WallClippingNode from './helper/WallClippingNode';

export default class RoomDoorNode extends WallClippingNode {
  private _roomDoor: RoomDoor;

  constructor(roomDoor: RoomDoor) {
    super(roomDoor, 'door', Scene.CURRENT_SCENE);
    this._roomDoor = roomDoor;
  }

  /**
   * Updates height based on RoomDoor
   */
  public updateHeight() {
    const height = this._roomDoor.getHeight() / 10;

    this._clipping.scaling.y = height;
    this._clipping.position.y = height / 2;
  }

  /**
   * @returns RoomDoor Object
   */
  public getRoomDoor() {
    return this._roomDoor;
  }
}
