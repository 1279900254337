import { useEffect, useState } from 'react';

export const useLoginDialog = () => {
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);

  const handleTogglePasswordDialog = () => setShowPasswordDialog(!showPasswordDialog);
  const handleToggleResetDialog = () => setShowResetDialog(!showResetDialog);
  const handleToggleRegisterDialog = () => setShowRegisterDialog(!showRegisterDialog);

  useEffect(() => {
    if (window.location.pathname === '/password-reset') {
      setShowResetDialog(true);
      setShowPasswordDialog(false);
    } else {
      setShowResetDialog(false);
    }

    return () => {
      setShowPasswordDialog(false);
      setShowResetDialog(false);
    };
  }, []);
  return {
    handleTogglePasswordDialog,
    handleToggleResetDialog,
    handleToggleRegisterDialog,
    showPasswordDialog,
    showResetDialog,
    showRegisterDialog
  };
};
