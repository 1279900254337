import { BlockSelector, LoadingSpinner } from 'components';
import { EditorContext } from 'context';
import { CategoryMenu, ContextMenu } from 'page/Editor/Menu/Shared';
import { useContext } from 'react';
import { Category } from 'types';
import { useMarineMeisterCategories } from './hooks';
import styles from './MarineMeisterFunctional.module.scss';

type Props = {
  setSelectedCategory: React.Dispatch<React.SetStateAction<Category>>;
};

export const MarineMeisterFunctional: React.FC<Props> = ({ setSelectedCategory }) => {
  const { setMarineMeisterView, setSelection, scene, selectedMarineMeister } = useContext(EditorContext);
  const { categories, isLoading } = useMarineMeisterCategories(selectedMarineMeister?.getVoltage());

  return (
    <div className={styles.FunctionalMenu}>
      <div className={styles.Header}>
        <div className={styles.SearchWrapper}>
          <ContextMenu></ContextMenu>
        </div>
        <div className="pt-2">
          <BlockSelector />
        </div>
        <div className={styles.BackWrapper}>
          <ContextMenu
            mode="back"
            noMargin
            onClick={() => {
              setMarineMeisterView('home');
              setSelection(null);
              scene.setSelected(null);
            }}
          />
        </div>
      </div>

      <div className={styles.ScrollWrapper}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && categories && (
          <div className={`Custom-Scroll ${styles.CustomScroll} pt-2`}>
            {categories.map((c, index) => {
              return (
                <CategoryMenu
                  key={`MarineMeister-Category-${c.id}`}
                  category={c}
                  isLast={index === categories.length - 1}
                  onSelect={() => setSelectedCategory(c)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
