import React, { FC, useContext, useEffect, useState } from 'react';

// Components
import { Icon, RadioButton } from 'atoms';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import { RoomWall } from 'page/Editor/configuration/RoomWall';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';
import RoomNode from 'components/babylon/node/RoomNode';
import Dimensions from 'utils/Dimensions';

// Context
interface Props {}

type Alignment = 'horizontally' | 'vertically';
type Wall = {
  width: number;
  depth: number;
  height: number;
  alignment: Alignment;
};

export const Walls: FC<Props> = () => {
  const { selection, setErrorAlert, setRoomView } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  const [wall, setWall] = useState<Wall>({
    width: selection instanceof RoomWall ? selection.getWidth() : 0,
    height: selection instanceof RoomWall ? selection.getHeight() : 0,
    depth: selection instanceof RoomWall ? selection.getDepth() : 0,
    alignment: 'horizontally'
  });

  useEffect(() => {
    if (selection && selection instanceof RoomWall) {
      setWall({
        width: selection instanceof RoomWall ? selection.getWidth() : 0,
        depth: selection instanceof RoomWall ? selection.getDepth() : 0,
        height: selection instanceof RoomWall ? selection.getHeight() : 0,
        alignment: selection instanceof RoomWall && selection.getRotation() === 0 ? 'horizontally' : 'vertically'
      });
    }
  }, [selection]);

  return (
    <div key="room-walls-menu">
      <ContextMenu mode="back" onClick={() => setRoomView('home')} />
      {selection && selection instanceof RoomWall ? (
        <>
          <div className="Submenu-Headline"> {Localizations['walls'][lang]}</div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={100}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setWall({
                      ...wall,
                      width: val
                    });
                    if (selection instanceof RoomWall) {
                      selection.setWidth(val);
                    }
                  }
                }}
                step={100}
                type="number"
                value={wall.width}
              />
              {Localizations['length'][lang]} (mm)
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={100}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setWall({
                      ...wall,
                      depth: val
                    });
                    if (selection instanceof RoomWall) {
                      selection.setDepth(val);
                    }
                  }
                }}
                step={25}
                type="number"
                value={wall.depth}
              />
              {Localizations['depth'][lang]} (mm)
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={Dimensions.MM(RoomNode.LOWER_WALL_HEIGHT)}
                max={3000}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setWall({
                      ...wall,
                      height: val
                    });
                    if (selection instanceof RoomWall) {
                      selection.setHeight(val);
                    }
                  }
                }}
                step={100}
                type="number"
                value={wall.height}
              />
              {Localizations['height'][lang]} (mm)
            </label>
          </div>
          <div className="Submenu-Attribute">
            <div>
              <RadioButton
                checked={wall.alignment === 'horizontally'}
                label={Localizations['horizontally'][lang]}
                onChange={() => {
                  setWall({ ...wall, alignment: 'horizontally' });
                  selection.setRotation(0);
                }}
                value={wall.alignment}
              />
            </div>
            <div style={{ marginLeft: '3rem' }}>
              <RadioButton
                checked={wall.alignment === 'vertically'}
                label={Localizations['vertically'][lang]}
                onChange={() => {
                  setWall({ ...wall, alignment: 'vertically' });
                  selection.setRotation(Math.PI / 2);
                }}
                value={wall.alignment}
              />
            </div>
          </div>
          <div className="DeviceDetails-Small-Bottom">
            <Icon
              color="medium"
              onClick={() => {
                setErrorAlert(['delete']);
              }}
              size="1.5rem"
              type="trash"
              stroke={3}
            />
          </div>
        </>
      ) : (
        <div className="mt-4 text-sm bold">{Localizations['selectWall'][lang]}</div>
      )}
    </div>
  );
};
