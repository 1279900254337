import React, { FC } from 'react';
import { Property } from 'csstype';

// Styles:
import { colors } from 'styles/theme';

// ==============================================================
interface Props {
  level?: '1' | '2';
  color?: 'white' | 'grey' | 'dark';
  fontSize?: string;
  fontWeight?: Property.FontWeight;
  margin?: string;
}

// ==============================================================
export const Heading: FC<Props> = ({ children, level = '1', color = 'dark', fontSize = '40px', fontWeight = 200, margin = '2rem 0' }) => {
  const style = {
    color: colors[color],
    margin: margin,
    fontSize: fontSize,
    fontWeight: fontWeight,
    letterSpacing: '1px'
  };

  return <>{level === '1' ? <h1 style={style}>{children}</h1> : <h2 style={style}>{children}</h2>}</>;
};
