const LNavigation = {
  "archive": {
    "fr": "Archives",
    "de": "Archiv",
    "en": "Archive",
    "cz": "Archiv"
  },
  "profile": {
    "fr": "Profil",
    "de": "Profil",
    "en": "Profile",
    "cz": "Profil"
  },
  "help": {
    "fr": "Aide",
    "de": "Hilfe",
    "en": "Help",
    "cz": "Nápověda"
  },
  "userManagement": {
    "fr": "Gestion des utilisateurs",
    "de": "Nutzerverwaltung",
    "en": "User management",
    "cz": "Správa uživatelů"
  },
  "language": {
    "fr": "Langue",
    "de": "Sprache",
    "en": "Language",
    "cz": "Jazyk"
  },
  "datasecurity": {
    "fr": "Protection des données",
    "de": "Datenschutzerklärung",
    "en": "Data Protection",
    "cz": "Prohlášení o ochraně osobních údajů"
  },
  "imprint": {
    "fr": "Impression",
    "de": "Impressum",
    "en": "Imprint",
    "cz": "Impressum"
  },
  "agb": {
    "fr": "Service",
    "de": "AGB",
    "en": "TOS",
    "cz": "VOP"
  }
};

export default LNavigation;