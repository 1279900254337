import React, { FC, useContext, useEffect, useState } from 'react';

// Context
import { EditorContext, LangContext } from 'context';

// Atoms:

// Languages:
import Localizations from 'languages';
import { colors } from 'styles/theme';
import Block from '../../configuration/Block';

interface Props {
  activePage: any;
  setActivePage: any;
}

export const BottomNav: FC<Props> = ({ activePage, setActivePage }) => {
  const {
    configuration,
    canAdvanceMarineMeister,
    canAdvanceMasterline,
    canAdvanceModular,
    scene,
    marineMeisterView,
    masterlineView,
    selectedMarineMeister,
    setMasterlineView,
    selectedMasterline,
    selectedModular,
    modularView,
    setMarineMeisterView,
    setModularView,
    setSelection,
    menuView
  } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const [itemsAdded, setItemsAdded] = useState(false);

  const navStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: '.65rem',
    fontWeight: 700,
    background: 'linear-gradient(180deg, rgba(0,0,0,0) calc(50% - 0.5px), rgba(192,192,192,1) calc(50%), rgba(0,0,0,0) calc(50% + 0.5px)',
    marginBottom: '6px'
  };

  const itemStyle = {
    fontSize: '.65rem',
    fontWeight: 700,
    backgroundColor: colors.extraLight,
    color: colors.medium,
    padding: '0 5px',
    flexShrink: 0,
    border: 'none'
  };

  const itemStyleDisabled = {
    fontSize: '.65rem',
    fontWeight: 700,
    backgroundColor: colors.extraLight,
    color: colors.disabledGrey,
    padding: '0 5px',
    flexShrink: 0,
    border: 'none',
    cursor: 'not-allowed'
  };

  const deselect = () => {
    setSelection(null);
    if (scene) {
      scene.setSelected(null);
    }
  };

  useEffect(() => {
    if (menuView === 'masterline') {
      if (configuration && configuration.getMasterline() && configuration.getMasterline().length > 0) {
        const containsDevices = configuration.getMasterline().findIndex((b: Block) => {
          return (
            (b.getRowBottom() && b.getRowBottom().getItems() && b.getRowBottom().getItems().length > 0) ||
            (b.getRowTop() && b.getRowTop().getItems() && b.getRowTop().getItems().length > 0)
          );
        });
        if (
          containsDevices !== -1 ||
          (selectedMasterline &&
            selectedMasterline.getRowBottom() &&
            selectedMasterline.getRowBottom().getItems() &&
            selectedMasterline.getRowBottom().getItems().length > 0)
        ) {
          setItemsAdded(true);
        } else {
          setItemsAdded(false);
        }
      } else {
        setItemsAdded(false);
      }
    }

    if (menuView === 'modular') {
      if (configuration && configuration.getModular() && configuration.getModular().length > 0) {
        const containsDevices = configuration.getModular().findIndex((b: Block) => {
          return (
            (b.getRowBottom() && b.getRowBottom().getItems() && b.getRowBottom().getItems().length > 0) ||
            (b.getRowTop() && b.getRowTop().getItems() && b.getRowTop().getItems().length > 0)
          );
        });
        if (
          containsDevices !== -1 ||
          (selectedModular &&
            selectedModular.getRowBottom() &&
            selectedModular.getRowBottom().getItems() &&
            selectedModular.getRowBottom().getItems().length > 0)
        ) {
          setItemsAdded(true);
        } else {
          setItemsAdded(false);
        }
      }
    }

    if (menuView === 'marineMeister') {
      if (configuration && configuration.getMarineMeister() && configuration.getMarineMeister().length > 0) {
        const containsDevices = configuration.getMarineMeister().findIndex((b: Block) => {
          return (
            (b.getRowBottom() && b.getRowBottom().getItems() && b.getRowBottom().getItems().length > 0) ||
            (b.getRowTop() && b.getRowTop().getItems() && b.getRowTop().getItems().length > 0)
          );
        });
        if (containsDevices !== -1 || selectedMarineMeister?.getRowBottom()?.getItems()?.length > 0) {
          setItemsAdded(true);
        } else {
          setItemsAdded(false);
        }
      }
    }
  }, [
    canAdvanceMarineMeister,
    canAdvanceMasterline,
    canAdvanceModular,
    configuration,
    menuView,
    marineMeisterView,
    modularView,
    masterlineView,
    selectedMarineMeister,
    selectedMasterline,
    selectedModular
  ]);

  useEffect(() => {
    if (menuView === 'masterline') {
      if (masterlineView === 'home') {
        setActivePage({ title: Localizations['startPage'][lang], number: 0, prev: null, next: () => setMasterlineView('structure') });
      } else if (masterlineView === 'structure') {
        setActivePage({
          title: Localizations['structure'][lang],
          number: 1,
          prev: () => setMasterlineView('home'),
          next: () => setMasterlineView('functional')
        });
        if (!(scene && scene.getSelected() instanceof Block)) deselect();
      } else if (masterlineView === 'functional') {
        setActivePage({
          title: Localizations['functionalDevices'][lang],
          number: 2,
          prev: () => setMasterlineView('structure'),
          next: () => setMasterlineView('upperStructures')
        });
        deselect();
      } else if (masterlineView === 'upperStructures') {
        setActivePage({
          title: Localizations['upperStructures'][lang],
          number: 3,
          prev: () => setMasterlineView('functional'),
          next: () => setMasterlineView('substructures')
        });
        // deselect();
      } else if (masterlineView === 'substructures') {
        setActivePage({
          title: Localizations['substructureOptions'][lang],
          number: 4,
          prev: () => setMasterlineView('upperStructures'),
          next: () => setMasterlineView('global')
        });
        // deselect();
      } else if (masterlineView === 'global') {
        setActivePage({ title: Localizations['globalSettings'][lang], number: 5, prev: () => setMasterlineView('substructures'), next: null });
        deselect();
      }
    }
  }, [masterlineView, lang]);

  useEffect(() => {
    if (menuView === 'modular') {
      if (modularView === 'home') {
        setActivePage({ title: Localizations['startPage'][lang], number: 0, prev: null, next: () => setMasterlineView('structure') });
      } else if (modularView === 'structure') {
        setActivePage({
          title: Localizations['structure'][lang],
          number: 1,
          prev: () => setModularView('home'),
          next: () => setModularView('functional')
        });
        if (!(scene && scene.getSelected() instanceof Block)) deselect();
      } else if (modularView === 'functional') {
        setActivePage({
          title: Localizations['functionalDevices'][lang],
          number: 2,
          prev: () => setModularView('structure'),
          next: () => setModularView('upperStructures')
        });
        deselect();
      } else if (modularView === 'upperStructures') {
        setActivePage({
          title: Localizations['upperStructures'][lang],
          number: 3,
          prev: () => setModularView('functional'),
          next: () => setModularView('substructures')
        });
        // deselect();
      } else if (modularView === 'substructures') {
        setActivePage({
          title: Localizations['substructureOptions'][lang],
          number: 4,
          prev: () => setModularView('upperStructures'),
          next: () => setModularView('global')
        });
        // deselect();
      } else if (modularView === 'global') {
        setActivePage({ title: Localizations['globalSettings'][lang], number: 5, prev: () => setModularView('substructures'), next: null });
        deselect();
      }
    }
  }, [modularView, lang]);

  useEffect(() => {
    if (menuView === 'marineMeister') {
      if (marineMeisterView === 'home') {
        setActivePage({ title: Localizations['startPage'][lang], number: 0, prev: null, next: () => setMarineMeisterView('structure') });
      } else if (marineMeisterView === 'structure') {
        setActivePage({
          title: Localizations['structure'][lang],
          number: 1,
          prev: () => setMarineMeisterView('home'),
          next: () => setMarineMeisterView('functional')
        });
        if (!(scene && scene.getSelected() instanceof Block)) deselect();
      } else if (marineMeisterView === 'functional') {
        setActivePage({
          title: Localizations['functionalDevices'][lang],
          number: 2,
          prev: () => setMarineMeisterView('structure'),
          next: () => setMarineMeisterView('upperStructures')
        });
        deselect();
      } else if (marineMeisterView === 'upperStructures') {
        setActivePage({
          title: Localizations['upperStructures'][lang],
          number: 3,
          prev: () => setMarineMeisterView('functional'),
          next: () => setMarineMeisterView('global')
        });
        // deselect();
      } else if (marineMeisterView === 'global') {
        setActivePage({ title: Localizations['globalSettings'][lang], number: 4, prev: () => setMarineMeisterView('upperStructures'), next: null });
        deselect();
      }
    }
  }, [marineMeisterView, lang]);

  if (menuView === 'modular') {
    return (
      <div style={navStyle}>
        <button
          onClick={() => {
            setModularView('structure');
          }}
          style={{ ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingLeft: '0' }}
        >{`01 ${modularView === 'structure' ? activePage.title : ''}`}</button>
        <button
          disabled={!configuration || !selectedModular}
          onClick={() => {
            setModularView('functional');
          }}
          style={
            selectedModular
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap' }
          }
        >{`02 ${modularView === 'functional' ? activePage.title : ''}`}</button>
        <button
          disabled={!canAdvanceModular}
          onClick={() => {
            if (itemsAdded) {
              setModularView('upperStructures');
            }
          }}
          style={
            itemsAdded
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap' }
          }
        >{`03 ${modularView === 'upperStructures' ? activePage.title : ''}`}</button>
        <button
          disabled={!canAdvanceModular}
          onClick={() => {
            if (itemsAdded) {
              setModularView('substructures');
            }
          }}
          style={
            itemsAdded
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap' }
          }
        >{`04 ${modularView === 'substructures' ? activePage.title : ''}`}</button>
        <button
          disabled={!canAdvanceModular}
          onClick={() => {
            if (itemsAdded) {
              setModularView('global');
            }
          }}
          style={
            itemsAdded
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingRight: '0' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingRight: '0' }
          }
        >{`05 ${modularView === 'global' ? activePage.title : ''}`}</button>
      </div>
    );
  } else if (menuView === 'marineMeister') {
    return (
      <div style={navStyle}>
        <button
          onClick={() => {
            setMarineMeisterView('structure');
          }}
          style={{ ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingLeft: '0' }}
        >{`01 ${marineMeisterView === 'structure' ? activePage.title : ''}`}</button>
        <button
          disabled={!configuration || !selectedMarineMeister}
          onClick={() => {
            setMarineMeisterView('functional');
          }}
          style={
            selectedMarineMeister
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap' }
          }
        >{`02 ${marineMeisterView === 'functional' ? activePage.title : ''}`}</button>
        <button
          disabled={!itemsAdded}
          onClick={() => {
            if (itemsAdded) {
              setMarineMeisterView('upperStructures');
            }
          }}
          style={
            itemsAdded
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap' }
          }
        >{`03 ${marineMeisterView === 'upperStructures' ? activePage.title : ''}`}</button>

        <button
          disabled={!itemsAdded}
          onClick={() => {
            if (itemsAdded) {
              setMarineMeisterView('global');
            }
          }}
          style={
            itemsAdded
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingRight: '0' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingRight: '0' }
          }
        >{`04 ${marineMeisterView === 'global' ? activePage.title : ''}`}</button>
      </div>
    );
  } else {
    return (
      <div style={navStyle}>
        <button
          onClick={() => {
            setMasterlineView('structure');
          }}
          style={{ ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingLeft: '0' }}
        >{`01 ${masterlineView === 'structure' ? activePage.title : ''}`}</button>
        <button
          disabled={!configuration || !selectedMasterline}
          onClick={() => {
            setMasterlineView('functional');
          }}
          style={
            selectedMasterline
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap' }
          }
        >{`02 ${masterlineView === 'functional' ? activePage.title : ''}`}</button>
        <button
          disabled={!itemsAdded}
          onClick={() => {
            if (itemsAdded) {
              setMasterlineView('upperStructures');
            }
          }}
          style={
            itemsAdded
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap' }
          }
        >{`03 ${masterlineView === 'upperStructures' ? activePage.title : ''}`}</button>
        <button
          disabled={!itemsAdded}
          onClick={() => {
            if (itemsAdded) {
              setMasterlineView('substructures');
            }
          }}
          style={
            itemsAdded
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap' }
          }
        >{`04 ${masterlineView === 'substructures' ? activePage.title : ''}`}</button>
        <button
          disabled={!itemsAdded}
          onClick={() => {
            if (itemsAdded) {
              setMasterlineView('global');
            }
          }}
          style={
            itemsAdded
              ? { ...itemStyle, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingRight: '0' }
              : { ...itemStyleDisabled, textTransform: 'uppercase', whiteSpace: 'nowrap', paddingRight: '0' }
          }
        >{`05 ${masterlineView === 'global' ? activePage.title : ''}`}</button>
      </div>
    );
  }
};
