import * as BABYLON from 'babylonjs';
import LabelUtils, { Orientation } from 'components/babylon/util/LabelUtils';
import MarkUtils from 'components/babylon/util/MarkUtils';
import { WallClipping } from 'page/Editor/configuration/Room';
import Scene, { ViewName } from 'page/Editor/Scene';
import MaterialUtils from '../../util/MaterialUtils';
import RoomNode from '../RoomNode';

export default class WallClippingNode extends BABYLON.TransformNode {
  private _wallClipping: WallClipping;

  protected _clipping: BABYLON.Mesh;
  protected _picker: BABYLON.Mesh;
  protected _topping: BABYLON.Mesh;

  protected _labels: Array<BABYLON.TransformNode> = [];

  constructor(wallClipping: WallClipping, name?: string, scene?: BABYLON.Scene, isPure?: boolean) {
    super(name, scene, isPure);
    this._wallClipping = wallClipping;
    const clipping = BABYLON.Mesh.CreateBox('object', 1, Scene.CURRENT_SCENE);
    clipping.parent = this;
    clipping.scaling.z = RoomNode.WALL_STRENGTH + 0.5;
    clipping.isPickable = false;
    clipping.material = MaterialUtils.MATERIAL_WALL;
    const verticesCount = clipping.getTotalVertices();
    clipping.subMeshes = [];
    clipping.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, 0, 12, clipping));
    clipping.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 12, 22, clipping));
    clipping.setEnabled(false);
    this._clipping = clipping;
    const picker = BABYLON.Mesh.CreateBox('picker', 1, Scene.CURRENT_SCENE);
    picker.parent = this;
    picker.scaling.z = RoomNode.WALL_STRENGTH + 0.5;
    picker.scaling.y = RoomNode.WALL_HEIGHT + 0.5;
    picker.position.y = picker.scaling.y / 2;
    picker.material = MaterialUtils.MATERIAL_TRANSPARENT;
    picker.isPickable = true;
    this._picker = picker;
    const topping = BABYLON.Mesh.CreateGround('topping', 1, 1, 1, Scene.CURRENT_SCENE);
    topping.parent = this;
    topping.scaling.z = RoomNode.WALL_STRENGTH - 1;
    topping.position.y = RoomNode.WALL_HEIGHT + 0.25;
    topping.isPickable = false;
    topping.setEnabled(Scene.CURRENT_SCENE['getView']() === 'Top');
    this._topping = topping;

    Scene.CURRENT_SCENE['registerOnViewChange'](this.onViewChangeEvent);

    this.parent = wallClipping.getRoom().getNode();
  }

  onViewChangeEvent = (view: ViewName, camera: BABYLON.Camera) => {
    this._topping.setEnabled(view === 'Top');
  };

  public getClipping() {
    return this._clipping;
  }

  public getPicker() {
    return this._picker;
  }

  public getTopping() {
    return this._topping;
  }

  /**
   * Updates wall based on WallClipping
   */
  public updatePosition() {
    const pos = this._wallClipping.getPosition();
    this.position.x = pos;
    this.position.z = 0;
    switch (this._wallClipping.getWall()) {
      case 'Top':
        this._clipping.position.x = 0;
        this._picker.position.x = 0;
        this._picker.position.z = 0;
        this._picker.rotation.y = 0;
        this._topping.position.x = 0;
        this._topping.position.z = 0;
        this._topping.rotation.y = 0;
        break;
      case 'Right':
        this._clipping.position.x = -2 * pos;
        this._picker.position.x = -pos;
        this._picker.position.z = pos;
        this._picker.rotation.y = Math.PI * 0.5;
        this._topping.position.x = -pos;
        this._topping.position.z = pos;
        this._topping.rotation.y = Math.PI * 0.5;
        break;
      case 'Bottom':
        this._clipping.position.x = -2 * pos;
        this._picker.position.x = 0;
        this._picker.position.z = 0;
        this._picker.rotation.y = 0;
        this._topping.position.x = 0;
        this._topping.position.z = 0;
        this._topping.rotation.y = 0;
        break;
      case 'Left':
        this._clipping.position.x = 0;
        this._picker.position.x = -pos;
        this._picker.position.z = pos;
        this._picker.rotation.y = Math.PI * 1.5;
        this._topping.position.x = -pos;
        this._topping.position.z = pos;
        this._topping.rotation.y = Math.PI * 1.5;
        break;
    }
    // Clear old Labels
    this._labels.splice(0, this._labels.length).forEach(node => {
      node.dispose();
    });
    // Gen new Labels
    const roomW = this.getWallClipping().getRoom().getWidth() / 10;
    const roomD = this.getWallClipping().getRoom().getDepth() / 10;
    const clipW = this.getWallClipping().getWidth() / 10;
    const clipW2 = clipW / 2;

    const enabled = this._picker.material == MarkUtils.fullMaterial;
    const offset = 10;

    const depth = 20;
    const labelOptions = {
      depth: depth,
      lineMaterial: LabelUtils.lineMaterialBlue
    };

    switch (this._wallClipping.getWall()) {
      case 'Top':
        {
          let r = roomW - (pos + roomW / 2);
          let l = roomW - r;
          r -= clipW2;
          l -= clipW2;

          const left = LabelUtils.drawLabel('' + l * 10, l, Orientation.Top, labelOptions);
          left.setEnabled(enabled);
          left.position.z = roomD / 2 + offset;
          left.position.x = -roomW / 2;
          this._labels.push(left);
          const right = LabelUtils.drawLabel('' + r * 10, r, Orientation.Top, labelOptions);
          right.setEnabled(enabled);
          right.position.z = roomD / 2 + offset;
          right.position.x = roomW / 2 - r;
          this._labels.push(right);
        }
        break;
      case 'Right':
        {
          let r = roomD - (pos + roomD / 2);
          let l = roomD - r;
          r -= clipW2;
          l -= clipW2;

          const left = LabelUtils.drawLabel('' + l * 10, l, Orientation.Right, labelOptions);
          left.setEnabled(enabled);
          left.position.z = -roomD / 2;
          left.position.x = roomW / 2 + offset;
          this._labels.push(left);
          const right = LabelUtils.drawLabel('' + r * 10, r, Orientation.Right, labelOptions);
          right.setEnabled(enabled);
          right.position.z = roomD / 2 - r;
          right.position.x = roomW / 2 + offset;
          this._labels.push(right);
        }
        break;
      case 'Bottom':
        {
          let r = roomW - (pos + roomW / 2);
          let l = roomW - r;
          r -= clipW2;
          l -= clipW2;

          const left = LabelUtils.drawLabel('' + l * 10, l, Orientation.Bottom, labelOptions);
          left.setEnabled(enabled);
          left.position.z = -roomD / 2 - offset;
          left.position.x = -roomW / 2;
          this._labels.push(left);
          const right = LabelUtils.drawLabel('' + r * 10, r, Orientation.Bottom, labelOptions);
          right.setEnabled(enabled);
          right.position.z = -roomD / 2 - offset;
          right.position.x = roomW / 2 - r;
          this._labels.push(right);
        }
        break;
      case 'Left':
        {
          let r = roomD - (pos + roomD / 2);
          let l = roomD - r;
          r -= clipW2;
          l -= clipW2;

          const left = LabelUtils.drawLabel('' + l * 10, l, Orientation.Left, labelOptions);
          left.setEnabled(enabled);
          left.position.z = -roomD / 2;
          left.position.x = -roomW / 2 - offset;
          this._labels.push(left);
          const right = LabelUtils.drawLabel('' + r * 10, r, Orientation.Left, labelOptions);
          right.setEnabled(enabled);
          right.position.z = roomD / 2 - r;
          right.position.x = -roomW / 2 - offset;
          this._labels.push(right);
        }
        break;
    }
  }

  /**
   * Updates wall based on WallClipping
   */
  public updateWall() {
    const wall = this._wallClipping.getRoom().getNode().getWall(this._wallClipping.getWall());
    this.parent = wall.node;
  }

  /**
   * Updates width based on WallClipping
   */
  public updateWidth() {
    const width = this._wallClipping.getWidth() / 10;

    this._clipping.scaling.x = width;
    this._picker.scaling.x = width;
    this._topping.scaling.x = width;
  }

  public setEnabledMark(value: boolean) {
    if (value) this._picker.material = MarkUtils.fullMaterial;
    else this._picker.material = MaterialUtils.MATERIAL_TRANSPARENT;

    setTimeout(() => this.updatePosition(), 100);
  }

  public getWallClipping() {
    return this._wallClipping;
  }

  public setPickable(value: boolean) {
    this._picker.isPickable = value;
  }

  public setShowLabels(value: boolean) {
    //this._labels.forEach(label => label.setEnabled(value));
  }

  dispose() {
    Scene.CURRENT_SCENE['unregisterOnViewChange'](this.onViewChangeEvent);
    super.dispose();
  }
}
