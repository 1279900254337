import { EditorContext, LangContext } from 'context';
import { useFlexichefSubtypes } from 'page/Editor/Menu/Menus/Flexichef/Hooks';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import { Fragment, useContext, useEffect } from 'react';
import Localizations from 'languages';
import { EquipmentSwitch } from '../../../Shared/EquipmentSwitch';
import FreeItem from 'page/Editor/configuration/FreeItem';
import BlockItem from 'page/Editor/configuration/BlockItem';

export const Substructures = () => {
  const { selection, setFlexiMenuView } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  const { canHaveBaseCover, canHaveBaseCoverRight, toggleSelection, usedDevices } = useFlexichefSubtypes();

  useEffect(() => {
    return () => {
      setFlexiMenuView('main');
    };
  }, []);

  const isTeamDevice = (selection instanceof FreeItem || selection instanceof BlockItem) && selection.getDeviceObject()?.style.includes('-');
  return (
    <Fragment>
      <ContextMenu mode="back" onClick={() => setFlexiMenuView('main')}></ContextMenu>
      {selection && !canHaveBaseCover && <div className="bold mt-4 text-sm">{Localizations['noSubtypes'][lang]}</div>}
      {canHaveBaseCover && (
        <EquipmentSwitch
          status={usedDevices.baseCoverLeft}
          toggleStatus={() => toggleSelection('baseCoverLeft')}
          label={`${Localizations['baseCover'][lang]} ${isTeamDevice ? Localizations['left'][lang] : ''}`}
        />
      )}
      {canHaveBaseCoverRight && isTeamDevice && (
        <EquipmentSwitch
          status={usedDevices.baseCoverRight}
          toggleStatus={() => toggleSelection('baseCoverRight')}
          label={`${Localizations['baseCover'][lang]} ${Localizations['right'][lang]}`}
        />
      )}
    </Fragment>
  );
};
