const LDataProtection = {
  "mainHeader": {
    "fr": "Déclaration de confidentialité des données de MKN Maschinenfabrik Kurt Neubauer GmbH & Co. KG",
    "de": "Datenschutzerklärung der MKN Maschinenfabrik Kurt Neubauer GmbH & Co. KG",
    "en": "Data privacy statement for MKN Maschinenfabrik Kurt Neubauer GmbH & Co. KG",
    "cz": "Prohlášení o ochraně osobních údajů společnosti MKN Maschinenfabrik Kurt Neubauer GmbH & Co. KG"
  },
  "dataPrivacyPolicy": {
    "fr": "Politique de confidentialité des données",
    "de": "Datenschutzerklärung",
    "en": "Data Privacy Policy",
    "cz": "Prohlášení o ochraně osobních údajů"
  },
  "dataPrivacyPolicyContent": {
    "fr": "Nous sommes heureux de vous accueillir sur notre site web et vous remercions de l'intérêt que vous portez à notre entreprise. La protection de vos données est pour nous d'une importance capitale. Par les dispositions suivantes relatives à la protection de la vie privée, nous souhaitons vous informer à quelles fins et dans quelle mesure nous collectons ou traitons vos données personnelles lorsque vous utilisez notre site web et comment nous protégeons votre vie privée lorsque vous nous fournissez vos données.",
    "de": "Wir freuen uns über Ihren Besuch auf unseren Webseiten und Ihr Interesse an unserem Unternehmen. Für uns ist der Schutz Ihrer Daten von höchster Bedeutung. Mit den folgenden Hinweisen zum Datenschutz möchten wir Sie darüber informieren, für welche Zwecke und in welchem Umfang wir Ihre personenbezogenen Daten bei der Nutzung unserer Webseiten erheben bzw. verarbeiten und wie wir Ihre Privatsphäre schützen, wenn Sie uns Ihre Daten zur Verfügung stellen.",
    "en": "We are pleased to welcome you to our website and would like to thank you for your interest in our company. The protection of your data is of utmost importance to us. With the following privacy stipulations, we would like to inform you for which purposes and to what extent we collect or process your personal data when using our website and how we protect your privacy when you provide us with your data.",
    "cz": "Těší nás, že jste navštívili naše webové stránky a že se zajímáte o naši firmu. Ochrana vašich osobních údajů je pro nás velmi důležitá. Následujícími informacemi o ochraně osobních údajů bychom vás rádi informovali o tom, za jakým účelem a v jakém rozsahu shromažďujeme, resp. zpracováváme vaše osobní údaje při používání našich webových stránek a jak chráníme vaše soukromí, když nám své údaje poskytnete."
  },
  "responsibility": {
    "fr": "Responsabilité",
    "de": "Verantwortlichkeit",
    "en": "Responsibility",
    "cz": "Odpovědnost"
  },
  "responsibilityFirst": {
    "fr": "MKN Maschinenfabrik Kurt Neubauer GmbH & Co. KG, Halberstädter Straße 2a, 38300 Wolfenbüttel, tél : +49 5331 890, fax : +49 5331 89 280, e-mail : info@mkn.de est l'opérateur de ce site web et est l'entité responsable comme spécifié dans l'Art. 4 Para. 7 GDPR pour le respect des réglementations applicables en matière de protection des données.",
    "de": "Die MKN Maschinenfabrik Kurt Neubauer GmbH & Co. KG, Halberstädter Straße 2a, 38300 Wolfenbüttel, Telefon: +49 5331 89-0, Telefax: +49 5331 89-280, E-Mail: info@mkn.de ist Betreiberin dieser Webseiten und als verantwortliche Stelle im Sinne von Art. 4 Abs. 7 DSGVO für die Einhaltung der geltenden Datenschutzbestimmungen zuständig.",
    "en": "MKN Maschinenfabrik Kurt Neubauer GmbH & Co. KG, Halberstädter Straße 2a, 38300 Wolfenbüttel, tel.: +49 5331 890, fax: +49 5331 89 280, e-mail: info@mkn.de is the operator of this website and is the responsible entity as specified in Art. 4 Para. 7 GDPR for compliance with the applicable data protection regulations.",
    "cz": "Společnost MKN Maschinenfabrik Kurt Neubauer GmbH & Co. KG, Halberstädter Straße 2a, 38300 Wolfenbüttel, Telefon: +49 5331 89-0, Telefax: +49 5331 89-280, E-mail: info@mkn.de je provozovatelem těchto webových stránek a jako odpovědný subjekt ve smyslu čl. 4 odst. 7 GDPR odpovídá za dodržování platných ustanovení o ochraně osobních údajů."
  },
  "responsibilitySecond": {
    "fr": "Les données personnelles de nos utilisateurs sont enregistrées et traitées par nos soins dans le respect des dispositions applicables, notamment du règlement général sur la protection des données (RGPD), de la loi fédérale sur la protection des données (BDSG) et de la loi sur les télémédias (TMG) dans leurs versions actuelles. Toutes les données personnelles sont collectées uniquement à des fins déterminées, claires et légitimes et ne sont pas conservées plus longtemps que nécessaire.",
    "de": "Die personenbezogenen Daten unserer Nutzer werden unter Beachtung der einschlägigen Vorschriften insb. der Datenschutzgrundverordnung (DSGVO), des Bundesdatenschutzgesetzes (BDSG) und des Telemediengesetzes (TMG) in den jeweils aktuellen Fassungen von uns gespeichert und verarbeitet. Alle personenbezogenen Daten werden nur für festgelegte, eindeutige und rechtmäßige Zwecke erhoben und nicht länger als notwendig aufbewahrt.",
    "en": "Osobní údaje našich uživatelů jsou ukládány a zpracovávány za dodržení příslušných předpisů, zejména Nařízení o ochraně osobních údajů (GDPR), Spolkového zákona o ochraně osobních údajů (BDSG) a Zákona o telemédiích (TMG) v aktuálních zněních. Všechny osobní údaje jsou shromažďovány pouze pro stanovené, jednoznačné a oprávněné účely a jsou ukládány pouze po nezbytně nutnou dobu."
  }
};

export default LDataProtection;