import BlockItem from 'page/Editor/configuration/BlockItem';
import { useCallback, useContext, useMemo } from 'react';
import { EditorContext } from '../context/editor-context';

export const useHasFlexichefs = () => {
  const { selectedMasterline } = useContext(EditorContext);

  const hasFlexichefs = useCallback(() => {
    if (!selectedMasterline) return false;

    const tr = selectedMasterline.getRowTop();
    const br = selectedMasterline.getRowBottom();
    const topItems = tr?.getItems() ? tr.getItems() : [];
    const bottomItems = br?.getItems() ? br.getItems() : [];
    const items = [...topItems, ...bottomItems];
    return (
      items.findIndex(i => {
        return i instanceof BlockItem && i.getDeviceObject().model?.flexiChef;
      }) > -1
    );
  }, [selectedMasterline]);

  return {
    hasFlexichefs: hasFlexichefs()
  };
};
