import { LangContext } from 'context';
import Localizations from 'languages';
import { useContext } from 'react';

export const TableHeader = () => {
  const { lang } = useContext(LangContext);

  return (
    <thead>
      <tr>
        <th className="first-col">E-Mail</th>
        <th className="second-col">{Localizations['rights'][lang]}</th>
        <th className="third-col">Superuser</th>
        <th className="fourth-col">{Localizations['active'][lang]}</th>
      </tr>
    </thead>
  );
};
