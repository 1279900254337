import React, { FC, CSSProperties, Fragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// Partials:
import { TableSpacer } from './index';

// Atoms:
import { ATableRow, Icon } from 'atoms';

// Types
import { SavedConfiguration } from 'types';

// Utils:
import { post } from 'utils';
import { EditorContext } from 'context';

// Consts:
const ANIMATION_DURATION = 100;

// ===================================================================
interface Props {
  index: number;
  configuration: SavedConfiguration;
  deleteRow: (index: number) => void;
  style: CSSProperties;
  widths: Array<string>;
  marginTop?: string;
  marginBottom?: string;
  archive?: boolean;
}

// ===================================================================
export const TableRow: FC<Props> = ({ index, configuration, deleteRow, style, widths, marginTop = '1.5rem', marginBottom = '1.5rem', archive = false }) => {
  const { setSavingError } = useContext(EditorContext);
  const [config, setConfig] = useState<SavedConfiguration>(configuration);
  const [pose, setPose] = useState<'exit' | 'enter'>('exit');
  const [hover, setHover] = useState<boolean>(false);
  const history = useHistory();
  const padding = marginTop + ' 0.25rem ' + marginBottom;
  const styleWithPad = { ...style, padding: padding };

  // Effects
  useEffect(() => {
    if (pose === 'exit') setTimeout(() => setPose('enter'), /* index * */ ANIMATION_DURATION);
    if (pose === 'enter') {
      setPose('exit');
      setConfig(configuration);
      setTimeout(() => setPose('enter'), /* index *  */ ANIMATION_DURATION);
    }
    return () => {
      setPose(null);
    };
  }, [configuration]);

  useEffect(() => {
    if (pose === 'enter') saveConfig();
  }, [config]);

  // Methods
  /*   const togglePublic = () => setConfig({ ...config, public: !config.public });
   */
  const saveConfig = async () => {
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/configuration/save`, {
      data: {
        id: config.id,
        customer: config.customer,
        location: config.location,
        name: config.name,
        public: config.public,
        json: config.json
      }
    });

    if (data) console.log('Config successfully saved.');
    if (error) {
      setSavingError(true);
      console.log('Error saving the Config:', error);
    }
  };

  // Render
  return (
    <Fragment>
      <ATableRow pose={pose} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <td onClick={() => history.push(`/editor?id=${config.id}`)} style={{ ...styleWithPad, cursor: 'pointer' }}>
          {config.created?.split(' ')[0]}
        </td>
        <td onClick={() => history.push(`/editor?id=${config.id}`)} style={{ ...styleWithPad, cursor: 'pointer' }}>
          {config.id}
        </td>
        <td onClick={() => history.push(`/editor?id=${config.id}`)} style={{ ...styleWithPad, cursor: 'pointer' }}>
          {config.customer}
        </td>
        <td
          onClick={() => history.push(`/editor?id=${config.id}`)}
          style={{ ...styleWithPad, width: widths[archive ? 3 : 2], fontWeight: 'bold', cursor: 'pointer' }}
        >
          {config.name}
        </td>
        <td onClick={() => history.push(`/editor?id=${config.id}`)} style={{ ...styleWithPad, cursor: 'pointer' }}>
          {config.location}
        </td>
        {!archive ? (
          <>
            {/* <td style={{ ...styleWithPad }}>
              <Switch type="hollow" status={config.public} toggleStatus={togglePublic} inactiveColor="#6BA3D0" size={40} />
            </td> */}
            <td
              style={{
                width: widths[1],
                textAlign: 'center',
                fontWeight: 'bold',
                padding: padding
              }}
            >
              <Icon color="white" type="trash" size="1.5rem" stroke={2} onClick={() => deleteRow(index)} />
            </td>
          </>
        ) : (
          <>
            <td onClick={() => history.push(`/editor?id=${config.id}`)} style={{ ...styleWithPad, width: widths[4], cursor: 'pointer' }}>
              {config.email}
            </td>
          </>
        )}
      </ATableRow>
      <TableSpacer count={6} pose={pose} />
    </Fragment>
  );
};
