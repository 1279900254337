import { RoomWall } from 'page/Editor/configuration/RoomWall';
import Scene from 'page/Editor/Scene';
import InnerRoomNode from './helper/InnerRoomNode';

export class RoomWallNode extends InnerRoomNode {
  private _roomWall: RoomWall;

  constructor(roomWall: RoomWall) {
    super(roomWall, 'wall', Scene.CURRENT_SCENE);
    this._roomWall = roomWall;
  }

  public getRoomWall() {
    return this._roomWall;
  }
}
