import { createContext } from 'react';
import { Member, Organization } from 'types';

type OrganizationDataContextType = {
  loading: boolean;
  selectedUser: Member;
  setSelectedUser: React.Dispatch<React.SetStateAction<Member>>;
  organizationData: Organization;
  setOrganizationData: React.Dispatch<React.SetStateAction<Organization>>;
  refetchData: () => void;
};

export const OrganizationDataContext = createContext<OrganizationDataContextType>({
  loading: false,
  organizationData: null,
  refetchData: () => {},
  selectedUser: null,
  setSelectedUser: () => {},
  setOrganizationData: () => {}
});
