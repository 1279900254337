import { EditorContext } from 'context';
import { useContext, useEffect } from 'react';

export const useUpdateSubstructureMode = ({ somethingSelected }: { somethingSelected: React.MutableRefObject<boolean> }) => {
  const { scene, setSelection } = useContext(EditorContext);
  useEffect(() => {
    if (scene) {
      scene.setSubstructureMode(true);
      if (scene.getSelected()) {
        setSelection(scene.getSelected());
        somethingSelected.current = true;
      }
    }

    return () => {
      if (scene) {
        scene.setSubstructureMode(false);
      }
      setSelection(null);
    };
  }, []);
};
