import React, { useContext, useEffect, useState } from 'react';
import { Portal } from 'react-portal';

// Atoms:
import { ADialog, ASimple, Button, Heading, Input } from 'atoms';

// Components:
import { LoadingSpinner } from 'components';

// Context:
import { LangContext } from 'context';

// Languages:
import Localizations from 'languages';

// Utils:
import { post } from 'utils';

interface Props {
  toggleDialog: () => void;
}

export const RegisterDialog: React.FC<Props> = ({ toggleDialog }) => {
  const { lang } = useContext(LangContext);
  const [email, setEmail] = useState<string>('');
  const [customerNr, setCustomerNr] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [wasSent, setWasSent] = useState<boolean>(null);

  const sendRegisterApplication = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/organization/register`, {
      data: {
        customerNr,
        email
      }
    });
    if (data) {
      setWasSent(true);
    }
    if (error) {
      setWasSent(false);
    }
    setLoading(false);
    //close(e);
  };

  useEffect(() => {
    return () => {
      setWasSent(null);
    };
  }, []);

  return (
    <Portal>
      {/* Background */}
      <ASimple key="PasswordDialog-Background" className="PasswordDialog-Background" onClick={toggleDialog} style={{ height: window.innerHeight }} />
      {/* Content */}
      <ADialog key="PasswordDialog-Content" className="PasswordDialog-Content" topExit="0" topEnter="22%">
        <>
          <Heading level="2" color="dark">
            {Localizations['register'][lang]}
          </Heading>
          <p style={{ height: '60px' }}>{wasSent ? Localizations['registerSent'][lang] : wasSent === false ? Localizations['registerError'][lang] : ''}</p>
          <form
            action=""
            className="LoginPage-ResetForm"
            onSubmit={e => {
              e.preventDefault();
              if (!loading) {
                sendRegisterApplication(e);
              }
            }}
          >
            <Input
              className="LoginPage-InputField"
              onChange={event => {
                const target = event.target as HTMLInputElement;
                setCustomerNr(target.value);
              }}
              placeholder={Localizations['customerNumber'][lang]}
              value={customerNr}
              margin="0 0 0.5rem"
            />
            <Input
              className="LoginPage-InputField"
              onChange={event => {
                const target = event.target as HTMLInputElement;
                setEmail(target.value);
              }}
              placeholder={'E-Mail'}
              type="email"
              value={email}
              margin="0 0 0.5rem"
            />
            <Button btnType="third" margin="3rem 0 0 0" type="submit" fontSize=".65rem" padding=".75rem 0 .75rem 0" width={'100%'} maxWidth={350}>
              {loading ? <LoadingSpinner size="20px" /> : Localizations['continue'][lang]}
            </Button>
            <Button
              btnType="second"
              type="button"
              fontSize=".65rem"
              padding=".75rem 0 .75rem 0"
              margin="1rem 0 0 0"
              onClick={toggleDialog}
              width={'100%'}
              maxWidth={350}
            >
              {Localizations['abort'][lang]}
            </Button>
          </form>
        </>
      </ADialog>
    </Portal>
  );
};
