import React, { useContext } from 'react';
import { LangContext } from 'context';
import Localizations from 'languages';

// Style:
import './style.scss';
import { TextWithHeader } from 'components';

export const AGB = () => {
  const { lang } = useContext(LangContext);

  return (
    <div className="container">
      <div className="content">
        <h1>{Localizations['agbHeader'][lang]}</h1>
        <TextWithHeader header={'firstHeader'} text={'firstContent'} />
        <TextWithHeader header={'secondHeader'} text={'secondContent'} />
        <TextWithHeader header={'thirdHeader'} text={'thirdContent'} />
        <TextWithHeader header={'fourthHeader'} text={'fourthContent'} />
        <TextWithHeader header={'fifthHeader'} text={'fifthContent'} />
        <TextWithHeader header={'sixthHeader'} text={'sixthContent'} />
        <TextWithHeader header={'seventhHeader'} text={'seventhContent'} />
        <TextWithHeader header={'eighthHeader'} text={'eighthContent'} />
        <TextWithHeader header={'ninthHeader'} text={'ninthContent'} />
        <TextWithHeader header={'tenthHeader'} text={'tenthContent'} />
        <TextWithHeader header={'eleventhHeader'} text={'eleventhContent'} />
        <TextWithHeader header={'twelvethHeader'} text={'twelvethContent'} />
        <p>
          MKN
          <br />
          MASCHINENFABRIK KURT NEUBAUER GmbH & Co. KG
          <br />
          D-38300 Wolfenbüttel
        </p>
      </div>
    </div>
  );
};
