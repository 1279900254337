import React from 'react';

// Assets:
import logo from '../../assets/images/logo@2x.png';

// ===================================================================
interface Props {
  size?: number;
}

// ===================================================================
export const Logo: React.FC<Props> = ({ size = 50 }) => (
  <img
    src={logo}
    alt="MKN Logo"
    style={{
      height: size,
      width: size
    }}
  />
);
