import * as BABYLON from 'babylonjs';

export default class Vector3 {
  x: number = 0;
  y: number = 0;
  z: number = 0;

  constructor(x?: number, y?: number, z?: number) {
    this.x = x || 0;
    this.y = y || 0;
    this.z = z || 0;
  }

  set(v: BABYLON.Vector3) {
    this.x = v.x;
    this.y = v.y;
    this.z = v.z;
  }

  get(): BABYLON.Vector3 {
    return new BABYLON.Vector3(this.x, this.y, this.z);
  }
}
