import React, { useContext, FC, useState, useEffect } from 'react';

// Assets
import logo from '../../assets/images/logo@2x.png';

// Components
import { Button } from 'atoms';

// Context
import { EditorContext, LangContext } from 'context';

// Languages
import Localization from 'languages';

// Styles
import './style.scss';
import { colors } from 'styles/theme';

// Types
import { Device } from 'types/Device';

// Utils
import { addDevice, addFreeDevice, get } from 'utils';

interface Props {
  amount?: number;
  deselectCategory: () => void;
  device: Device;
  selectDevice?: (d: Device) => void;
}

export const DeviceTileFull: FC<Props> = ({ amount = null, device, deselectCategory }) => {
  const urlCreator = window.URL || window.webkitURL;

  const {
    addImageToStorage,
    configuration,
    deviceImageStorage,
    menuView,
    scene,
    selectedMasterline,
    selectedModular,
    seriesFilter,
    setCanAdvanceMasterline,
    setCanAdvanceModular,
    setCoverEnlargementSize,
    setDialog,
    setErrorAlert
  } = useContext(EditorContext);
  const { lang, langId } = useContext(LangContext);

  const { electroConditions, elektro, gas, steam, voltage } = device.energy;
  const { width, height, depths } = device.model;
  // TODO: Undo Englisch display in czech, when translations are updated
  const { name, description } = device.translations[langId];
  // const [showDetails, setShowDetails] = useState(false);
  const freeDevice = device.model.spaceCombi || device.model.flexiChef;
  const block = menuView === 'modular' ? selectedModular : menuView === 'masterline' ? selectedMasterline : null;

  const [itemAddable, setItemAddable] = useState(
    menuView === 'modular' && selectedModular
      ? selectedModular.canItemBeDropped(device, selectedModular.getRowTop()) || selectedModular.canItemBeDropped(device, selectedModular.getRowBottom())
      : freeDevice
      ? freeDevice
      : block
      ? block.canItemBeDropped(device, block.getRowTop()) || block.canItemBeDropped(device, block.getRowBottom())
      : false
  );

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (menuView === 'modular') {
      setItemAddable(
        selectedModular &&
          (selectedModular.canItemBeDropped(device, selectedModular.getRowTop()) || selectedModular.canItemBeDropped(device, selectedModular.getRowBottom()))
      );
    } else if (!freeDevice && block && device) {
      setItemAddable(block.canItemBeDropped(device, block.getRowTop()) || block.canItemBeDropped(device, block.getRowBottom()));
    }
  }, [block, menuView, selectedModular, device]);

  useEffect(() => {
    const getImage = async () => {
      const imgUrl = `${process.env.REACT_APP_API_URL}/device/get/${device.id}/model/image`;
      const { data, error } = await get(imgUrl, { responseType: 'blob' });
      if (data) {
        setImage(urlCreator.createObjectURL(data));
        addImageToStorage({ id: device.id, blob: data });
      }
      if (error) {
        console.log(error);
      }
    };

    // Check if image is in Context, else fetch from DB
    if (deviceImageStorage && deviceImageStorage[device.id]) {
      setImage(urlCreator.createObjectURL(deviceImageStorage[device.id]));
    } else {
      getImage();
    }
  }, []);

  return (
    <div className="Device-Tile-Full">
      {/* Header */}
      <div className="Device-Tile-Full-Header">
        {image ? <img src={image} alt="" /> : <img src={logo} alt="" />}
        <div>
          <div className="Device-Tile-Full-Header-Title">{device && device.model && device.model.flexiChef ? device.code : name}</div>
          <div className="Device-Tile-Full-Header-Summary">{description ? description : ''}</div>
          <div className="Device-Tile-Full-Header-Numbers">
            {depths.map((d, index) => (
              <div key={`${device.id} depth ${index}`}>{`${width} x ${d} x ${height}`}</div>
            ))}
          </div>
          <div className="Device-Tile-Full-Header-Numbers">
            {/* TODO: Add Units for Gas and Steam */}
            <span>{`${elektro > 0 ? elektro + ' kW' : ''} ${electroConditions} ${gas > 0 ? gas + ' kW' : ''} ${steam > 0 ? steam + ' kW' : ''} ${
              voltage > 0 ? voltage + ' V' : ''
            }`}</span>
          </div>
        </div>
      </div>
      {/* Description */}
      {/*  <div className="Device-Tile-Full-Details">
        <div className={`Device-Tile-Full-Details-Content ${showDetails ? 'expanded' : ''}`}>
          <div className="Device-Tile-Full-Details-Content-Collapsable">
            <div className="Device-Tile-Full-Details-Content-Collapsable-Description">{description}</div>
            <Accordion classes="Device-Tile-Full-Details-Content-Collapsable-Accordion" title={Localization['attachments'][lang]}>
              <div className="Device-Tile-Full-Details-Content-Collapsable-Panel">Noch mehr Lorem.</div>
            </Accordion>
            <Accordion classes="Device-Tile-Full-Details-Content-Collapsable-Accordion" title={Localization['specialEquipment'][lang]}>
              <div className="Device-Tile-Full-Details-Content-Collapsable-Panel">Noch mehr Lorem.</div>
            </Accordion>
          </div>
        </div>
      </div> */}
      <div className="Device-Tile-Full-Details-Controls">
        {/* <button className="Device-Tile-Full-Details-Controls-ExpandBtn" onClick={() => setShowDetails(!showDetails)}>
          <span>Details</span>
          <Icon color="medium" margin="0 0 .25rem .5rem" size="15px" type={showDetails ? 'arrowUp' : 'arrowDown'} />
        </button> */}
        <Button
          btnType="third"
          disabled={!itemAddable}
          fontSize=".65rem"
          onClick={event => {
            event.stopPropagation();
            if (device.model.modular && menuView === 'modular') {
              addDevice(scene, device, setDialog, setErrorAlert, setCoverEnlargementSize, seriesFilter, selectedModular);
              setCanAdvanceModular(true);
            }
            // TODO: ADD FLEXI/SPACE
            else {
              if (device.model && (device.model.flexiChef || device.model.spaceCombi)) {
                addFreeDevice(scene, device, configuration);
              } else {
                addDevice(scene, device, setDialog, setErrorAlert, setCoverEnlargementSize, seriesFilter, block);
                //selectDevice(device);
                if (menuView === 'masterline') {
                  setCanAdvanceMasterline(true);
                }
              }
            }
          }}
          padding=".5rem"
          width="140px"
        >
          {Localization['add'][lang]}
        </Button>
        {amount !== null && device && device.model && device.model.masterline && (
          <div
            style={{
              background: 'transparent',
              border: `1px solid ${colors.grey}`,
              display: 'flex',
              alignItems: 'center',
              marginLeft: '.5rem',
              minWidth: '28px',
              justifyContent: 'center',
              color: amount > 0 ? colors.medium : colors.greyText
            }}
          >
            {amount}
          </div>
        )}
      </div>
    </div>
  );
};
