import * as BABYLON from 'babylonjs';
import * as React from 'react';

export type SceneEventArgs = {
  engine: BABYLON.Engine;
  scene: BABYLON.Scene;
  canvas: HTMLCanvasElement;
};

export type SceneProps = {
  engineOptions?: BABYLON.EngineOptions;
  sceneOptions?: BABYLON.SceneOptions;
  adaptToDeviceRatio?: boolean;
  onSceneMount?: (args: SceneEventArgs) => SceneMountReturn;
};

export type SceneMountReturn = {
  resizeEvents?: Array<() => void>;
};

export default class BabylonScene extends React.Component<SceneProps & React.HTMLAttributes<HTMLCanvasElement>, {}> {
  private scene: BABYLON.Scene;
  private engine: BABYLON.Engine;
  private canvas: HTMLCanvasElement;

  private sceneMountReturn: SceneMountReturn;

  onResizeWindow = () => {
    if (this.engine) {
      this.engine.resize();
      this.resize();
    }
  };

  componentDidMount() {
    // Produce new shit
    this.engine = new BABYLON.Engine(this.canvas, this.props.engineOptions.antialias, this.props.engineOptions, this.props.adaptToDeviceRatio);

    this.scene = new BABYLON.Scene(this.engine, this.props.sceneOptions);

    if (typeof this.props.onSceneMount === 'function') {
      this.sceneMountReturn = this.props.onSceneMount({
        scene: this.scene,
        engine: this.engine,
        canvas: this.canvas
      });
    } else {
      console.error('onSceneMount function not available');
    }

    // Resize the babylon engine when the window is resized
    window.addEventListener('resize', this.onResizeWindow);

    // Clean up old shit
    window['_active_scene'] = this.scene;
    window['_active_engine'] = this.engine;
    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  onBeforeUnload(_event: BeforeUnloadEvent) {
    window.removeEventListener('resize', this.onResizeWindow);
    const scene = window['_active_scene'];
    const engine = window['_active_engine'];
    if (scene && scene instanceof BABYLON.Scene) scene.dispose();
    if (engine && engine instanceof BABYLON.Engine) engine.dispose();
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow);
    this.scene.dispose();
    this.engine.dispose();
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }
  onCanvasLoaded = (c: HTMLCanvasElement) => {
    if (c !== null) {
      this.canvas = c;
      this.resize();
    }
  };

  resize = () => {
    this.canvas.width = this.canvas.parentElement.offsetWidth;
    this.canvas.height = this.canvas.parentElement.offsetHeight;
    if (this.sceneMountReturn && this.sceneMountReturn.resizeEvents) {
      for (let i = 0; i < this.sceneMountReturn.resizeEvents.length; i++) {
        this.sceneMountReturn.resizeEvents[i]();
      }
    }
  };

  render() {
    return (
      <canvas
        // {...this.props}
        ref={this.onCanvasLoaded}
        width={'100%'}
        height={'100%'}
      />
    );
  }
}
