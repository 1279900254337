import React, { FC, useContext, useRef, useState, useEffect } from 'react';

// Components
import { ContextMenu, EquipmentSwitch } from 'page/Editor/Menu/Shared';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Types:
import { Device } from 'types/Device';

// Utils:
import { post } from 'utils/AJAX';
import { checkMarginInput } from 'utils';

type BordSettings = {
  marginLeft: number;
  marginRight: number;
  marginMixingFaucetLeft: number;
  marginMixingFaucetRight: number;
  mixingFaucetLeft: Device[];
  mixingFaucetRight: Device[];
  salamanderLeft: boolean;
  salamanderRight: boolean;
};

// ===================================================================
interface Props {}

// ===================================================================
export const SelectedBoard: FC<Props> = () => {
  const { scene, selectedMasterline, setMasterlineView, setErrorAlert, setSelection } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const didMount = useRef(false);
  const [initFinished, setInitFinished] = useState(false);
  const [isFreeBoard, setIsFreeBoard] = useState(false);
  const [isMediaBoard, setIsMediaBoard] = useState(false);
  const [mixingFaucetDevice, setMixingFaucetDevice] = useState<Device>(null);
  const [shelfMixingFaucetDevice, setShelfMixingFaucetDevice] = useState<Device>(null);
  const [settings, setSettings] = useState<BordSettings>({
    marginLeft: 0,
    marginRight: 0,
    marginMixingFaucetLeft: 0,
    marginMixingFaucetRight: 0,
    salamanderLeft: false,
    salamanderRight: false,
    mixingFaucetLeft: [],
    mixingFaucetRight: []
  });
  const [parent, setParent] = useState(null);

  useEffect(() => {
    if (initFinished && (settings.salamanderLeft || settings.salamanderRight)) {
      setErrorAlert(['salamander']);
    }
    if (didMount.current) {
      setInitFinished(true);
    }
  }, [settings.salamanderLeft, settings.salamanderRight]);

  useEffect(() => {
    if (!didMount.current) {
      if (selectedMasterline) {
        const board = selectedMasterline.getBoard();
        if (board) {
          if (board.getDeviceObject()?.style === 'Tray') {
            setIsMediaBoard(true);
          } else {
            setIsMediaBoard(false);
          }
          if (!board.getDeviceObject()) {
            setIsFreeBoard(true);
          } else {
            setIsFreeBoard(false);
          }
          const getLeft = board.getLeft();
          const getRight = board.getRight();
          setSettings({
            ...settings,
            mixingFaucetLeft: Array.isArray(getLeft) && getLeft.length > 0 ? [board.getLeft()[0]] : [],
            mixingFaucetRight: Array.isArray(getRight) && getRight.length > 0 ? [board.getRight()[0]] : [],
            marginLeft: board.getMarginLeft(),
            marginRight: board.getMarginRight(),
            marginMixingFaucetLeft: Array.isArray(getLeft) && getLeft.length > 0 ? board.getLeft()[0].margin : 0,
            marginMixingFaucetRight: Array.isArray(getRight) && getRight.length > 0 ? board.getRight()[0].margin : 0,
            salamanderLeft: board.isSalamanderLeft(),
            salamanderRight: board.isSalamanderRight()
          });
        }
        didMount.current = true;
      }
    } else {
      if (parent && parent.id === selectedMasterline.getId()) {
        const board = parent.getBoard();
        if (board) {
          if (board.getMarginLeft() !== settings.marginLeft) {
            board.setMarginLeft(settings.marginLeft);
          }
          if (board.getMarginRight() !== settings.marginRight) {
            board.setMarginRight(settings.marginRight);
          }
          if (board.isSalamanderLeft() !== settings.salamanderLeft) {
            board.setSalamanderLeft(settings.salamanderLeft);
            if (settings.salamanderLeft) {
              board.setSalamanderRight(false);
            }
          }
          if (board.isSalamanderRight() !== settings.salamanderRight) {
            board.setSalamanderRight(settings.salamanderRight);
            if (settings.salamanderRight) {
              board.setSalamanderLeft(false);
            }
          }

          const usedMixingFaucet = isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice;
          const leftDevice = board.getLeft(usedMixingFaucet)?._device;

          if ((!leftDevice && settings.mixingFaucetLeft.length > 0) || (leftDevice && settings.mixingFaucetLeft.length < 1)) {
            if (settings.mixingFaucetLeft.length > 0) {
              board.addLeft(usedMixingFaucet);
            } else {
              if (board.getLeft().length > 0) {
                board.removeLeft(usedMixingFaucet);
              }
            }
          }

          const rightDevice = board.getRight(usedMixingFaucet)?._device;

          if ((!rightDevice && settings.mixingFaucetRight.length > 0) || (rightDevice && settings.mixingFaucetRight.length < 1)) {
            if (settings.mixingFaucetRight.length > 0) {
              board.addRight(usedMixingFaucet);
            } else {
              if (board.getRight().length > 0) {
                board.removeRight(usedMixingFaucet);
              }
            }
          }

          if (board.getRight() && board.getRight()[0] && board.getRight()[0].margin !== settings.marginMixingFaucetRight) {
            board.getRight()[0].setMargin(settings.marginMixingFaucetRight);
            board.rerender();
          }

          if (board.getLeft() && board.getLeft()[0] && board.getLeft()[0].margin !== settings.marginMixingFaucetLeft) {
            board.getLeft()[0].setMargin(settings.marginMixingFaucetLeft);
            board.rerender();
          }
        }
      } else {
        setInitFinished(false);
        const board = selectedMasterline.getBoard();
        if (board) {
          if (board.getDeviceObject()?.style === 'Tray') {
            setIsMediaBoard(true);
          } else {
            setIsMediaBoard(false);
          }
          if (!board.getDeviceObject()) {
            setIsFreeBoard(true);
          } else {
            setIsFreeBoard(false);
          }
          const getLeft = board.getLeft();
          const getRight = board.getRight();
          setSettings({
            ...settings,
            mixingFaucetLeft: Array.isArray(getLeft) && getLeft.length > 0 ? [board.getLeft()[0]] : [],
            mixingFaucetRight: Array.isArray(getRight) && getRight.length > 0 ? [board.getRight()[0]] : [],
            marginLeft: board.getMarginLeft(),
            marginRight: board.getMarginRight(),
            marginMixingFaucetLeft: Array.isArray(getLeft) && getLeft.length > 0 ? board.getLeft()[0].margin : 0,
            marginMixingFaucetRight: Array.isArray(getRight) && getRight.length > 0 ? board.getRight()[0].margin : 0,
            salamanderLeft: board.isSalamanderLeft(),
            salamanderRight: board.isSalamanderRight()
          });
        }
        setParent(selectedMasterline);
      }
    }
  }, [parent, selectedMasterline, settings]);

  useEffect(() => {
    // Mixing Faucet
    const mixingFaucetKey = `slctdbrd-mxng-fct`;
    const getMixingFaucet = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            subtype: 'MixingFaucet'
          }
        }
      });
      if (Array.isArray(data)) {
        setMixingFaucetDevice(data[0]);
        sessionStorage.setItem(mixingFaucetKey, JSON.stringify(data[0]));
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedMixingFaucet = sessionStorage.getItem(mixingFaucetKey);
    if (cachedMixingFaucet) {
      setMixingFaucetDevice(JSON.parse(cachedMixingFaucet));
    } else {
      getMixingFaucet();
    }

    // Shelf Mixing Faucet
    const shelfMixingFaucetKey = `slctdbrd-shlf-mxng-fct`;
    const getShelfMixingFaucet = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            subtype: 'ShelfMixingFaucet'
          }
        }
      });
      if (Array.isArray(data)) {
        setShelfMixingFaucetDevice(data[0]);
        sessionStorage.setItem(shelfMixingFaucetKey, JSON.stringify(data[0]));
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedShelfMixingFaucet = sessionStorage.getItem(shelfMixingFaucetKey);
    if (cachedShelfMixingFaucet) {
      setShelfMixingFaucetDevice(JSON.parse(cachedShelfMixingFaucet));
    } else {
      getShelfMixingFaucet();
    }
  }, []);

  return (
    <div>
      <ContextMenu
        mode="back"
        onClick={() => {
          scene.setSelected(null);
          setMasterlineView('global');
          setSelection(null);
        }}
      />
      <div className="bold mb-3">{Localizations['shelf'][lang]}</div>
      <div className="Submenu-Attribute">
        <label className="Submenu-Label">
          <input
            className="Submenu-Input"
            onBlur={e => {
              const board = selectedMasterline.getBoard();
              const marginRight = board.getMarginRight();
              setSettings({ ...settings, marginRight });
              checkMarginInput(parseInt(e.target.value), marginRight, setErrorAlert);
            }}
            onChange={event => {
              if (selectedMasterline.getBoard()) {
                const shouldUpdateState = selectedMasterline.getBoard().setMarginRight(parseInt(event.target.value));
                if (shouldUpdateState) {
                  setSettings({ ...settings, marginRight: parseInt(event.target.value) || 0, marginLeft: selectedMasterline.getBoard().getMarginLeft() });
                }
              }
            }}
            min={0}
            step={100}
            type="number"
            value={settings.marginRight}
          />
          {`${Localizations['edgeDistance'][lang]} ${Localizations['right'][lang]} (mm)`}
        </label>
      </div>
      <div className="Submenu-Attribute">
        <label className="Submenu-Label">
          <input
            className="Submenu-Input"
            onBlur={e => {
              const board = selectedMasterline.getBoard();
              const marginLeft = board.getMarginLeft();
              setSettings({ ...settings, marginLeft });
              checkMarginInput(parseInt(e.target.value), marginLeft, setErrorAlert);
            }}
            onChange={event => {
              if (selectedMasterline.getBoard()) {
                const shouldUpdateState = selectedMasterline.getBoard().setMarginLeft(parseInt(event.target.value));
                if (shouldUpdateState) {
                  setSettings({ ...settings, marginLeft: parseInt(event.target.value) || 0, marginRight: selectedMasterline.getBoard().getMarginRight() });
                }
              }
            }}
            min={0}
            step={100}
            type="number"
            value={settings.marginLeft}
          />
          {`${Localizations['edgeDistance'][lang]} ${Localizations['left'][lang]} (mm)`}
        </label>
      </div>

      <div>
        <EquipmentSwitch
          label={`Salamander ${Localizations['right'][lang]}`}
          status={settings.salamanderRight}
          toggleStatus={() => {
            if (settings.salamanderLeft) {
              setSettings({ ...settings, salamanderRight: !settings.salamanderRight, salamanderLeft: false });
            } else {
              setSettings({ ...settings, salamanderRight: !settings.salamanderRight });
            }
          }}
          type="first"
        />
      </div>
      <div>
        <EquipmentSwitch
          label={`Salamander ${Localizations['left'][lang]}`}
          status={settings.salamanderLeft}
          toggleStatus={() => {
            if (settings.salamanderRight) {
              setSettings({ ...settings, salamanderLeft: !settings.salamanderLeft, salamanderRight: false });
            } else {
              setSettings({ ...settings, salamanderLeft: !settings.salamanderLeft });
            }
          }}
          type="first"
        />
      </div>
      {(isMediaBoard || isFreeBoard) && (
        <>
          <div>
            <EquipmentSwitch
              label={`${Localizations['mixingFaucetMedia'][lang]} ${Localizations['right'][lang]}`}
              status={settings.mixingFaucetRight.length > 0}
              toggleStatus={() => {
                setSettings({
                  ...settings,
                  mixingFaucetRight: settings.mixingFaucetRight.length > 0 ? [] : [isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice]
                });
              }}
              type="first"
            />
          </div>
          <div className="Submenu-Attribute mt-2">
            <label className={`Submenu-Label ${!settings.mixingFaucetRight || settings.mixingFaucetRight.length < 1 ? 'disabled' : ''}`}>
              <input
                className="Submenu-Input"
                disabled={!settings.mixingFaucetRight || settings.mixingFaucetRight.length < 1}
                onBlur={e => {
                  const board = selectedMasterline.getBoard();
                  const rightDevice = board.getRight();
                  if (Array.isArray(rightDevice) && rightDevice[0]) {
                    const marginMixingFaucetRight = rightDevice[0].getMargin();
                    setSettings({ ...settings, marginMixingFaucetRight });
                    checkMarginInput(parseInt(e.target.value), rightDevice[0].getMargin(), setErrorAlert);
                  }
                }}
                onChange={event => {
                  setSettings({ ...settings, marginMixingFaucetRight: parseInt(event.target.value) || 0 });
                }}
                min={0}
                step={100}
                type="number"
                value={settings.marginMixingFaucetRight}
              />
              {`${Localizations['edgeDistance'][lang]} ${Localizations['faucet'][lang]} R. (mm)`}
            </label>
          </div>

          <div>
            <EquipmentSwitch
              label={`${Localizations['mixingFaucetMedia'][lang]} ${Localizations['left'][lang]}`}
              status={settings.mixingFaucetLeft.length > 0}
              toggleStatus={() => {
                setSettings({
                  ...settings,
                  mixingFaucetLeft: settings.mixingFaucetLeft.length > 0 ? [] : [isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice]
                });
              }}
              type="first"
            />
          </div>
          <div className="Submenu-Attribute mt-2">
            <label className={`Submenu-Label ${!settings.mixingFaucetLeft || settings.mixingFaucetLeft.length < 1 ? 'disabled' : ''}`}>
              <input
                className={`Submenu-Input`}
                disabled={!settings.mixingFaucetLeft || settings.mixingFaucetLeft.length < 1}
                onBlur={e => {
                  const board = selectedMasterline.getBoard();
                  const leftDevice = board.getLeft();
                  if (Array.isArray(leftDevice) && leftDevice[0]) {
                    const marginMixingFaucetLeft = leftDevice[0].getMargin();
                    setSettings({ ...settings, marginMixingFaucetLeft });
                    checkMarginInput(parseInt(e.target.value), leftDevice[0].getMargin(), setErrorAlert);
                  }
                }}
                onChange={event => {
                  setSettings({ ...settings, marginMixingFaucetLeft: parseInt(event.target.value) || 0 });
                }}
                min={0}
                step={100}
                type="number"
                value={settings.marginMixingFaucetLeft}
              />
              {`${Localizations['edgeDistance'][lang]} ${Localizations['faucet'][lang]} L. (mm)`}
            </label>
          </div>
        </>
      )}
    </div>
  );
};
