import { post } from './AJAX'

export const getLanguages = async () => {
  const { data, error } = await post(`${process.env.REACT_APP_API_URL}/language/search`, {
    data: {
      rows: 999,
      offset: 0,
      query: '',
      sort: {
        field: 'name',
        order: 'ASC'
      }
    }
  })
  return { data, error }
}
