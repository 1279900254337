import React, { FC, useContext, useState, useEffect } from 'react';

// Components
import { Button, Icon, Selectbox } from 'atoms';
import { BlockSelector } from 'components';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import Vector3 from 'page/Editor/configuration/Vector3';
import Block from 'page/Editor/configuration/Block';

// Context
import { EditorContext, LangContext } from 'context';

// Hooks
import { useMasterlineReducer, useInitStructure } from './hooks';

// Language
import Localizations from 'languages';

// Utils
import { hasDimSum, useHasFlexichefs } from 'utils';

// Consts:
const SINGLE_SERIES = ['700', '850'];
const SINGLE_SERIES_FREE = ['740', '890'];
const DOUBLE_SERIES = ['700 / 700', '700 / 850', '850 / 700', '850 / 850'];
const COVER_ENLARGEMENT_OPTIONS_WALL = [0, 50, 100];
const COVER_ENLARGEMENT_OPTIONS_DOUBLE = [0, 100, 200, 300];

// ===================================================================
interface Props {}

type Series = '700' | '850' | '740' | '890' | '700 / 700' | '700 / 850' | '850 / 700' | '850 / 850';

export interface Action {
  type: 'rowChange' | 'depthChange' | 'addBlock' | 'coverEnlargementChange' | 'changeSelectedBlock';
  index?: number;
  payload?: boolean | Series | string | Block;
}

export type MasterlineTypes = {
  singleFree?: string;
  singleWall: string;
  double: string;
};

// ===================================================================
export const MasterlineStructure: FC<Props> = () => {
  const { lang } = useContext(LangContext);
  const { scene, selectedMasterline, setErrorAlert, setSelectedMasterline, setSelection, setMasterlineView, setSeriesFilter } = useContext(EditorContext);
  const { hasFlexichefs } = useHasFlexichefs();
  const ROWS: MasterlineTypes = hasFlexichefs
    ? { singleWall: `${Localizations['singleRow'][lang]} ${Localizations['wallStanding'][lang]}`, double: Localizations['doubleRow'][lang] }
    : {
        singleWall: `${Localizations['singleRow'][lang]} ${Localizations['wallStanding'][lang]}`,
        singleFree: `${Localizations['singleRow'][lang]} ${Localizations['freeStanding'][lang]}`,
        double: Localizations['doubleRow'][lang]
      };

  const [coverEnlargement, setCoverEnlargement] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<string>('');
  const [selectedSeries, setSelectedSeries] = useState<string>('');
  const [masterlineAdded, setMasterlineAdded] = useState(selectedMasterline !== null);
  const [initialized, setInitialized] = useState(false);
  const { masterlineBlock, dispatch, addMasterline } = useMasterlineReducer(ROWS, setSelectedRow, setSelectedSeries);

  useEffect(() => {
    if (scene) {
      scene.setBlockMode(true);
    }
    return () => {
      if (scene) {
        scene.setBlockMode(false);
      }
      setInitialized(false);
    };
  }, []);

  useInitStructure(selectedMasterline, initialized, lang, ROWS, setSelectedRow, setInitialized, setSelectedSeries, setCoverEnlargement, dispatch);

  useEffect(() => {
    setInitialized(false);
    if (!masterlineAdded) {
      setMasterlineAdded(selectedMasterline !== null);
    }
  }, [selectedMasterline]);

  return (
    <div className="flex-col" style={{ maxHeight: '100%', overflow: 'hidden' }}>
      <ContextMenu
        mode="back"
        onClick={() => {
          setMasterlineView('home');
          setSelection(null);
          scene.setSelected(null);
        }}
      />
      {selectedMasterline && <BlockSelector />}
      <div className="Custom-Scroll mt-2" style={{ flex: 1, overflowY: 'auto' }}>
        <div key={`config-masterline-`} style={{ marginBottom: '.75rem' }}>
          {masterlineBlock && (
            <div className="mb-1">
              {/* ROWS ----> SINGLE WALL / SINGLE FREE / DOUBLE */}
              <Selectbox
                label={Localizations['blockStructure'][lang]}
                options={Object.values(ROWS)}
                state={selectedRow}
                setState={(value: string) => {
                  if (value === ROWS.singleFree && hasDimSum(masterlineBlock, true)) {
                    setErrorAlert(['dimSum']);
                  } else {
                    hasDimSum(masterlineBlock, false);
                  }

                  setSelectedRow(value);
                  dispatch({ type: 'rowChange', payload: value });

                  if (value === ROWS.singleFree) {
                    setSelectedSeries(['700', '700 / 700', '850 / 700'].includes(value) ? '740' : '890');
                  } else if (value === ROWS.singleWall) {
                    setSelectedSeries(value === '740' ? '700' : '850');
                  } else if (value === ROWS.double) {
                    setSelectedSeries(['700', '740'].includes(value) ? '700 / 700' : '700 / 850');
                    setSeriesFilter('all');
                  }
                }}
              />
            </div>
          )}

          {/* SINGLE DEPTH OPTIONS */}
          {masterlineBlock && (selectedRow === ROWS.singleWall || selectedRow === ROWS.singleFree) && (
            <div className="mb-1">
              <Selectbox
                label={Localizations['rowDepth'][lang]}
                state={selectedSeries}
                setState={value => {
                  setSelectedSeries(value);
                  setSeriesFilter(
                    value === '700' || value === '740' || value === '700 / 700'
                      ? '700'
                      : value === '850 / 850' || value === '850' || value === '890'
                      ? '850'
                      : 'all'
                  );
                  dispatch({ type: 'depthChange', payload: value });
                }}
                options={selectedRow === ROWS.singleFree ? SINGLE_SERIES_FREE : SINGLE_SERIES}
              />
            </div>
          )}
          {/* DOUBLE DEPTH OPTIONS */}
          {masterlineBlock && selectedRow === ROWS.double && (
            <div className="mb-1">
              <Selectbox
                label={Localizations['rowDepth'][lang]}
                state={selectedSeries}
                setState={value => {
                  setSelectedSeries(value);
                  dispatch({ type: 'depthChange', payload: value });
                }}
                options={DOUBLE_SERIES}
              />
            </div>
          )}
          {/* COVER ENLARGEMENT OPTIONS */}
          {masterlineBlock && (selectedRow === ROWS.singleWall || selectedRow === ROWS.double) && (
            <div className="mb-1">
              <Selectbox
                label={`${Localizations['coverEnlargement'][lang]} (mm)`}
                state={coverEnlargement}
                setState={value => {
                  setCoverEnlargement(value);
                  dispatch({ type: 'coverEnlargementChange', payload: value });
                }}
                options={selectedRow === ROWS.singleWall ? COVER_ENLARGEMENT_OPTIONS_WALL : COVER_ENLARGEMENT_OPTIONS_DOUBLE}
              />
            </div>
          )}
          {masterlineBlock && (
            <div className="w-100 flex justify-end">
              <Icon
                type="rotate"
                size="1.5rem"
                style={{ margin: '0 1rem' }}
                onClick={() => {
                  masterlineBlock.setRotation(new Vector3(0, masterlineBlock.getRotation().y + Math.PI / 2, 0));
                }}
              />
              <Icon
                color="medium"
                onClick={() => {
                  setSelectedMasterline(masterlineBlock);
                  setErrorAlert(['delete']);
                }}
                size="1.5rem"
                type="trash"
                stroke={3}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-1">
        <Button btnType="third" fontSize=".65rem" onClick={addMasterline} padding=".5rem">
          {Localizations['addMasterline'][lang]}
        </Button>
      </div>
    </div>
  );
};
