import { Button, Icon, Selectbox } from 'atoms';
import { EditorContext, LangContext } from 'context';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import { useContext } from 'react';
import { useMarineMeisterStructure } from './useMarineMeisterStructure';

import { BlockSelector } from 'components';
import Localizations from 'languages';
import Vector3 from 'page/Editor/configuration/Vector3';
import { useBlockMode } from 'utils';

export const MarineMeisterStructure: React.FC = () => {
  const { lang } = useContext(LangContext);
  const { setMarineMeisterView, setSelection, scene, selectedMarineMeister, setErrorAlert } = useContext(EditorContext);
  const { handleAddMarineMeister, row, handleRowSelection, ROW_OPTIONS, voltage, handleVoltageSelection, VOLTAGE_OPTIONS } = useMarineMeisterStructure();

  useBlockMode(scene);

  return (
    <div>
      <ContextMenu
        mode="back"
        onClick={() => {
          setMarineMeisterView('home');
          setSelection(null);
          scene.setSelected(null);
        }}
      />
      <>
        <BlockSelector />

        {selectedMarineMeister && (
          <>
            <div className="Custom-Scroll mt-2" style={{ flex: 1, overflowY: 'auto' }}>
              <Selectbox label={Localizations['blockStructure'][lang]} options={ROW_OPTIONS} state={row} setState={handleRowSelection} />
              <div className="mt-2">
                <Selectbox label={`${Localizations['voltage'][lang]} (V)`} options={VOLTAGE_OPTIONS} state={voltage} setState={handleVoltageSelection} />
              </div>
            </div>
            <div className="w-100 flex justify-end mt-2">
              <Icon
                type="rotate"
                size="1.5rem"
                style={{ margin: '0 1rem' }}
                onClick={() => {
                  selectedMarineMeister.setRotation(new Vector3(0, selectedMarineMeister.getRotation().y + Math.PI / 2, 0));
                }}
              />
              <Icon
                color="medium"
                onClick={() => {
                  setErrorAlert(['delete']);
                }}
                size="1.5rem"
                type="trash"
                stroke={3}
              />
            </div>
          </>
        )}
      </>
      <div className="mt-2">
        <Button btnType="third" fontSize=".65rem" onClick={handleAddMarineMeister} padding=".5rem">
          {Localizations['addMarineMeister'][lang]}
        </Button>
      </div>
    </div>
  );
};
