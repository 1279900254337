import InnerRoomNode from 'components/babylon/node/helper/InnerRoomNode';
import WallClippingNode from 'components/babylon/node/helper/WallClippingNode';
import RoomNode from 'components/babylon/node/RoomNode';
import Vector2 from './Vector2';

export type WallDirection = 'Top' | 'Right' | 'Bottom' | 'Left';

export default class Room {
  public clazz = 'Room';

  private width: number;
  private depth: number;

  private children: Array<RoomChild>;

  private _node: RoomNode;
  private _showLabels: boolean = false;

  constructor() {
    this._node = new RoomNode(this);
    this.children = [];

    this.setWidth(6000);
    this.setDepth(6000);
  }

  /**
   * @returns width in mm
   */
  public getWidth() {
    return this.width;
  }

  /**
   * @param width width in mm
   */
  public setWidth(width: number) {
    this.width = width;

    this._node.setWidth(width / 10);
  }

  /**
   * @returns depth in mm
   */
  public getDepth() {
    return this.depth;
  }

  /**
   * @param depth depth in mm
   */
  public setDepth(depth: number) {
    this.depth = depth;

    this._node.setDepth(depth / 10);
  }

  public getChildren() {
    return this.children;
  }

  public removeChild(index: number) {
    if (index < this.children.length) {
      const item = this.children.splice(index, 1)[0];
      item.getNode().dispose();
      this._node.bake();
    }
  }

  public setActive(value: boolean) {
    this._node.getChildTransformNodes(false, node => {
      if (node instanceof WallClippingNode) node.setPickable(value);
      if (node instanceof InnerRoomNode) node.setPickable(value);
      return false;
    });
  }

  public setShowLabels(value: boolean) {
    this._showLabels = value;
    this._node.setShowLabels(value);
    this.children.forEach(child => child.getNode().setShowLabels(value));
  }

  public isShowLabels() {
    return this._showLabels;
  }

  /**
   * @returns RoomNode Object
   */
  public getNode() {
    return this._node;
  }
}

export class RoomChild {
  protected _room: Room;

  constructor(room: Room) {
    this._room = room;
    room.getChildren().push(this);
  }

  public getRoom() {
    return this._room;
  }

  public getNode(): WallClippingNode | InnerRoomNode {
    return null;
  }

  public delete() {
    if (this.getNode()) {
      for (let i = 0; i < this.getRoom().getChildren().length; i++) {
        const e = this.getRoom().getChildren()[i];
        if (e.getNode().uniqueId === this.getNode().uniqueId) {
          this.getRoom().removeChild(i);
          break;
        }
      }
    }
  }
}

export class WallClipping extends RoomChild {
  protected width: number;
  protected position: number;
  protected wall: WallDirection;

  constructor(room: Room) {
    super(room);
    this.position = 0;
  }

  /**
   * @returns width in mm
   */
  public getWidth() {
    return this.width;
  }

  /**
   * @param width width in mm
   */
  public setWidth(width: number) {
    this.width = width;

    this.getNode().updateWidth();
    this._room.getNode().bake();
  }

  /**
   * @returns Wall
   */
  public getWall() {
    return this.wall;
  }

  /**
   * @param wall 'Top' | 'Right' | 'Bottom' | 'Left'
   */
  public setWall(wall: WallDirection) {
    this.wall = wall;
    this.getNode().updateWall();
    // this._room.getNode().bake();
  }

  /**
   * @returns position of element relativ to wall
   */
  public getPosition() {
    return this.position;
  }

  /**
   * @param position position of element relativ to wall
   */
  public setPosition(position: number) {
    this.position = position;
    this.getNode().updatePosition();
  }

  public getNode(): WallClippingNode {
    return null;
  }

  public delete() {
    super.delete();
    this.getNode().dispose();
  }
}

export class InnerRoom extends RoomChild {
  protected width: number;
  protected depth: number;
  protected position: Vector2;
  protected rotation: number;

  constructor(room: Room) {
    super(room);
    this.position = new Vector2(0, 0);
    this.rotation = 0;
    this.width = 0;
    this.depth = 0;
  }

  /**
   * @returns width in mm
   */
  public getWidth() {
    return this.width;
  }

  /**
   * @param width width in mm
   */
  public setWidth(width: number) {
    this.width = width;

    this.getNode().updateWidth();
    this._room.getNode().bake();
  }

  /**
   * @returns depth in mm
   */
  public getDepth() {
    return this.depth;
  }

  /**
   * @param depth depth in mm
   */
  public setDepth(depth: number) {
    this.depth = depth;

    this.getNode().updateDepth();
    this._room.getNode().bake();
  }

  /**
   * @returns height in mm
   */
  public getHeight() {
    return RoomNode.WALL_HEIGHT * 10;
  }

  /**
   * in CM
   * @returns position of element
   */
  public getPosition() {
    return this.position;
  }

  /**
   * in CM
   * @param position position of element
   */
  public setPosition(position: Vector2) {
    this.position = position;
    this.getNode().updatePosition();
  }

  /**
   * @returns rotation of element
   */
  public getRotation() {
    return this.rotation;
  }

  /**
   * @param rotation rotation of element
   */
  public setRotation(rotation: number) {
    this.rotation = rotation;
    this.getNode().updatePosition();
  }

  public getNode(): InnerRoomNode {
    return null;
  }

  public delete() {
    super.delete();
    this.getNode().dispose();
  }
}
