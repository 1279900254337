import React, { FC } from 'react';
import { Property } from 'csstype';

// Styles:
import { colors } from 'styles/theme';

// ==============================================================
interface Props {
  color?: 'white' | 'dark' | 'grey' | 'medium' | 'greyText' | 'signal';
  fontSize?: string;
  fontWeight?: Property.FontWeight;
  margin?: string;
  padding?: string;
  textAlign?: Property.TextAlign;
  width?: string;
  underlined?: boolean;
  letterSpacing?: string;
  lineHeight?: string;
}

// ==============================================================
export const Paragraph: FC<Props> = ({
  children,
  color = 'medium',
  fontSize = '1rem',
  fontWeight = 'normal',
  margin = '0',
  padding = '',
  textAlign = 'start',
  width = 'auto',
  underlined = false,
  letterSpacing = 'normal',
  lineHeight = 1.5
}) => (
  <p
    style={{
      width: width,

      margin: margin,
      padding: padding,

      color: colors[color],
      fontSize: fontSize,
      fontWeight: fontWeight,
      textAlign: textAlign,
      lineHeight,
      letterSpacing,
      borderBottom: underlined ? 'solid 2px ' + colors[color] : 'none'
    }}
  >
    {children}
  </p>
);
