import React, { CSSProperties, useEffect, useState } from 'react';

import './style.scss';

interface Props {
  duration?: number;
  pose?: 'active' | 'inactive';
  style?: CSSProperties;
}

export const ASimpleWithHeight: React.FC<Props> = ({ children, duration, pose, style }) => {
  const [className, setClassname] = useState('ASimpleWithHeight');
  useEffect(() => {
    if (pose !== 'inactive') {
      setClassname('ASimpleWithHeight-Active');
    } else {
      setClassname('ASimpleWithHeight');
    }
  }, [pose]);
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};
