import { Device } from "types/Device";

export const findMatchingUnderframe = (data: Device[], device: Device): Device => {
  const deviceDepth = device.model.depths[0]

  let smallestWidthDifference = data[0].model.width - device.model.width;

  
  data.forEach(underframe => {
    if (Math.abs(underframe.model.width - device.model.width) <= Math.abs(smallestWidthDifference)) {
      smallestWidthDifference = Math.abs(underframe.model.width - device.model.width)
    }
  })

  const matchingWidthUnderframes = data.filter(underframe => {
    return Math.abs(underframe.model.width - device.model.width) === smallestWidthDifference
  })

  let usedUnderframe = matchingWidthUnderframes[0];
  let difference = matchingWidthUnderframes[0] ? matchingWidthUnderframes[0].model.depths[0] - deviceDepth : 0;


  // 
  matchingWidthUnderframes.forEach((u: Device) => {
    if (Math.abs(u.model.depths[0] - deviceDepth) < Math.abs(difference)) {
      usedUnderframe = u;
      difference = u.model.depths[0] - deviceDepth;
    }
  });

  return usedUnderframe
}