import React, { FC, CSSProperties, useContext } from 'react';

// Context:
import { LangContext } from 'context';

// Atoms:
import { Icon } from 'atoms';

// Partials:
import { TableSpacer } from './index';

// Lang
import Localizations from 'languages';
import { SortCriteria } from '../index';

// ===================================================================
interface Props {
  style: CSSProperties;
  widths: Array<string>;
  marginBottom?: string;
  setSortBy: (col: SortCriteria) => void;
  sortBy: SortCriteria;
  setDirection: (value: 'ASC' | 'DESC') => void;
  direction: string;
  archive?: boolean;
}

// ===================================================================
export const TableHeader: FC<Props> = ({ style, widths, marginBottom = '0.75rem', setSortBy, direction, setDirection, sortBy, archive = false }) => {
  const { lang } = useContext(LangContext);

  const padding = '0 0.25rem ' + marginBottom;
  const styleWithPad: CSSProperties = {
    ...style,
    fontSize: '0.65rem',
    padding: padding,
    fontWeight: 'bold',
    lineHeight: '9px',
    letterSpacing: '.7px'
  };

  const handleSelection = (column: SortCriteria) => {
    if (sortBy === column) {
      setDirection(direction === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortBy(column);
      setDirection('DESC');
    }
  };

  return (
    <thead>
      <tr>
        <th onClick={() => handleSelection('created')} style={{ ...styleWithPad, cursor: 'pointer' }}>
          {Localizations['date'][lang].toUpperCase()}
          {sortBy === 'created' && <Icon color="white" stroke={1} type={direction === 'ASC' ? 'arrowDown' : 'arrowUp'} size="1rem" margin="0 0 0 1rem" />}
        </th>
        <th /* onClick={() => handleSelection('id')} */ style={{ ...styleWithPad /* cursor: 'pointer' */ }}>
          CODE
          {sortBy === 'id' && <Icon color="white" stroke={1} type={direction === 'ASC' ? 'arrowDown' : 'arrowUp'} size="1rem" margin="0 0 0 1rem" />}
        </th>
        <th onClick={() => handleSelection('customer')} style={{ ...styleWithPad, cursor: 'pointer' }}>
          {Localizations['customer'][lang].toUpperCase()}
          {sortBy === 'customer' && <Icon color="white" stroke={1} type={direction === 'ASC' ? 'arrowDown' : 'arrowUp'} size="1rem" margin="0 0 0 1rem" />}
        </th>
        <th onClick={() => handleSelection('name')} style={{ ...styleWithPad, width: widths[archive ? 3 : 2], cursor: 'pointer' }}>
          {Localizations['object'][lang].toUpperCase()}
          {sortBy === 'name' && <Icon color="white" stroke={1} type={direction === 'ASC' ? 'arrowDown' : 'arrowUp'} size="1rem" margin="0 0 0 1rem" />}
        </th>
        <th onClick={() => handleSelection('location')} style={{ ...styleWithPad, width: widths[archive ? 3 : 2], cursor: 'pointer' }}>
          {Localizations['location'][lang].toUpperCase()}
          {sortBy === 'location' && <Icon color="white" stroke={1} type={direction === 'ASC' ? 'arrowDown' : 'arrowUp'} size="1rem" margin="0 0 0 1rem" />}
        </th>
        {!archive ? (
          <>
            {/* <th onClick={() => reorder('public')} style={{ ...styleWithPad, textAlign: 'center', cursor: 'pointer' }}>
              {Localizations['public'][lang].toUpperCase()}
              {sortBy === 'public' && <Icon color="white" stroke={1} type={direction === 'ASC' ? 'arrowDown' : 'arrowUp'} size="1rem" margin="0 0 0 1rem" />}
            </th> */}
            <th style={{ ...styleWithPad, width: widths[0], textAlign: 'center' }}>{Localizations['delete'][lang].toUpperCase()}</th>
          </>
        ) : (
          <th /* onClick={() => handleSelection('email')} */ style={{ ...styleWithPad, width: widths[4] /* cursor: 'pointer' */ }}>
            {Localizations['mail'][lang].toUpperCase()}
            {sortBy === 'email' && <Icon color="white" stroke={1} type={direction === 'ASC' ? 'arrowDown' : 'arrowUp'} size="1rem" margin="0 0 0 1rem" />}
          </th>
        )}
      </tr>
      <TableSpacer count={6} pose="enter" />
    </thead>
  );
};
