import Configuration from '../page/Editor/configuration/Configuration';
import Block from '../page/Editor/configuration/Block';

export const getMasterlinesAsArray = (config: Configuration): Block[] => {
  const masterlines = config.getMasterline()
  if (masterlines === null) {
    return []
  }
  if (Array.isArray(masterlines)) {
    return masterlines
  } else return [masterlines]
}