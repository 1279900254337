import { EditorContext } from 'context';
import { useContext, useEffect } from 'react';
import { initializeContainsMerges } from 'utils';

export const useUpdateContainsMerges = () => {
  const { selectedMasterline, selection, setContainsMerges } = useContext(EditorContext);
  useEffect(() => {
    if (selectedMasterline) {
      setContainsMerges(initializeContainsMerges(selectedMasterline));
    }
  }, [selection, selectedMasterline]);
};
