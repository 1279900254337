import BlockItem from 'page/Editor/configuration/BlockItem';
import { useEffect, useState } from 'react';
import { Device } from 'types';
import { getStyleAttribute } from '../../../../../../utils/getStyleAttribute';
import { Selected } from '../../../../Scene';
import { EquipmentHelper } from '../../../../configuration/Equipment';

export const useWidthSelection = (device: Device, block: Device | Selected) => {
  const [width, setWidth] = useState(block instanceof BlockItem ? block.getWidth() : 0);

  const { style } = device;
  const loweredStyle = style?.toLowerCase();
  const showWidthSelection =
    loweredStyle?.includes('loweredworktable') || loweredStyle?.includes('coverplateoverhang') || loweredStyle?.includes('showwidthselection');
  const widthMin = getStyleAttribute(style, 'widthMin');
  const widthMax = getStyleAttribute(style, 'widthMax');

  const numericWidthMin = Number(widthMin);
  const numericWidthMax = Number(widthMax);

  useEffect(() => {
    if (block instanceof BlockItem) {
      if (block.getWidth() !== width) {
        setWidthToBlockWidth();
      }
    }
  }, [block]);

  useEffect(() => {
    if (block instanceof BlockItem) {
      if (block.getWidth() !== width) {
        const usedWidth = width < numericWidthMin ? numericWidthMin : width > numericWidthMax ? numericWidthMax : width;
        block.setWidth(usedWidth);
        if (!EquipmentHelper.canHaveMixingFaucet(block)) {
          EquipmentHelper.setMixingFaucet(block, null);
        }
        if (width === usedWidth) setWidthToBlockWidth();
      }
    }
  }, [width]);

  const setWidthToBlockWidth = () => {
    if (block instanceof BlockItem) {
      setWidth(block.getWidth());
    }
  };

  return {
    showWidthSelection,
    width,
    validateWidth: setWidthToBlockWidth,
    setWidth,
    widthMax,
    widthMin
  };
};
