import React, { FC, useState } from 'react';
import { Icon } from 'atoms';

import './style.scss';

interface Props {
  classes?: string;
  defaultOpen?: boolean;
  title: string;
  reverse?: boolean;
  style?: React.CSSProperties;
  marginTitle?: string;
}

export const Accordion: FC<Props> = ({ children, classes = '', defaultOpen = false, title, marginTitle = '0', reverse = false, style }) => {
  const [showDetails, setShowDetails] = useState(defaultOpen);
  return (
    <div
      className={`Accordion${showDetails ? ' expanded' : ''} ${classes}`}
      style={{ display: 'flex', flexDirection: reverse ? 'column-reverse' : 'column', ...style }}
    >
      <div
        onClick={() => setShowDetails(!showDetails)}
        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', marginBottom: showDetails ? marginTitle : '0' }}
      >
        <span>{title}</span>
        <Icon color="greyText" onClick={() => {}} stroke={1} size="13px" type={showDetails ? (reverse ? 'arrowUp' : 'arrowLeft') : 'arrowDown'} />
      </div>
      <div className="Panel">{children}</div>
    </div>
  );
};
