import React, { useContext, useEffect, useState, FC } from 'react';
import { Portal } from 'react-portal';

// Atoms:
import { ASimple, ADialog, Button, Heading, Input } from 'atoms';

// Components:
import { LoadingSpinner } from 'components';

// Context:
import { LangContext } from 'context';

// Languages:
import Localizations from 'languages';

// Utils:
import { post } from 'utils';

interface Props {
  setShowPasswordDialog: (s: boolean) => void;
}

export const ConfirmPasswordDialog: FC<Props> = ({ setShowPasswordDialog }) => {
  const { lang } = useContext(LangContext);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>(null);
  const [reset, setReset] = useState<boolean>(null);

  const close = (e: any) => {
    e.preventDefault();

    setShowPasswordDialog(false);
  };

  const sendNewPassword = (e: any) => {
    e.preventDefault();
    changePassword();
  };

  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(password === repeatPassword);

  const checkPassword = (pw: string) => {
    return pw.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{9,}$/g);
  };

  useEffect(() => {
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('token')) {
      setToken(urlParams.get('token'));
    }
    return () => {
      setToken(null);
      setReset(null);
    };
  }, []);

  // Effects
  useEffect(() => {
    setPasswordsMatch(password === repeatPassword);
    if (password) {
      setPasswordValid(checkPassword(password) !== null);
    }
  }, [password, repeatPassword]);

  useEffect(() => {
    setPasswordValid(checkPassword(password) !== null);
  }, [password]);

  const changePassword = async () => {
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/member/password/change`, {
      data: {
        token,
        password,
        passwordRepeat: repeatPassword
      }
    });
    if (data) {
      setReset(true);
    } else if (error) {
      setReset(false);
    }
    setLoading(false);
  };

  return (
    <Portal>
      {/* Background */}
      <ASimple key="PasswordDialog-Background" className="PasswordDialog-Background" onClick={close} style={{ height: window.innerHeight }} />
      {/* Content */}
      <ADialog key="PasswordDialog-Content" className="PasswordDialog-Content" topExit="0" topEnter="27%">
        <>
          <Heading level="2" color="dark">
            {Localizations['createNewPassword'][lang]}
          </Heading>
          <form
            action=""
            className="LoginPage-ResetForm"
            onSubmit={e => {
              e.preventDefault();
              if (!loading) {
                sendNewPassword(e);
              }
            }}
          >
            {password && (!passwordValid || (repeatPassword && !passwordsMatch)) ? (
              <p className="PasswordHint">{Localizations[!passwordValid ? 'invalidPassword' : 'passwordsDontMatch'][lang]}</p>
            ) : (
              <div className="PasswordHint"></div>
            )}
            <Input
              className="LoginPage-InputField"
              //@ts-ignore
              onChange={event => setPassword(event.target.value)}
              placeholder={'Password'}
              type="password"
              value={password}
              margin=".5rem 0 0.5rem"
            />
            <Input
              className="LoginPage-InputField"
              //@ts-ignore
              onChange={event => setRepeatPassword(event.target.value)}
              placeholder={'Repeat password'}
              type="password"
              value={repeatPassword}
              margin="0 0 0.5rem"
            />
            <p style={{ height: '30px' }}>
              {reset ? Localizations['passwordChanged'][lang] : reset === false ? Localizations['passwordChangeError'][lang] : ''}
            </p>

            <Button btnType="third" margin="1rem 0 0 0" type="submit" fontSize=".65rem" padding=".75rem 0 .75rem 0" width={'100%'} maxWidth={350}>
              {loading ? <LoadingSpinner size="20px" /> : Localizations['continue'][lang]}
            </Button>
            <Button
              btnType="second"
              type="button"
              fontSize=".65rem"
              padding=".75rem 0 .75rem 0"
              margin="1rem 0 0 0"
              onClick={close}
              width={'100%'}
              maxWidth={350}
            >
              {Localizations['abort'][lang]}
            </Button>
          </form>
        </>
      </ADialog>
    </Portal>
  );
};
