const LLanguages = {
  "german": {
    "fr": "Allemand",
    "de": "Deutsch",
    "en": "German",
    "cz": "Deutsch"
  },
  "english": {
    "fr": "Anglais",
    "de": "Englisch",
    "en": "English",
    "cz": "Angličtina"
  },
  "czech": {
    "fr": "Tchèque",
    "de": "Tschechisch",
    "en": "Czech",
    "cz": "Čeština"
  },
  "french": {
    "fr": "Francais ",
    "de": "französisch",
    "en": "french",
    "cz": "francouzsky"
  }
};

export default LLanguages;