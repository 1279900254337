import { Device } from 'types/Device';
import { get, post } from './AJAX';

const prefix = 'mkn-configurator-device-';
const prefixCode = 'mkn-configurator-devices-code-';

export const getDevice = async (id: string): Promise<Device> => {
  if (!id) return null;

  const storageItem = sessionStorage.getItem(prefix + id);
  if (storageItem) return JSON.parse(storageItem);
  else {
    const { data, error } = await get(`${process.env.REACT_APP_API_URL}/device/get/${id}`);
    if (data) {
      sessionStorage.setItem(prefix + id, JSON.stringify(data));
      return data;
    }
    if (error) {
      console.error(error);
    }
  }
  return null;
};

export const getDevicesForCodeMaybe = (code: string): Device[] => {
  const storageItem = sessionStorage.getItem(prefixCode + code);
  if (storageItem) return JSON.parse(storageItem);
  else {
    setTimeout(() => {
      post(`${process.env.REACT_APP_API_URL}/device/search/code`, { data: { rows: 10, offset: 0, query: code, sort: { field: 'code', order: 'ASC' } } }).then(
        ({ data, error }) => {
          if (data) {
            if (data.length > 0) {
              sessionStorage.setItem(prefixCode + code, JSON.stringify(data));
            }
          }
          if (error) {
            console.error(error);
          }
        }
      );
    }, 0);
  }
  return null;
};

export const getDevicesForCode = (code: string): Promise<Device[]> => {
  return new Promise((resolve, reject) => {
    const storageItem = sessionStorage.getItem(prefixCode + code);
    if (storageItem) resolve(JSON.parse(storageItem));
    else {
      setTimeout(() => {
        post(`${process.env.REACT_APP_API_URL}/device/search/code`, { data: { rows: 10, offset: 0, query: code, sort: { field: 'code', order: 'ASC' } } }).then(
          ({ data, error }) => {
            if (data) {
              if (data.length > 0) {
                sessionStorage.setItem(prefixCode + code, JSON.stringify(data));
                resolve(data);
              }
            }
            if (error) {
              console.error(error);
              reject(error);
            }
          }
        );
      }, 0);
    }
  });
};
