import { Selectbox } from 'atoms';
import { BlockSelector, NumericInput } from 'components';
import { EditorContext, LangContext } from 'context';
import Localizations from 'languages';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import { useContext } from 'react';
import { useGlobalSettings, useUpturn } from './hooks';

export const MarineMeisterGlobal: React.FC = () => {
  const { setMarineMeisterView, selectedMarineMeister } = useContext(EditorContext);
  const { lang, langId } = useContext(LangContext);

  const {
    selectedHandlebar,
    setSelectedHandlebar,
    handrailNames,
    handleTypeOptionsLabels,
    isBorderLeftUpturned,
    isBorderRightUpturned,
    setHandleType,
    borderNames,
    borders,
    handleType,
    setSelectedBorderLeft,
    setSelectedBorderRight,
    selectedBorderLeft,
    selectedBorderRight,
    updating
  } = useGlobalSettings();

  const { handleUpturnInput, upturn, upturnL, upturnR } = useUpturn();

  const IS_WALL = selectedMarineMeister?.getSingleType() === 'Wall' && selectedMarineMeister.getType() === 'Single';
  return (
    <div>
      <ContextMenu mode="back" noMargin onClick={() => setMarineMeisterView('home')}></ContextMenu>
      <div className="mt-2">
        <BlockSelector />
      </div>

      <div className="mb-2">
        <h5 className="mb-1">{Localizations['handlebar'][lang]}</h5>
        <Selectbox
          state={selectedHandlebar}
          setState={setSelectedHandlebar}
          options={
            handrailNames && handrailNames.length > 0 && handrailNames[0] !== ''
              ? [...handrailNames.map(hR => hR[langId]['name']), Localizations['none'][lang]]
              : ['']
          }
        />
      </div>
      {!IS_WALL && selectedHandlebar !== Localizations['none'][lang] && (
        <div className="mb-2">
          <h5 className="mb-1">{Localizations['handlebarPosition'][lang]}</h5>
          <Selectbox
            state={handleTypeOptionsLabels.find(ol => ol.value === handleType)?.label}
            setState={v => {
              setHandleType(handleTypeOptionsLabels.find(ol => ol.label === v)?.value);
              const isUmlaufend = v === Localizations['Full'][lang];
              if (isUmlaufend) {
                const roundedBorderDevice = borders.find(b => b.style === 'BorderRoundRound');
                setSelectedBorderLeft(roundedBorderDevice.translations[langId].description);
                selectedMarineMeister.setBorderLeft(roundedBorderDevice);
                setSelectedBorderRight(roundedBorderDevice.translations[langId].description);
                selectedMarineMeister.setBorderRight(roundedBorderDevice);
              }
            }}
            options={handleTypeOptionsLabels.map(ol => ol?.label)}
          />
        </div>
      )}

      <div className="mb-2">
        <h5 className="mb-1">{`${Localizations['border'][lang]} (${Localizations['right'][lang]})`}</h5>
        <Selectbox
          disabled={updating}
          state={selectedBorderRight}
          setState={setSelectedBorderRight}
          options={
            borderNames && borderNames.length > 0 && borderNames[0] !== ''
              ? [...borderNames.map(bN => bN[langId]['description']) /* , Localizations['none'][lang] */]
              : ['']
          }
        />
      </div>

      <div className="mb-2">
        <h5 className="mb-1">{`${Localizations['border'][lang]} (${Localizations['left'][lang]})`}</h5>
        <Selectbox
          disabled={updating}
          state={selectedBorderLeft}
          setState={val => {
            setSelectedBorderLeft(val);
          }}
          options={
            borderNames && borderNames.length > 0 && borderNames[0] !== ''
              ? [...borderNames.map(bN => bN[langId]['description']) /* , Localizations['none'][lang] */]
              : ['']
          }
        />
      </div>
      {IS_WALL && (
        <>
          <div className="mt-2">
            <NumericInput
              className="Submenu-Input"
              labelClassName="Submenu-Label"
              label={`${Localizations['upstand'][lang]} ${Localizations['Top'][lang]} (mm)`}
              min={100}
              max={300}
              step={10}
              value={upturn}
              onChange={u => handleUpturnInput(Number(u))}
              validateOnBlur
            />
            <br />
          </div>
        </>
      )}
      {isBorderLeftUpturned && (
        <div className="mt-2">
          <NumericInput
            className="Submenu-Input"
            labelClassName="Submenu-Label"
            label={`${Localizations['upstand'][lang]} ${Localizations['left'][lang]} (mm)`}
            min={100}
            max={300}
            step={10}
            value={upturnL}
            onChange={u => handleUpturnInput(Number(u), 'L')}
            validateOnBlur
          />
          <br />
        </div>
      )}
      {isBorderRightUpturned && (
        <div className="mt-2">
          <NumericInput
            className="Submenu-Input"
            labelClassName="Submenu-Label"
            label={`${Localizations['upstand'][lang]} ${Localizations['right'][lang]} (mm)`}
            min={100}
            max={300}
            step={10}
            value={upturnR}
            onChange={u => handleUpturnInput(Number(u), 'R')}
            validateOnBlur
          />
          <br />
        </div>
      )}
    </div>
  );
};
