import React from 'react';
import './style.scss';

interface Props {
  animationOrder?: number;
  className: string;
  color?: string;
  onClick?: () => void;
}

export const AItem: React.FC<Props> = ({ animationOrder, className, color, children, onClick }) => {
  const style = { '--animation-order': animationOrder } as React.CSSProperties;
  return (
    <div className={className + ' AItem' + (color ? ' AItem-White-Transparent' : '')} onClick={onClick} style={style}>
      {children}
    </div>
  );
};
