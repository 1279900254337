const LImprint = {
  "headlineImprint": {
    "fr": "A propos de nous",
    "de": "Impressum",
    "en": "About us",
    "cz": "Impressum"
  },
  "secondHeadlineImprint": {
    "fr": "Coordonnées de l'entreprise",
    "de": "",
    "en": "Company details",
    "cz": ""
  },
  "phone": {
    "fr": "Téléphone",
    "de": "Telefon",
    "en": "Phone",
    "cz": "Telefon"
  },
  "fax": {
    "fr": "Fax ",
    "de": "Telefax",
    "en": "Fax",
    "cz": "Telefax"
  },
  "manager": {
    "fr": "Directeur:- Dipl.-Kfm. Georg Weber, MBM",
    "de": "Geschäftsführer: Dipl.-Kfm. Georg Weber, MBM",
    "en": "Manager:- Dipl.-Kfm. Georg Weber, MBM",
    "cz": "Jednatel: Dipl.-Kfm. Georg Weber, MBM"
  },
  "personallyLiable": {
    "fr": "Associé responsable personnellement: Neubauer management company with limited liability",
    "de": "Persönlich haftende Gesellschafterin: Neubauer Verwaltungsgesellschaft mit beschränkter Haftung",
    "en": "Personally liable partner: Neubauer management company with limited liability",
    "cz": "Osobně ručící společník: Neubauer Verwaltungsgesellschaft mit beschränkter Haftung"
  },
  "court": {
    "fr": "Tribunal d'instance de Braunschweig HRA 12658",
    "de": "Amtsgericht Braunschweig HRA 12658",
    "en": "District court Braunschweig HRA 12658",
    "cz": "Obvodní soud Braunschweig, HRA 12658"
  },
  "taxOffice": {
    "fr": "Bureau des impôts Wolfenbüttel",
    "de": "Finanzamt Wolfenbüttel",
    "en": "Tax Office Wolfenbüttel",
    "cz": "Finanční úřad Wolfenbüttel"
  },
  "taxNumber": {
    "fr": "Numéro d'impôt : 51 / 202 / 01802",
    "de": "Steuer-Nr.: 51 / 202 / 01802",
    "en": "Tax No.: 51 / 202 / 01802",
    "cz": "Daňové č.: 51 / 202 / 01802"
  },
  "vat": {
    "fr": "Numéro de TVA : DE 116 887 327",
    "de": "USt.-ID.-Nr.: DE 116 887 327",
    "en": "VAT No.: DE 116 887 327",
    "cz": "DIČ: DE 116 887 327"
  },
  "contentHeader": {
    "fr": "Contenu",
    "de": "Urheberschutz",
    "en": "Content",
    "cz": "Ochrana autorských práv"
  },
  "contentContent": {
    "fr": "L'auteur se réserve le droit de ne pas être responsable de l'actualité, de l'exactitude, de l'exhaustivité ou de la qualité des informations fournies. Les réclamations de responsabilité concernant les dommages causés par l'utilisation de toute information fournie, y compris tout type d'information incomplète ou incorrecte, seront donc rejetées. Toutes les offres sont sans engagement et sans obligation. L'auteur se réserve le droit de compléter, de modifier ou de supprimer partiellement ou totalement certaines parties des pages ou l'ensemble de la publication, y compris l'ensemble des offres et des informations, sans préavis.",
    "de": "Sämtliche Texte, Scripte und Bilddateien sind urheberrechtlich geschützt. Die Verwertung und Vervielfältigung dieser Inhalte und Daten ohne schriftliche Genehmigung ist untersagt (§72 i.V.m. §2 Abs. 1 Nr. 5 UrhG).",
    "en": "The author reserves the right not to be responsible for the topicality, correctness, completeness or quality of the information provided. Liability claims regarding damage caused by the use of any information provided, including any kind of information which is incomplete or incorrect, will therefore be rejected. All offers are not-binding and without obligation. Parts of the pages or the complete publication including all offers and information might be extended, changed or partly or completely deleted by the author without separate announcement.",
    "cz": "Veškeré texty, skripty a obrazové soubory jsou chráněny autorským právem. Využívání a rozmnožování těchto obsahů a dat bez písemného souhlasu je zakázáno (§72 v kombinaci s §2 odst. 1 č. 5 autorského zákona)."
  },
  "referralsHeader": {
    "fr": "Renvois et liens",
    "de": "Hyperlinks",
    "en": "Referrals and links",
    "cz": "Hypertextové odkazy"
  },
  "referralsContent": {
    "fr": "L'auteur n'est pas responsable des contenus liés ou référencés à partir de ses pages - à moins qu'il n'ait une connaissance totale des contenus illégaux et qu'il soit en mesure d'empêcher les visiteurs de son site de consulter ces pages. En cas de dommages causés par l'utilisation des informations présentées sur ces pages, seul l'auteur des pages concernées peut être tenu pour responsable, et non celui qui a créé un lien vers ces pages. En outre, l'auteur n'est pas responsable des messages publiés par les utilisateurs des forums de discussion, des livres d'or ou des listes de diffusion fournis sur sa page.",
    "de": "Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden, dass man durch die Ausbringung eines Links die Inhalte der gelinkten Seite ggf. mit zu verantworten hat. Dies kann, so das LG, nur dadurch verhindert werden, dass man sich von diesen Inhalten distanziert. Auch auf unseren Webseiten finden Sie Links zu anderen Webseiten, von denen wir uns deshalb ausdrücklich distanzieren. Wir haben keinerlei Einfluss auf Inhalte und Gestaltung dieser Webseiten und machen uns deren Inhalte nicht zu eigen.",
    "en": "The author is not responsible for any contents linked or referred to from his pages - unless he has full knowledge of illegal contents and would be able to prevent the visitors of his site from viewing those pages. If any damage occurs by the use of information presented there, only the author of the respective pages might be liable, not the one who has linked to these pages. Furthermore the author is not liable for any postings or messages published by users of discussion boards, guestbooks or mailinglists provided on his page.",
    "cz": "Rozsudkem ze dne 12. května 1998 rozhodl zemský soud v Hamburku, že při zveřejnění odkazu je osoba, která odkaz zveřejnila, příp. spoluzodpovědná za obsahy stránky, na kterou odkazuje. Podle zemského soudu tomu lze zabránit pouze tak, že se příslušná osoba od těchto obsahů distancuje. Také na našich webových stránkách naleznete odkazy na jiné webové stránky, od kterých se výslovně distancujeme. Nemáme žádný vliv na obsahy a uspořádání těchto webových stránek a jejich obsahy nepřijímáme za své."
  },
  "dataProtectionLinkHeader": {
    "fr": "Sécurité des données",
    "de": "Datenschutz",
    "en": "Data security",
    "cz": "Ochrana osobních údajů"
  },
  "dataProtectionLinkContent": {
    "fr": "En savoir plus sur la sécurité des données",
    "de": "Mehr Informationen zum Datenschutz",
    "en": "More information about data security",
    "cz": "Více informací o ochraně osobních údajů"
  },
  "here": {
    "fr": "ici",
    "de": "hier",
    "en": "here",
    "cz": "zde"
  },
  "copyright": {
    "fr": "Droit d'auteur",
    "de": "",
    "en": "Copyright",
    "cz": ""
  },
  "copyrightContent": {
    "fr": "L'auteur a l'intention de ne pas utiliser de matériel protégé par des droits d'auteur pour la publication ou, si cela n'est pas possible, d'indiquer les droits d'auteur de l'objet concerné. Les droits d'auteur de tout matériel créé par l'auteur sont réservés. Toute reproduction ou utilisation d'objets tels que des diagrammes, des sons ou des textes dans d'autres publications électroniques ou imprimées n'est pas autorisée sans l'accord de l'auteur.",
    "de": "",
    "en": "The author intended not to use any copyrighted material for the publication or, if not possible, to indicate the copyright of the respective object. The copyright for any material created by the author is reserved. Any duplication or use of objects such as diagrams, sounds or texts in other electronic or printed publications is not permitted without the author's agreement.",
    "cz": ""
  },
  "legalValidity": {
    "fr": "Validité juridique de cette clause de non-responsabilité",
    "de": "",
    "en": "Legal validity of this disclaimer",
    "cz": ""
  },
  "legalValidityContent": {
    "fr": "Cette clause de non-responsabilité doit être considérée comme faisant partie de la publication Internet à laquelle vous avez été renvoyé. Si certaines parties ou certains termes de cette déclaration ne sont pas légaux ou corrects, le contenu ou la validité des autres parties n'en sont pas affectés.",
    "de": "",
    "en": "This disclaimer is to be regarded as part of the internet publication which you were referred from. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.",
    "cz": ""
  }
};

export default LImprint;