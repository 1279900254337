import { useContext, useEffect, useState } from 'react';
import { BordSettings } from 'page/Editor/Menu/Menus/Masterline/Partials';
import Localizations from 'languages';
import { Device } from 'types';
import { EditorContext, LangContext } from 'context';

export const useShelfSelection = (
  didMount: boolean,
  shelfOptions: string[],
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>,
  selectedShelf: string,
  boardSettings: BordSettings,
  setBoardSettings: React.Dispatch<React.SetStateAction<BordSettings>>,
  boardDevices: Device[],
  shelfInitialized: boolean
) => {
  const { selectedMasterline } = useContext(EditorContext);
  const [boardParent, setBoardParent] = useState(selectedMasterline);
  const { lang, langId } = useContext(LangContext);

  useEffect(() => {
    setBoardParent(selectedMasterline);
  }, [selectedMasterline]);

  useEffect(() => {
    if (didMount && shelfInitialized && Array.isArray(shelfOptions) && shelfOptions.length > 1 && boardParent.getId() === selectedMasterline.getId()) {
      setUpdating(true);
      const currentBoard = selectedMasterline.getBoard();

      if (selectedShelf === Localizations['noShelf'][lang]) {
        const { salamanderLeft, salamanderRight, mixingFaucetLeft, mixingFaucetRight } = boardSettings;
        if (salamanderLeft || salamanderRight) {
          setBoardSettings({ ...boardSettings, salamanderLeft: false, salamanderRight: false });
        }
        if (!mixingFaucetLeft?.length && !mixingFaucetRight?.length) {
          selectedMasterline.enableBoard(false, null);
        } else {
          selectedMasterline.enableBoard(true, null);
        }
      } else if (selectedShelf === Localizations['salamanderBoard'][lang]) {
        selectedMasterline.enableBoard(true, null);
        setBoardSettings({ ...boardSettings });
      } else {
        const shelf = boardDevices.find(board => {
          return (
            board && board.translations && board.translations[langId] && board.translations[langId].name && board.translations[langId].name === selectedShelf
          );
        });
        if (shelf) {
          if (!(currentBoard?.getDeviceObject()?.id === shelf.id)) {
            if (shelf?.style === 'Tray600') {
              setBoardSettings({ ...boardSettings, mixingFaucetLeft: [], mixingFaucetRight: [], marginMixingFaucetLeft: 0, marginMixingFaucetRight: 0 });
            }
            selectedMasterline.enableBoard(true, shelf);

            const marginLeft = currentBoard?.getMarginLeft();
            const marginRight = currentBoard?.getMarginRight();
            setBoardSettings({ ...boardSettings, marginLeft, marginRight });
          }
        }
      }
    }
    setUpdating(false);
  }, [selectedShelf, selectedMasterline, boardParent, shelfInitialized]);
};
