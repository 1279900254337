import { useUser } from 'context';
import { useEffect, useState } from 'react';
import { Organization } from 'types';
import { get } from 'utils';

export const useOrganizationData = () => {
  const { organization } = useUser();
  const [loading, setLoading] = useState(false);
  const [organizationData, setOrganizationData] = useState<Organization | null>(null);

  const refetchData = async () => {
    if (!organization?.id) return;
    setLoading(true);
    const { data, error } = await get(`${process.env.REACT_APP_API_URL}/organization/get/${organization.id}`);
    if (data) {
      setOrganizationData(data);
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    refetchData();
  }, [organization?.id]);

  return {
    loading,
    organizationData,
    refetchData,
    setOrganizationData
  };
};
