import * as BABYLON from 'babylonjs';
import Scene, { ViewName } from 'page/Editor/Scene';
import MaterialUtils from '../../util/MaterialUtils';
import RoomNode, { Wall } from '../RoomNode';
import MarkUtils from 'components/babylon/util/MarkUtils';
import { InnerRoom } from 'page/Editor/configuration/Room';

export default class InnerRoomNode extends BABYLON.TransformNode {
  private _innerRoom: InnerRoom;

  protected _element: Wall;
  protected _picker: BABYLON.Mesh;

  constructor(innerRoom: InnerRoom, name?: string, scene?: BABYLON.Scene, isPure?: boolean) {
    super(name, scene, isPure);
    this._innerRoom = innerRoom;

    const node = new BABYLON.TransformNode('element', Scene.CURRENT_SCENE);
    node.parent = this;
    const upper = BABYLON.Mesh.CreateBox('upper', 1, Scene.CURRENT_SCENE);
    upper.parent = node;
    upper.isPickable = false;
    upper.material = MaterialUtils.MATERIAL_WALL_GRAY;
    upper.subMeshes = [];
    const verticesCount = upper.getTotalVertices();
    upper.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, 0, 24, upper));
    upper.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 24, 8, upper));
    // upper.setEnabled(Scene.CURRENT_SCENE['getView']() === 'Top');
    // upper.layerMask = 0x20000000;
    const lower = BABYLON.Mesh.CreateBox('lower', 1, Scene.CURRENT_SCENE);
    lower.parent = node;
    lower.isPickable = false;
    lower.material = MaterialUtils.MATERIAL_WALL_GRAY;
    lower.subMeshes = [];
    lower.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, 0, 24, lower));
    lower.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 24, 8, lower));

    const lh = RoomNode.LOWER_WALL_HEIGHT;
    const uh = RoomNode.WALL_HEIGHT - lh;

    upper.scaling.y = uh;
    upper.position.y = uh / 2 + lh;

    lower.scaling.y = lh;
    lower.position.y = lh / 2;

    this._element = {
      node,
      lower,
      upper
    };

    // Scene.CURRENT_SCENE['registerOnViewChange'](this.onViewChangeEvent);

    // const node = BABYLON.Mesh.CreateBox('lower', 1, Scene.CURRENT_SCENE);
    // node.parent = this;
    // node.isPickable = false;
    // node.material = MaterialUtils.MATERIAL_WALL_GRAY;
    // const verticesCount = node.getTotalVertices();
    // node.subMeshes = [];
    // node.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, 0, 24, node));
    // node.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 24, 8, node));

    // this._element = {
    //   node,
    //   lower: null,
    //   upper: null
    // };

    // Always same Height
    // this._element.node.position.y = RoomNode.LOWER_WALL_HEIGHT / 2;
    // this._element.node.scaling.y = RoomNode.LOWER_WALL_HEIGHT;

    const picker = BABYLON.Mesh.CreateBox('picker', 1, Scene.CURRENT_SCENE);
    picker.parent = node;
    picker.scaling.x = 1.01;
    picker.scaling.z = 1.01;
    picker.scaling.y = 1.01;
    picker.scaling.y = RoomNode.WALL_HEIGHT + 0.5;
    picker.position.y = picker.scaling.y / 2;
    picker.material = MaterialUtils.MATERIAL_TRANSPARENT;
    picker.isPickable = true;
    this._picker = picker;

    this.parent = innerRoom.getRoom().getNode();
  }

  onViewChangeEvent = (view: ViewName, camera: BABYLON.Camera) => {
    // this._element.upper.setEnabled(view === 'Top');
  };

  /**
   * Updates wall based on InnerRoom
   */
  public updatePosition() {
    const pos = this._innerRoom.getPosition();
    const rot = this._innerRoom.getRotation();

    this.position.x = pos.x;
    this.position.z = pos.y;

    this._element.node.rotation.y = rot;
  }

  /**
   * Updates width based on InnerRoom
   */
  public updateWidth() {
    const width = this._innerRoom.getWidth() / 10;

    this._element.node.scaling.x = width;
  }

  /**
   * Updates width based on InnerRoom
   */
  public updateDepth() {
    const depth = this._innerRoom.getDepth() / 10;

    this._element.node.position.z = depth / 2;
    this._element.node.scaling.z = depth;
  }

  /**
   * Updates width based on InnerRoom
   */
  public updateHeight() {
    const { upper, lower } = this._element;

    const lh = RoomNode.LOWER_WALL_HEIGHT;
    const uh = Math.max(this._innerRoom.getHeight() / 10 - lh, 0);

    upper.scaling.y = uh;
    upper.position.y = uh / 2 + lh;

    lower.scaling.y = lh;
    lower.position.y = lh / 2;
  }

  public getInnerRoom() {
    return this._innerRoom;
  }

  public setEnabledMark(value: boolean) {
    if (value) this._picker.material = MarkUtils.fullMaterial;
    else this._picker.material = MaterialUtils.MATERIAL_TRANSPARENT;
  }

  public setPickable(value: boolean) {
    this._picker.isPickable = value;
  }

  public setShowLabels(value: boolean) {
    //this._labels.forEach(label => label.setEnabled(value));
  }

  dispose() {
    // Scene.CURRENT_SCENE['unregisterOnViewChange'](this.onViewChangeEvent);
    super.dispose();
  }
}
