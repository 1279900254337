import React, { CSSProperties, useEffect, useState } from 'react';
import './style.scss';

interface Props {
  className?: string;
  duration?: number;
  topExit?: string;
  topEnter?: string;
  style?: CSSProperties;
}

export const ADialog: React.FC<Props> = ({ className, children, style }) => {
  const [pose, setPose] = useState<'-Enter' | ''>('');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setPose('');
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (mounted) setPose('-Enter');
    else setPose('');
  }, [mounted]);
  return (
    <div className={className + ` ADialog${pose}`} style={style}>
      {children}
    </div>
  );
};
