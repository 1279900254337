import React, { Fragment, FC, useContext } from 'react';

// Context:
import { LangContext } from 'context';

// Atoms:
import { Input, TextArea, Switch } from 'atoms';

// Languages:
import Localizations from 'languages';

// Style
import { colors } from 'styles/theme';

// ===================================================================
interface Props {
  message: string;
  setMessage: (message: string) => void;
  emails: string;
  setEmails: (emails: string) => void;
  allowContact: boolean;
  sketch: boolean;
  setSketch: (sketch: boolean) => void;
  setAllowContact: (status: boolean) => void;
}

// ===================================================================
export const Send: FC<Props> = ({ message, setMessage, emails, setEmails, sketch, setSketch, allowContact, setAllowContact }) => {
  const { lang } = useContext(LangContext);

  return (
    <Fragment>
      <TextArea color={colors['medium']} value={message} setValue={setMessage} placeholder={Localizations['yourMessage'][lang]} margin="1rem 0 1.5rem" />
      <Input
        color="light"
        value={emails}
        onChange={(event: any) => setEmails(event.target.value)}
        placeholder={Localizations['emails'][lang]}
        fontWeight="normal"
        fontColor={colors['medium']}
      />
      <div
        className="flex align-center text-center w-100"
        style={{
          padding: '1rem 0rem'
        }}
      ></div>

      <div
        className="flex align-center text-center w-100"
        style={{
          padding: '1rem 0rem'
        }}
      >
        <Switch
          status={allowContact}
          margin="0"
          shadow
          toggleStatus={() => {
            setAllowContact(!allowContact);
          }}
        />
        <span className="ml-2">{Localizations['contactFromMKN'][lang]}</span>
      </div>
      {/* <Checkbox boxSize="1.25rem" status={allowContact} setStatus={setAllowContact} label={Localizations['contactFromMKN'][lang]} /> */}
    </Fragment>
  );
};
