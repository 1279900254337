import React, { FC, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// Styles:
import './style.scss';

// Context:
import { EditorContext } from 'context';

// Partials:
import { HeaderContent, HeaderNavigation } from './Partials';

// Types
import { SavedConfiguration } from 'types';

// Utils
import { post } from 'utils';

// Consts:
const NAV_HEIGHT = 100;

// ==============================================================
export const Header: FC = () => {
  const { configuration, owner, savedConfiguration, setSavingError, setSavedConfiguration } = useContext(EditorContext);
  const [isEditor, setEditor] = useState<boolean>(false);
  const [configIsOpen, setConfigIsOpen] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!isEditor && window.location.pathname.includes('editor')) setEditor(true);
    else setEditor(false);
  }, [window.location.pathname]);

  useEffect(() => {
    if (!isEditor && configIsOpen) setConfigIsOpen(false);
  }, [isEditor]);

  useEffect(() => {
    const saveConfiguration = async () => {
      if (savedConfiguration && savedConfiguration.id !== '') {
        const { data, error } = await post(`${process.env.REACT_APP_API_URL}/configuration/save`, {
          data: {
            id: savedConfiguration.id ? savedConfiguration.id : null,
            customer: savedConfiguration.customer,
            location: savedConfiguration.location,
            name: savedConfiguration.name,
            public: savedConfiguration.public,
            json: configuration ? configuration.exportJSON() : ''
          }
        });

        setTimeout(() => {
          if (data) {
            if (!savedConfiguration.id) {
              const newConfig: SavedConfiguration = {
                id: data.id,
                customer: data.customer,
                name: data.name,
                public: data.public,
                json: data.json,
                created: data.created,
                email: data.email,
                location: data.location
              };
              setSavedConfiguration(newConfig);
            }

            history.push(`/editor?id=${data.id}`);
          }
        }, 1000);
        if (error) {
          setSavingError(true);
          console.log(error);
        }
      }
    };
    if (owner && configIsOpen) {
      saveConfiguration();
    }
  }, [configIsOpen]);

  // Render:
  return (
    <div className="Header">
      {/* Main Header */}
      <HeaderNavigation
        savedConfiguration={savedConfiguration}
        isEditor={isEditor}
        configIsOpen={configIsOpen}
        setConfigIsOpen={(status: boolean) => setConfigIsOpen(status)}
      />

      <HeaderContent savedConfiguration={savedConfiguration} configIsOpen={configIsOpen} navHeight={NAV_HEIGHT} />
    </div>
  );
};
