import React, { useCallback, useContext, useEffect, useState } from 'react';

// Components
import { LoadingSpinner } from 'components';
import { ErrorMessage, SearchResults, SelectedCategory, SelectedCategoryFull, SelectedDevice } from 'page/Editor/Menu/Shared';
import FreeItem from '../../../configuration/FreeItem';
import BlockItem from '../../../configuration/BlockItem';
import { EquipmentHelper } from '../../../configuration/Equipment';

// Context
import { EditorContext, LangContext } from 'context';

// Languages
import Localizations from 'languages';

// Styles
import './style.scss';

// Types
import { Category } from 'types';

// Utils
import { getCategories, hasOwnProperty, isDevice } from 'utils';

export const SpaceCombiMenu = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>(null);
  const [fullscreenIsMenu, setFullscreenIsMenu] = useState<boolean>(false);

  const { lang } = useContext(LangContext);
  const { configuration, errorAlert, fullscreen, menuView, scene, searchResults, setSeriesFilter, selection, setSelection, setErrorAlert } =
    useContext(EditorContext);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Delete') {
        if (selection && selection instanceof FreeItem) {
          setErrorAlert(['delete']);
        }
      }
    },
    [selection]
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await getCategories('spaceCombi');
      if (data) {
        setCategories(data);
        setSelectedCategory(data[0]);
      }
      if (error) {
        console.log(error);
      }
    };
    fetchCategories();
    setSeriesFilter('all');

    return () => {
      setSelectedCategory(null);
    };
  }, []);

  useEffect(() => {
    if (selection) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selection]);

  useEffect(() => {
    if (fullscreen === 'menu') {
      setFullscreenIsMenu(true);
    }
    if (fullscreen !== 'menu' && fullscreenIsMenu) {
      if (scene && scene.getSelected && scene.getSelected() instanceof BlockItem) {
        setSelectedCategory(null);
        setSelection(scene.getSelected());
      }
      setFullscreenIsMenu(false);
    }
  }, [fullscreen, fullscreenIsMenu]);

  if (loading) {
    return <LoadingSpinner color="blue" />;
  }

  if (!(menuView === 'spaceCombi')) {
    return <></>;
  }

  if (searchResults && !errorAlert?.includes('size') && !selection && menuView === 'spaceCombi') {
    return <SearchResults />;
  }

  return (
    <>
      {/* Search Results */}
      {!fullscreen && searchResults && !errorAlert?.includes('size') && !selection && <SearchResults />}

      {/* Selected Category */}
      {!fullscreen &&
        !searchResults &&
        selectedCategory &&
        (!selection || (selection && hasOwnProperty(selection, 'type') && isDevice(selection))) &&
        menuView === 'spaceCombi' && (
          <SelectedCategory noSeries extraGoBack setSelection={setSelection} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
        )}

      {/* Delete Confirm */}
      {!fullscreen && !searchResults && Array.isArray(errorAlert) && errorAlert.length > 0 && errorAlert.includes('delete') && (
        <ErrorMessage
          title={Localizations['delete'][lang]}
          message={Localizations['confirmDelete'][lang]}
          confirm={() => {
            if (configuration) {
              const spaceCombis = configuration.getSpaceCombis();
              if (spaceCombis && spaceCombis.length > 0 && selection instanceof FreeItem) {
                const index = spaceCombis.findIndex((sC: FreeItem) => sC.getUniqueId() === selection.getUniqueId());
                if (index !== -1) {
                  configuration.removeSpaceCombi(index);
                }
              }
            }
            setSelection(null);
            setErrorAlert(null);
            scene.setSelected(null);
          }}
          goBack={() => setErrorAlert(null)}
        />
      )}

      {!fullscreen /* && !searchResults */ && (!Array.isArray(errorAlert) || !(errorAlert?.length > 0)) && selection && selection instanceof FreeItem && (
        <SelectedDevice
          isOverlay
          selectedDevice={[selection.getDeviceObject()]}
          showUnderframe={selection && (EquipmentHelper.canHaveUnderframe580(selection) || EquipmentHelper.canHaveUnderframe850(selection))}
        />
      )}

      {!fullscreen /* && !searchResults */ &&
        (!Array.isArray(errorAlert) || !(errorAlert?.length > 0)) &&
        selection &&
        hasOwnProperty(selection, 'type') &&
        isDevice(selection) && (
          /* @ts-ignore */
          <SelectedDevice isOverlay selectedDevice={[selection]} setSelection={setSelection} showUnderframe={false} />
        )}

      {/* Selected Category Full */}
      {selectedCategory && !selection && fullscreenIsMenu && menuView === 'spaceCombi' && (
        <SelectedCategoryFull setSelection={setSelection} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
      )}
    </>
  );
};
