import { useEffect, useRef, useState } from 'react';
import { Category } from 'types';
import { getCategories } from 'utils';

export const useMarineMeisterCategories = (voltage: number) => {
  const [categories, setCategories] = useState<Category[] | null>(null);
  const [error, setError] = useState(null);
  const mounted = useRef(true);
  useEffect(() => {
    const getData = async () => {
      const { data, error } = await getCategories('marineMeister', voltage);
      if (!mounted.current) return;
      if (data) {
        setCategories(data);
        setError(null);
      } else if (error) {
        setCategories(null);
        setError(error);
      }
    };
    getData();

    return () => {
      mounted.current = false;
    };
  }, []);
  return { categories, isLoading: !categories && !error };
};
