import DeviceNode, { PropertyType as DNPT } from 'components/babylon/node/DeviceNode';
import BasicUtils from 'components/babylon/util/BasicUtils';
import { Device } from 'types/Device';
import { Subtype } from 'types/DeviceEnum';
import Configuration from './Configuration';
import Equipment from './Equipment';
import Vector3 from './Vector3';

export default class FreeItem {
  public clazz = 'FreeItem';
  private _uniqueId: string;
  private device: string;
  public _device: Device;

  private equipments: Array<Equipment> = new Array<Equipment>();

  private position: Vector3 = new Vector3();
  private rotation: Vector3 = new Vector3();

  private _node: DeviceNode;
  private _configuration: Configuration;

  constructor(device: Device, configuration: Configuration) {
    this._uniqueId = Date.now() + BasicUtils.generateRandomString(16);
    this._configuration = configuration;
    if (device.model.flexiChef) configuration.getFlexiChefs().push(this);
    else if (device.model.spaceCombi) configuration.getSpaceCombis().push(this);
    else throw 'Unknown Device';

    this._device = device;
    this.device = device.id;
    // Created 3D Node
    this._node = new DeviceNode(this);
  }

  public compatibleWithEquipment(value: Subtype) {
    for (let i = 0; i < this._device.dependency.equipmentCombinations.length; i++) {
      let comb: string | Subtype = this._device.dependency.equipmentCombinations[i];
      if (typeof comb === 'string') comb = Subtype[comb];
      if (comb === value) {
        return true;
      }
    }
    return false;
  }

  public getUniqueId() {
    return this._uniqueId;
  }

  public getWidth() {
    return this._device.model.width;
  }

  public getHeight() {
    return this._device.model.height;
  }

  public getDepth() {
    return this._device.model.depths[0];
  }

  public getWidthSubstructure() {
    if (this._device.model.widthSubstructure > 0) return this._device.model.widthSubstructure;
    return this._device.model.width;
  }

  public getDeviceId() {
    return this.device;
  }

  public getDeviceObject() {
    return this._device;
  }

  public getEquipments() {
    return this.equipments;
  }

  public getPosition() {
    return this.position;
  }

  /**
   *
   * @param position in cm
   */
  public setPosition(position: Vector3) {
    this.position = position;
    this.getNode().position = position.get();
  }

  public getRotation() {
    return this.rotation;
  }

  public setRotation(rotation: Vector3) {
    this.rotation = rotation;
    this.getNode().rotation = rotation.get();
  }

  public getNode() {
    return this._node;
  }

  public getConfiguration() {
    return this._configuration;
  }

  public setShowLabels(value: boolean) {
    this._node.set(DNPT.Label, value);
  }

  public delete() {
    let items: Array<FreeItem> = [];
    if (this._device.model.flexiChef) items = this._configuration.getFlexiChefs();
    else if (this._device.model.spaceCombi) items = this._configuration.getSpaceCombis();
    for (let i = 0; i < items.length; i++) {
      const e = items[i];
      if (e.getNode().uniqueId === this.getNode().uniqueId) {
        if (this._device.model.flexiChef) this._configuration.removeFlexiChef(i);
        else if (this._device.model.spaceCombi) this._configuration.removeSpaceCombi(i);
        break;
      }
    }
  }
}
