import React from 'react';
import './style.scss';

interface Props {
  className: string;
  pose: 'left' | 'right';
  onClick: () => void;
}

export const ASlider: React.FC<Props> = ({ children, className, pose, onClick }) => {
  return (
    <div className={className + ` ASlider ASlider-${pose === 'left' ? 'Left' : 'Right'}`} onClick={onClick}>
      {children}
    </div>
  );
};
