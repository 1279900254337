import React, { FC, useContext, useState, useEffect } from 'react';
import { Portal } from 'react-portal';

// Atoms:
import { ASimple, ADialog, Button, Heading, Icon, Input } from 'atoms';

// Context:
import { AuthContext, EditorContext, LangContext } from 'context';

// Components:
import { LoadingSpinner } from 'components';
import { ResetPasswordDialog } from 'page/Login/ResetPasswordDialog';
import { ConfirmPasswordDialog } from 'page/Login/ConfirmPasswordDialog';

// Languages:
import Localizations from 'languages';

// Styles:
import './style.scss';
import { colors } from 'styles/theme';

// Utils:
import { post } from 'utils';

// ===================================================================
interface Props {}

// ===================================================================
export const ProfileDialog: FC<Props> = () => {
  const { userEmail } = useContext(AuthContext);
  const { profileOpen, setProfileOpen } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState(userEmail ? userEmail : '');
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [result, setResult] = useState<'' | 'success' | 'error'>('');
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);

  /* Methods */
  const close = () => {
    setProfileOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setEmail(userEmail);
    setPassword('');
    setRepeatPassword('');
    setNewPassword('');
  };

  const updateProfile = async () => {
    setLoading(true);
    const updateData =
      newPassword && newPassword.length > 0
        ? {
            email,
            passwordOld: password,
            password: newPassword,
            passwordRepeat: repeatPassword
          }
        : { email };
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/member/profile/save`, {
      data: updateData
    });
    if (data) {
      setResult('success');
      setPassword('');
      setNewPassword('');
      setRepeatPassword('');
    } else if (error) {
      setResult('error');
      setPassword('');
      setNewPassword('');
      setRepeatPassword('');
    }
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      setPassword('');
      setRepeatPassword('');
      setNewPassword('');
    };
  }, []);

  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(newPassword === repeatPassword);

  const checkPassword = (pw: string) => {
    return pw.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{9,}$/g);
  };

  // Effects
  useEffect(() => {
    setPasswordsMatch(newPassword === repeatPassword);
    if (newPassword) {
      setPasswordValid(checkPassword(newPassword) !== null);
    }
  }, [newPassword, repeatPassword]);

  useEffect(() => {
    setPasswordValid(checkPassword(newPassword) !== null);
  }, [newPassword]);

  /* Render */
  return (
    <Portal>
      {/* Background */}
      {profileOpen && <ASimple key="ProfileDialog-Background" className="ProfileDialog-Background" style={{ height: window.innerHeight }} onClick={close} />}

      {/* Content */}
      {profileOpen && (
        <ADialog key="ProfileDialog-Content" className="ProfileDialog-Content" topExit="0" topEnter="50%">
          {/* Close Icon */}
          <div className="ProfileDialog-Content-Icon">
            <Icon type="close" size="1.25rem" color="grey" onClick={close} />
          </div>

          {/* Main */}
          <div className="ProfileDialog-Content-Main">
            <Heading level="1" color="dark">
              {Localizations['profile'][lang]}
            </Heading>
            <Input
              className="LoginPage-InputField"
              onChange={event => setEmail(event.currentTarget.value)}
              placeholder="E-MAIL"
              type="email"
              value={email}
              margin="0rem 0 0.5rem"
            />
            <Input
              className="LoginPage-InputField"
              onChange={event => setPassword(event.currentTarget.value)}
              placeholder={Localizations['password'][lang].toUpperCase()}
              type="password"
              value={password}
              margin="1.5rem 0 0.5rem"
            />
            <div className="flex justify-end w-100">
              <button
                disabled
                onClick={() => {
                  setShowPasswordDialog(true);
                }}
                style={{
                  background: 'transparent',
                  color: colors.grey,
                  border: 'none',
                  cursor: 'not-allowed'
                }}
              >
                {Localizations['passwordforgotten'][lang]}
              </button>
            </div>
            {newPassword && (!passwordValid || (repeatPassword && !passwordsMatch)) ? (
              <span className="PasswordHint">{Localizations[!passwordValid ? 'invalidPassword' : 'passwordsDontMatch'][lang]}</span>
            ) : (
              <div className="PasswordHint"></div>
            )}
            <Input
              className="LoginPage-InputField"
              onChange={event => setNewPassword(event.currentTarget.value)}
              placeholder={Localizations['newPassword'][lang].toUpperCase()}
              type="password"
              value={newPassword}
              margin="0rem 0 0.5rem"
            />
            <Input
              className="LoginPage-InputField"
              onChange={event => setRepeatPassword(event.currentTarget.value)}
              placeholder={Localizations['confirmPassword'][lang].toUpperCase()}
              type="password"
              value={repeatPassword}
              margin="1.5rem 0 0.5rem"
            />
            <p
              className="text-center text-sm w-100"
              style={{
                color: result === 'error' ? 'red' : colors.medium,
                height: '26px',
                margin: '.5rem auto 0'
              }}
            >
              {result === 'success' ? Localizations['profileUpdated'][lang] : result === 'error' ? Localizations['profileUpdateError'][lang] : ''}
            </p>

            <Button
              btnType="third"
              disabled={newPassword.length > 0 && (!passwordValid || !passwordsMatch)}
              maxWidth={400}
              onClick={updateProfile}
              margin="0rem 0 0 0"
              padding=".65rem 2rem"
              fontSize=".8rem"
            >
              {loading ? <LoadingSpinner size="1rem" /> : Localizations['save'][lang]}
            </Button>
          </div>
        </ADialog>
      )}
      {showPasswordDialog && <ResetPasswordDialog setShowPasswordDialog={setShowPasswordDialog} />}
      {showResetDialog && <ConfirmPasswordDialog setShowPasswordDialog={setShowResetDialog} />}
    </Portal>
  );
};
