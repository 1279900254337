import * as BABYLON from 'babylonjs';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import Scene from 'page/Editor/Scene';
import HighPerformanceQueue from '../helper/HighPerformanceQueue';
import BasicUtils from '../util/BasicUtils';
import LabelUtils, { Orientation } from '../util/LabelUtils';
import MarkUtils from '../util/MarkUtils';
import DeviceNode, { PropertyType } from './DeviceNode';
import { OptionNode } from './OptionNode';

type Config = {
  model?: ConfigModel;
  output?: ConfigOutput;
};

type ConfigModel = {
  items: BABYLON.TransformNode;
  optionNodes: BABYLON.TransformNode;
  options: Map<number, OptionNode>;
};

type ConfigOutput = {
  node?: BABYLON.TransformNode;
  meshes?: BABYLON.TransformNode;
  visuals?: BABYLON.TransformNode;
  mark?: BABYLON.TransformNode;
  markFull?: BABYLON.TransformNode;
  label?: BABYLON.TransformNode;
  connectLabel?: BABYLON.TransformNode;
};

export default class BlockGroupNode extends BABYLON.TransformNode {
  private _group: BlockGroup;

  private config: Config = {
    model: {
      items: null,
      optionNodes: null,
      options: new Map<number, OptionNode>()
    },
    output: {
      node: null,
      meshes: null,
      visuals: null,
      mark: null,
      markFull: null,
      label: null,
      connectLabel: null
    }
  };

  private _isConnectLabel: boolean = false;

  constructor(group: BlockGroup) {
    super('group.' + group.getUniqueId(), Scene.CURRENT_SCENE, undefined);
    this._group = group;
    this.parent = group.getParent().getNode().getDeviceNode();

    this.config.model.items = new BABYLON.TransformNode('items', Scene.CURRENT_SCENE);
    this.config.model.items.parent = this;
    this.config.model.optionNodes = new BABYLON.TransformNode(this.name, Scene.CURRENT_SCENE);
    this.config.model.optionNodes.parent = DeviceNode.defaultSettings.modelNode;
    this.config.model.optionNodes.setEnabled(false);
    this.config.output.node = new BABYLON.TransformNode('output', Scene.CURRENT_SCENE);
    this.config.output.node.parent = this;
    this.config.output.meshes = new BABYLON.TransformNode('meshes', Scene.CURRENT_SCENE);
    this.config.output.meshes.parent = this.config.output.node;
    this.config.output.visuals = new BABYLON.TransformNode('visuals', Scene.CURRENT_SCENE);
    this.config.output.visuals.parent = this.config.output.node;
  }

  public setPosition(position: { x?: number; y?: number; z?: number }) {
    // console.log('setPosition', position)
    if (typeof position.x !== 'undefined') {
      this.position.x = position.x;
    }
    if (typeof position.y !== 'undefined') {
      this.position.y = position.y;
    }
    if (typeof position.z !== 'undefined') {
      this.position.z = position.z;
    }
  }

  public getBlockGroup() {
    return this._group;
  }

  public getDeviceNode() {
    return this.config.model.items;
  }

  public getBlock() {
    if (!this._group) return null;
    return this._group.getBlock();
  }

  public getBlockRow() {
    if (!this._group) return null;
    return this._group.getBlockRow();
  }

  public addOption(option: OptionNode, bake?: boolean): OptionNode {
    // Set Parent
    option.parent = this.config.model.optionNodes;
    // Apply Position
    if (option.getDeviceComponent().position)
      option.getModel().position = new BABYLON.Vector3(
        option.getDeviceComponent().position.x,
        option.getDeviceComponent().position.y,
        option.getDeviceComponent().position.z
      );
    // Prepare Type
    option.setContainer(this);
    option.prepareType();
    // Apply Height
    option.position.y = this.get(PropertyType.BottomHeight) as number;
    // Apply Depth
    if (!option.getDeviceComponent().fixed) {
      switch (this.get(PropertyType.Depth) as number) {
        case 700:
          option.position.z = 15;
          break;
        case 850:
          option.position.z = 0;
          break;
      }
    }
    option.prepareTypeDepthChange();
    // Add to Map
    this.config.model.options.set(option.uniqueId, option);

    if (bake) this.bake();

    return option;
  }

  public removeOption(option: BABYLON.TransformNode | string | number): void {
    if (option instanceof BABYLON.TransformNode) {
      // Node
      this.config.model.options.delete(option.uniqueId);
      if (!option.isDisposed()) option.dispose();
    } else if (typeof option === 'number') {
      // Number
      const obj = this.config.model.options.get(option);
      if (obj) {
        this.config.model.options.delete(option);
        if (!obj.isDisposed()) obj.dispose();
      }
    } else {
      // Name
      let obj: BABYLON.TransformNode;
      this.config.model.options.forEach(element => {
        if (element.name === (option as string)) {
          obj = element;
        }
      });
      if (obj) {
        this.config.model.options.delete(obj.uniqueId);
        if (!obj.isDisposed()) obj.dispose();
      }
    }
  }

  public set(property: PropertyType, value: string | boolean | number, bake?: boolean) {
    switch (property) {
      case PropertyType.Left:
        {
          const leftIndex = this.getLeftIndex();
          for (let i = 0; i < this._group.getItems().length; i++) {
            const item = this._group.getItems()[i];
            if (i === leftIndex) {
              item.getNode().set(PropertyType.Left, value);
            } else {
              item.getNode().set(PropertyType.Left, false);
            }
          }
        }
        break;
      case PropertyType.Right:
        {
          const rightIndex = this.getRightIndex();
          for (let i = 0; i < this._group.getItems().length; i++) {
            const item = this._group.getItems()[i];
            if (i === rightIndex) {
              item.getNode().set(PropertyType.Right, value);
            } else {
              item.getNode().set(PropertyType.Right, false);
            }
          }
        }
        break;

      case PropertyType.MergeCorpusLeft:
      case PropertyType.MergeCorpusRight:
        // Not Available here!
        break;

      case PropertyType.HandleLeft:
        {
          const leftIndex = this.getLeftIndex();
          for (let i = 0; i < this._group.getItems().length; i++) {
            const item = this._group.getItems()[i];
            if (i === leftIndex) {
              item.getNode().set(PropertyType.HandleLeft, value);
            } else {
              item.getNode().set(PropertyType.HandleLeft, false);
            }
          }
        }
        break;
      case PropertyType.HandleRight:
        {
          const rightIndex = this.getRightIndex();
          for (let i = 0; i < this._group.getItems().length; i++) {
            const item = this._group.getItems()[i];
            if (i === rightIndex) {
              item.getNode().set(PropertyType.HandleRight, value);
            } else {
              item.getNode().set(PropertyType.HandleRight, false);
            }
          }
        }
        break;
      case PropertyType.Width:
        // Not Available here!
        break;
      case PropertyType.Depth:
        for (let i = 0; i < this._group.getItems().length; i++) {
          const item = this._group.getItems()[i];
          item.getNode().set(PropertyType.Depth, value);
        }
        // All options
        const options = this.config.model.options.values();
        let option = null;
        while (typeof (option = options.next().value) !== 'undefined') {
          if (option instanceof OptionNode) {
            if (!option.getDeviceComponent().fixed) {
              switch (this.get(PropertyType.Depth) as number) {
                case 700:
                  option.position.z = 15;
                  break;
                case 850:
                  option.position.z = 0;
                  break;
              }
            }
            option.prepareTypeDepthChange();
          }
        }
        break;

      case PropertyType.Mark:
        for (let i = 0; i < this._group.getItems().length; i++) {
          const item = this._group.getItems()[i];
          item.getNode().set(PropertyType.Mark, false);
        }
        if (this.config.output.mark && !this.config.output.mark.isDisposed()) this.config.output.mark.setEnabled(value as boolean);
        if (this.config.output.connectLabel && !this.config.output.connectLabel.isDisposed())
          this.config.output.connectLabel.setEnabled((value as boolean) || this._isConnectLabel);
        break;
      case PropertyType.MarkFull:
        for (let i = 0; i < this._group.getItems().length; i++) {
          const item = this._group.getItems()[i];
          item.getNode().set(PropertyType.MarkFull, false);
        }
        if (this.config.output.markFull && !this.config.output.markFull.isDisposed()) this.config.output.markFull.setEnabled(value as boolean);
        break;
      case PropertyType.Label:
        for (let i = 0; i < this._group.getItems().length; i++) {
          const item = this._group.getItems()[i];
          item.getNode().set(PropertyType.Label, false);
        }
        if (this.config.output.label && !this.config.output.label.isDisposed()) this.config.output.label.setEnabled(value as boolean);
        break;

      case PropertyType.Rotate:
        for (let i = 0; i < this._group.getItems().length; i++) {
          const item = this._group.getItems()[i];
          item.getNode().set(PropertyType.Rotate, value);
        }
        this.refreshVisuals();
        break;

      default:
        for (let i = 0; i < this._group.getItems().length; i++) {
          const item = this._group.getItems()[i];
          item.getNode().set(property, value, bake);
        }
        break;
    }

    if (bake) this.bake();
  }

  public setAll(property: PropertyType, value: string | boolean | number, bake?: boolean) {
    for (let i = 0; i < this._group.getItems().length; i++) {
      this._group.getItems()[i].getNode().set(property, value);
    }

    if (bake) this.bake();
  }

  public get(property: PropertyType): string | boolean | number {
    switch (property) {
      case PropertyType.Left:
        return this._group.getItems()[this.getLeftIndex()].getNode().get(PropertyType.Left);
      case PropertyType.Right:
        return this._group.getItems()[this.getRightIndex()].getNode().get(PropertyType.Right);

      case PropertyType.MergeCorpusLeft:
      case PropertyType.MergeCorpusRight:
        return false;

      case PropertyType.HandleLeft:
        return this._group.getItems()[this.getLeftIndex()].getNode().get(PropertyType.HandleLeft);
      case PropertyType.HandleRight:
        return this._group.getItems()[this.getRightIndex()].getNode().get(PropertyType.HandleRight);

      case PropertyType.Width:
        let width = 0;
        for (let i = 0; i < this._group.getItems().length; i++) {
          const item = this._group.getItems()[i];
          width += item.getNode().get(PropertyType.Width) as number;
        }
        return width;

      case PropertyType.Mark:
        if (this.config.output.mark && !this.config.output.mark.isDisposed()) return this.config.output.mark.isEnabled();
        else return false;
      case PropertyType.MarkFull:
        if (this.config.output.markFull && !this.config.output.markFull.isDisposed()) return this.config.output.markFull.isEnabled();
        else return false;
      case PropertyType.Label:
        if (this.config.output.label && !this.config.output.label.isDisposed()) return this.config.output.label.isEnabled();
        else return false;

      default:
        return this._group.getItems()[0].getNode().get(property);
    }
  }

  public setConnectLabel(value: boolean) {
    this._isConnectLabel = value;
    if (this.config.output.connectLabel && !this.config.output.connectLabel.isDisposed())
      this.config.output.connectLabel.setEnabled((value as boolean) || this._isConnectLabel);
  }

  public isConnectLabel() {
    return this._isConnectLabel;
  }

  private getLeftIndex() {
    if (this._group.getParent().getType() === 'Bottom') return 0;
    else return this._group.getItems().length - 1;
  }

  private getRightIndex() {
    if (this._group.getParent().getType() === 'Bottom') return this._group.getItems().length - 1;
    else return 0;
  }

  public getBounds() {
    let bounds = null;
    for (let i = 0; i < this._group.getItems().length; i++) {
      const item = this._group.getItems()[i];
      if (bounds == null) bounds = item.getNode().getBounds();
      else {
        const b = item.getNode().getBounds();
        bounds.width += b.width;
        if (bounds.x.min > b.x.min) bounds.x.min = b.x.min;
        if (bounds.x.max < b.x.max) bounds.x.max = b.x.max;
        if (bounds.y.min > b.y.min) bounds.y.min = b.y.min;
        if (bounds.y.max < b.y.max) bounds.y.max = b.y.max;
        if (bounds.z.min > b.z.min) bounds.z.min = b.z.min;
        if (bounds.z.max < b.z.max) bounds.z.max = b.z.max;
      }
    }
    return bounds;
  }

  public getOutputNode() {
    return this.config?.output?.node;
  }

  public bake() {
    for (let i = 0; i < this._group.getItems().length; i++) {
      const item = this._group.getItems()[i];
      item.getNode().bake();
    }

    HighPerformanceQueue.push(this.uniqueId, () => {
      // Clear old output
      {
        const children = this.config.output.meshes.getChildren();
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          child.dispose();
        }
      }

      const masterline = this.getBlock().isMasterline();
      const extendedSize = this.getBlockRow().getExtendedSize();
      const height = this.get(PropertyType.BottomHeight) as number;

      // Fix Merge Bug...
      BasicUtils.computeAllWorldMatrix(this);

      // Build new Merge
      const nodes = new Array<BABYLON.TransformNode>();
      // Add all options
      const options = this.config.model.options.values();
      let option = null;
      while (typeof (option = options.next().value) !== 'undefined') {
        option.position.y = height;
        BasicUtils.computeAllWorldMatrix(option);
        // ---
        const meshes = option.getChildMeshes();
        // Hygiene
        if (option.getDeviceComponent().component.id === 'OK7l2') {
          if (this.get(PropertyType.Open)) nodes.push(BasicUtils.findFirstChild('HygieneOpen', option));
          else nodes.push(BasicUtils.findFirstChild('HygieneClosed', option));
          continue;
        }
        // WingedDoor
        if (option.getDeviceComponent().component.id === '7KP0K') {
          nodes.push(BasicUtils.findFirstChild('Front', option));
          if (this.get(PropertyType.Open)) nodes.push(BasicUtils.findFirstChild('Back', option));
          continue;
        }
        for (let j = 0; j < meshes.length; j++) {
          const mesh = meshes[j];
          nodes.push(mesh);
        }
      }

      // Merge
      const materialMap = new Map<string, BABYLON.Mesh[]>();
      const materialMapKeys = new Array<string>();
      for (let i = 0; i < nodes.length; i++) {
        const element = nodes[i];
        const childMeshes = element.getChildMeshes();
        for (let i = 0; i < childMeshes.length; i++) {
          const mesh = childMeshes[i];
          // console.log('check', mesh.name, mesh.material ? mesh.material.name : '', mesh);
          if (mesh instanceof BABYLON.Mesh && mesh.material) {
            switch (this.get(PropertyType.Depth) as number) {
              case 700:
                if (mesh.parent.name === 'Extension') {
                  continue;
                }
                break;
              case 850:
                break;
            }
            if (materialMap.has(mesh.material.name)) {
              materialMap.get(mesh.material.name).push(mesh);
            } else {
              materialMap.set(mesh.material.name, [mesh]);
              materialMapKeys.push(mesh.material.name);
            }
          }
        }
      }
      for (let i = 0; i < materialMapKeys.length; i++) {
        // console.log('.....................................');
        const mat = materialMapKeys[i];
        const meshes = materialMap.get(mat);
        // console.log('merge', mat, meshes);
        const mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, false, true, undefined, false);
        mergedMesh.name = 'mesh-' + mat;
        mergedMesh.parent = this.config.output.meshes;
        for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
          const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
          shadowGenerator.addShadowCaster(mergedMesh);
        }
        for (let i = 0; i < DeviceNode.defaultSettings.mirrors.length; i++) {
          const mirror = DeviceNode.defaultSettings.mirrors[i];
          mirror.renderList.push(mergedMesh);
        }
        mergedMesh.receiveShadows = true;
        mergedMesh.isPickable = false;
        if (masterline && this.getBlock().isFullBlendColor() && mergedMesh.name === 'mesh-_metal_blue_panel') {
          mergedMesh.material = this.getBlock().getBlendColorMaterial();
        } else if (masterline && mergedMesh.name === 'mesh-_metal_blue_panel_top') {
          mergedMesh.material = this.getBlock().getBlendColorMaterial();
        } else if (masterline && mergedMesh.name === 'mesh-_metal_substructure') {
          mergedMesh.material = this.getBlock().getDoorColorMaterial();
        }
      }

      if (this.get(PropertyType.Rotate)) {
        this.config.output.meshes.position = new BABYLON.Vector3(this._group.getWidth() / 10, 0, -this._group.getBlockRow().getDepth() / 10);
        this.config.output.meshes.rotation = new BABYLON.Vector3(0, Math.PI, 0);
      } else {
        this.config.output.meshes.position = BABYLON.Vector3.Zero();
        this.config.output.meshes.rotation = BABYLON.Vector3.Zero();
      }

      this.extended(extendedSize);

      this.refreshVisuals();
      return true;
    });
  }

  public extended(extendedSize: number) {
    if (extendedSize > 0) {
      const depth = this.get(PropertyType.Depth) as number;
      const scale = (depth + extendedSize) / depth;
      this.config.output.node.scaling.z = scale;
      if (this.getBlockRow().getType() === 'Top') this.config.output.node.position.z = -this.getBlockRow().getDepthExtended() / 10;
    } else {
      this.config.output.node.scaling.z = 1;
    }
  }

  public refreshVisuals() {
    const isMark = this.get(PropertyType.Mark) as boolean;
    const isMarkFull = this.get(PropertyType.MarkFull) as boolean;
    const isLabel = this.get(PropertyType.Label) as boolean;

    // Clear old output
    {
      const children = this.config.output.visuals.getChildren();
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        child.dispose();
      }
    }
    const width = this.get(PropertyType.Width) as number;
    const depth = this.get(PropertyType.Depth) as number;

    const bounds = this.getBounds();
    // Add Mark
    this.config.output.mark = MarkUtils.buildMark({
      width: bounds.width,
      height: bounds.height,
      depth: bounds.depth,
      offset: new BABYLON.Vector3(bounds.x.min, bounds.y.min, bounds.z.max),
      parent: this.config.output.visuals
    });
    this.config.output.mark.setEnabled(isMark);

    if (this.get(PropertyType.Rotate)) {
      const markLabel = LabelUtils.drawLabel(this.getScene().getMaterialByName('_connect_label'), width / 10, Orientation.Top, {
        depth: 20,
        lineMaterial: LabelUtils.lineMaterialBlue
      });
      markLabel.parent = this.config.output.visuals;
      markLabel.position = new BABYLON.Vector3(0, 0, 0);
      this.config.output.connectLabel = markLabel;
    } else {
      const markLabel = LabelUtils.drawLabel(this.getScene().getMaterialByName('_connect_label'), width / 10, Orientation.Bottom, {
        depth: 20,
        lineMaterial: LabelUtils.lineMaterialBlue
      });
      markLabel.parent = this.config.output.visuals;
      markLabel.position = new BABYLON.Vector3(0, 0, -(depth / 10));
      this.config.output.connectLabel = markLabel;
    }
    this.config.output.connectLabel.setEnabled(isMark || this._isConnectLabel);
    // Add Full Mark
    this.config.output.markFull = MarkUtils.buildFullMark({
      width: bounds.width,
      height: bounds.height,
      depth: bounds.depth,
      offset: new BABYLON.Vector3(bounds.x.min, bounds.y.min, bounds.z.max),
      parent: this.config.output.visuals
    });
    this.config.output.markFull.setEnabled(isMarkFull);
    // Add Label
    if (this.get(PropertyType.Rotate)) {
      this.config.output.label = LabelUtils.drawLabel('' + width, width / 10, Orientation.Top);
      this.config.output.label.parent = this.config.output.visuals;
      this.config.output.label.position.z = 0;
    } else {
      this.config.output.label = LabelUtils.drawLabel('' + width, width / 10, Orientation.Bottom);
      this.config.output.label.parent = this.config.output.visuals;
      this.config.output.label.position.z = -(depth / 10);
    }
    this.config.output.label.setEnabled(isLabel);
  }

  dispose() {
    this.config.model.optionNodes.dispose();
    super.dispose();
  }
}
