import { useContext, useEffect, useRef, useState } from 'react';
import { EditorContext, LangContext } from 'context';
import { HandleType } from '../../../../../../configuration/Block';
import { Device } from 'types';
import Localizations from 'languages';
import { useHandrailDevices } from 'page/Editor/Menu/Menus/MarineMeister/Partials/Global/hooks/useHandrailDevices';
import { useBorderDevices } from 'page/Editor/Menu/Menus/MarineMeister/Partials/Global/hooks/useBorderDevices';

const OPTIONS_FULL: HandleType[] = ['Top', 'Bottom', 'Both', 'Full'];
const OPTIONS: HandleType[] = ['Top', 'Bottom', 'Both'];

const UPDATE_TIMEOUT = 500;

const STANDARD_BORDER = {
  de: 'Überstand 50 mm mit 90° Ecke(n) ',
  en: 'Overstand 50 mm with 90° corner(s)',
  // TODO: Add czech translation when available
  cz: 'Overstand 50 mm with 90° corner(s)',
  // TODO: Add fr translation when available
  fr: 'Overstand 50 mm with 90° corner(s)'
};

const UPTURN_STYLE = 'BorderAngular_upturn';

export const useGlobalSettings = () => {
  const { selectedMarineMeister } = useContext(EditorContext);
  const { langId, lang } = useContext(LangContext);
  const { handrailNames, handrails } = useHandrailDevices();
  const { borderNames, borders } = useBorderDevices();
  const didMount = useRef(false);
  const [updating, setUpdating] = useState(false);

  // Select values
  const [selectedHandlebar, setSelectedHandlebar] = useState('');
  const [selectedBorderRight, setSelectedBorderRight] = useState(STANDARD_BORDER[lang] ?? STANDARD_BORDER.de);
  const [selectedBorderLeft, setSelectedBorderLeft] = useState(STANDARD_BORDER[lang] ?? STANDARD_BORDER.de);

  const [handleType, setHandleType] = useState<HandleType | ''>('');

  const handle = handrails.find((hR: Device) => hR.translations[langId].name === selectedHandlebar);

  const [isBorderLeftUpturned, setIsBorderLeftUpturned] = useState(false);
  const [isBorderRightUpturned, setIsBorderRightUpturned] = useState(false);

  const canHaveFullHandleType = handle && handle?.style?.includes('HandleRound');
  const handleTypeOptions: HandleType[] = canHaveFullHandleType ? OPTIONS_FULL : OPTIONS;

  const handleTypeOptionsLabels = handleTypeOptions.map(o => {
    return { value: o, label: Localizations[o][lang] };
  });

  useEffect(() => {
    return () => {
      didMount.current = false;
    };
  }, [selectedMarineMeister]);

  useEffect(() => {
    const currentHandle = selectedMarineMeister?.getHandleType();
    if (!didMount.current) {
      setHandleType(currentHandle);
      didMount.current = true;
    } else {
      if (handleType && handleType !== currentHandle) {
        selectedMarineMeister?.setHandleType(handleType);
      }
    }
  }, [handleType, setHandleType, selectedMarineMeister, didMount]);

  useEffect(() => {
    if (!canHaveFullHandleType && handleType === 'Full') {
      selectedMarineMeister?.setHandleType('Both');
      setHandleType('Both');
    }
  }, [handleType, canHaveFullHandleType, setHandleType]);

  // Set Handlebar Selectboxes initial value to value of current config
  useEffect(() => {
    if (!didMount.current) {
      setUpdating(true);
      if (selectedHandlebar && selectedHandlebar !== Localizations['none'][lang]) {
        const current = selectedMarineMeister?.getHandle();

        if (!current || (current && current.translations[langId].name !== selectedHandlebar)) {
          const newBar = handrails.find((hR: Device) => hR.translations[langId].name === selectedHandlebar);
          selectedMarineMeister?.setHandle(newBar);
        }
      } else if (selectedHandlebar !== '') {
        if (selectedMarineMeister?.getHandle() !== null) {
          selectedMarineMeister?.setHandle(null);
        }
      }
      setUpdating(false);
    }
  }, [selectedHandlebar, handrails]);

  useEffect(() => {
    if (didMount.current) {
      setUpdating(true);

      const leftBorder = selectedMarineMeister?.getBorderLeftObject();
      const newBorder = leftBorder?.translations[langId]?.description || STANDARD_BORDER[lang];
      setIsBorderLeftUpturned(leftBorder?.style === UPTURN_STYLE);

      setSelectedBorderLeft(newBorder);
      const rightBorder = selectedMarineMeister?.getBorderRightObject();
      setSelectedBorderRight(rightBorder?.translations[langId]?.description || STANDARD_BORDER[lang]);
      setIsBorderRightUpturned(rightBorder?.style === UPTURN_STYLE);

      // Griffstange
      setSelectedHandlebar(selectedMarineMeister?.getHandle() ? selectedMarineMeister.getHandle().translations[langId].name : Localizations['none'][lang]);

      setTimeout(() => {
        setUpdating(false);
      }, UPDATE_TIMEOUT);
    }
  }, [selectedMarineMeister, langId, lang]);

  const handleBorderLeftSelection = (newSelection: string) => {
    if (selectedMarineMeister) {
      const newBorderLeft = borders.find((brdr: Device) => brdr.translations[langId].description === newSelection);
      setIsBorderLeftUpturned(newBorderLeft?.style === UPTURN_STYLE);
      selectedMarineMeister.setBorderLeft(newBorderLeft);
      setSelectedBorderLeft(newSelection);
    }
  };

  const handleBorderRightSelection = (newSelection: string) => {
    if (selectedMarineMeister) {
      const newBorderRight = borders.find((brdr: Device) => brdr.translations[langId].description === newSelection);
      setIsBorderRightUpturned(newBorderRight?.style === UPTURN_STYLE);
      selectedMarineMeister.setBorderRight(newBorderRight);
      setSelectedBorderRight(newSelection);
    }
  };

  return {
    borderNames,
    borders,
    canHaveFullHandleType,
    handrailNames,
    handrails,
    handleType,
    handleTypeOptionsLabels,
    handleTypeOptions,
    isBorderLeftUpturned,
    isBorderRightUpturned,
    setHandleType,
    selectedHandlebar,
    setSelectedHandlebar,
    selectedBorderLeft,
    selectedBorderRight,
    setSelectedBorderLeft: handleBorderLeftSelection,
    setSelectedBorderRight: handleBorderRightSelection,
    updating
  };
};
