import React, { useContext, useEffect, useState } from 'react';

// Components
import { Selectbox } from 'atoms';
import Block from '../page/Editor/configuration/Block';

// Context
import { EditorContext, LangContext } from 'context';

// Lang
import Localizations from 'languages';

// Utilities
import { getMasterlinesAsArray } from 'utils';

export const BlockSelector = () => {
  const {
    menuView,
    marineMeisterView,
    masterlineView,
    modularView,
    selection,
    configuration,
    selectedMarineMeister,
    selectedMasterline,
    selectedModular,
    setSelectedMarineMeister,
    setSelectedMasterline,
    setSelectedModular
  } = useContext(EditorContext);

  const { lang } = useContext(LangContext);

  const getBlocks = (blocks: Block[]) => {
    return blocks.map((b: Block, index: number) => {
      if (menuView === 'marineMeister') {
        return `${
          b.getType() === 'Double' ? Localizations['doubleRowLabel'][lang] : `${Localizations['singleRow'][lang]} ${Localizations['wallStanding'][lang]}`
        } - ${b.getVoltage()}V - ${index + 1}`;
      }
      return `${
        b.getType() === 'Double'
          ? Localizations['doubleRowLabel'][lang]
          : `${Localizations['singleRow'][lang]} ${b.getSingleType() === 'Free' ? Localizations['freeStanding'][lang] : Localizations['wallStanding'][lang]}`
      } - Serie ${b.getType() === 'Double' ? `${b.getRowTop().getDepth()}/${b.getRowBottom().getDepth()}` : b.getRowBottom().getDepth()} - ${index + 1}`;
    });
  };

  const options =
    menuView === 'modular'
      ? getBlocks(configuration.getModular())
      : menuView === 'marineMeister'
      ? getBlocks(configuration.getMarineMeister())
      : getBlocks(getMasterlinesAsArray(configuration));

  const [index, setIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string>(options && options.length > 0 ? options[index > -1 ? index : 0] : '');

  const handleSelect = (val: string) => {
    const newIndex = options.findIndex(o => o === val);
    if (menuView === 'modular') {
      const modularBlocks = configuration.getModular();
      setSelectedModular(modularBlocks[newIndex]);
    } else if (menuView === 'masterline') {
      const masterlineBlocks = configuration.getMasterline();
      setSelectedMasterline(masterlineBlocks[newIndex]);
    } else if (menuView === 'marineMeister') {
      const marineMeisterBlocks = configuration.getMarineMeister();
      setSelectedMarineMeister(marineMeisterBlocks[newIndex]);
    }
    setIndex(newIndex);
    setSelectedOption(val);
  };

  /* Selected Option geändert, Index geändert */
  useEffect(() => {
    const findOptionIndex = () => {
      if (configuration && menuView === 'modular' && selectedModular) {
        return configuration.getModular().findIndex((b: Block) => {
          return b.getId() === selectedModular.getId();
        });
      } else if (configuration && menuView === 'masterline' && selectedMasterline) {
        return getMasterlinesAsArray(configuration).findIndex((b: Block) => {
          return b.getId() === selectedMasterline.getId();
        });
      } else if (configuration && menuView === 'marineMeister' && selectedMarineMeister) {
        return configuration.getMarineMeister().findIndex((b: Block) => {
          return b.getId() === selectedMarineMeister.getId();
        });
      } else return 0;
    };
    const newIndex = findOptionIndex();
    if (menuView === 'modular') {
      const modularBlocks = configuration.getModular();
      if (selectedModular) {
        selectedModular.getNode()?.getMark()?.setEnabled(false);
        selectedModular.getNode()?.setShowGrab(false);
      }
      if (Array.isArray(modularBlocks) && modularBlocks.length > 0) {
        if (modularBlocks.length > 1) {
          configuration.getModular()[newIndex]?.getNode()?.getMark()?.setEnabled(true);
        }
        if (modularView === 'structure') {
          configuration.getModular()[newIndex]?.getNode()?.setShowGrab(true);
        }
      }
    } else if (menuView === 'masterline') {
      const masterlineBlocks = configuration.getMasterline();
      if (selectedMasterline) {
        selectedMasterline.getNode()?.getMark()?.setEnabled(false);
        selectedMasterline.getNode()?.setShowGrab(false);
      }
      if (Array.isArray(masterlineBlocks) && masterlineBlocks.length > 0) {
        if (masterlineBlocks.length > 1) {
          configuration.getMasterline()[newIndex]?.getNode()?.getMark()?.setEnabled(true);
        }
        if (masterlineView === 'structure') {
          configuration.getMasterline()[newIndex]?.getNode()?.setShowGrab(true);
        }
      }
    } else if (menuView === 'marineMeister') {
      const marineMeisterBlocks = configuration.getMarineMeister();
      if (selectedMarineMeister) {
        selectedMarineMeister.getNode()?.getMark()?.setEnabled(false);
        selectedMarineMeister.getNode()?.setShowGrab(false);
      }
      if (Array.isArray(marineMeisterBlocks) && marineMeisterBlocks.length > 0) {
        if (marineMeisterBlocks.length > 1) {
          configuration.getMarineMeister()[newIndex]?.getNode()?.getMark()?.setEnabled(true);
        }
        if (marineMeisterView === 'structure') {
          configuration.getMarineMeister()[newIndex]?.getNode()?.setShowGrab(true);
        }
      }
    }
    setSelectedOption(options[newIndex]);

    return () => {
      selectedModular?.getNode()?.getMark()?.setEnabled(false);
      selectedModular?.getNode()?.setShowGrab(false);
      selectedMasterline?.getNode()?.setShowGrab(false);
      selectedMasterline?.getNode()?.getMark()?.setEnabled(false);
      selectedMarineMeister?.getNode()?.setShowGrab(false);
      selectedMarineMeister?.getNode()?.getMark()?.setEnabled(false);
    };
  }, [options, index, selection, selectedMasterline, selectedMarineMeister, setSelectedMarineMeister, selectedModular, masterlineView, modularView]);

  if (!options?.length) return null;
  return <Selectbox disabled={options.length === 1} state={selectedOption} setState={handleSelect} options={options} />;
};
