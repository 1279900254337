import React, { FC, useState, useRef, Fragment, useContext, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Style:
import './style.scss';

// Atoms:
import { AConfigSearchbar, Button, Heading, Icon, Logo } from 'atoms';

// Context:
import { EditorContext, LangContext } from 'context';

// Components:
import Table from 'components/Table';

// Partials:
import Formular from './Partials/Formular';

// Languages
import Localizations from 'languages';

// ===================================================================
interface Props extends RouteComponentProps {}

// ===================================================================
const Home: FC<Props> = () => {
  const { configuration, setConfiguration, savedConfiguration, setSavedConfiguration, selection, setSelection, fullscreen, setFullscreen, scene, setScene } =
    useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const [mode, setMode] = useState<'code' | 'new' | null>(null);
  const [showTable, setShowTable] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [query, setQuery] = useState('');
  const searchFieldRef = useRef(null);

  useEffect(() => {
    // Reset Configuration in Editor Context
    if (configuration) setConfiguration(null);
    if (savedConfiguration) setSavedConfiguration(null);
    if (selection) setSelection(null);
    if (fullscreen) setFullscreen(null);
    if (scene) setScene(null);
  }, []);

  useEffect(() => {
    if (searchActive) {
      setShowTable(true);
    }
    if (!searchActive && query.length > 0) {
      setQuery('');
    }
  }, [searchActive, query]);

  // Render:
  return (
    <Fragment>
      {/* Logo */}
      <div className="LoginPage-Logo">
        <Logo />
      </div>

      {/* Main */}
      <div className="Home" style={{ overflow: 'auto' }}>
        {/* Heading */}
        <div className="Home-Heading">
          <AConfigSearchbar pose={searchActive ? 'active' : 'inactive'}>
            <div className="Home-Heading-Searchbar">
              <Heading color="white" margin="0">
                <div className="Home-Heading-Searchbar-Inactive">
                  {Localizations['configurations'][lang]}
                  <Icon
                    onClick={() => {
                      setSearchActive(true);
                      setTimeout(() => {
                        if (searchFieldRef.current) {
                          searchFieldRef.current.focus();
                        }
                      }, 250);
                    }}
                    stroke={2.5}
                    type="search"
                    size="28px"
                    style={{ marginLeft: '1rem' }}
                  />
                </div>
              </Heading>
              <div className="Home-Heading-Searchbar-Active">
                <input
                  className="Home-Heading-Searchbar-Active-Textfield"
                  ref={searchFieldRef}
                  type="text"
                  placeholder={`${Localizations['search'][lang]} ...`}
                  onChange={e => setQuery(e.target.value)}
                  value={query}
                />
                <Icon onClick={() => setSearchActive(false)} type="close" size="1.25rem" />
              </div>
            </div>
          </AConfigSearchbar>
        </div>

        <div className="w-100 h-100" style={{ paddingTop: '10rem' }}>
          <div className="flex-col align-center w-100 h-100" style={{ overflow: 'hidden' }}>
            {/* Formular */}
            <Formular mode={mode} setMode={(mode: null | 'code' | 'new') => setMode(mode)} lang={lang} />
            <Button btnType="hollow" margin="1rem 0 0 0" onClick={() => setShowTable(!showTable)} padding=".75rem" style={{ flexShrink: 0 }}>
              {showTable ? Localizations['hideConfigs'][lang] : Localizations['showConfigs'][lang]}
            </Button>
            {/* Table */}
            {showTable && (
              <div className="Custom-Scroll mt-2 w-100">
                <Table filter={query} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Home);
