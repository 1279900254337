import { EditorContext, LangContext } from 'context';
import Localizations from 'languages';
import Block from 'page/Editor/configuration/Block';
import { useContext, useEffect, useState } from 'react';

const VOLTAGE_OPTIONS = [400, 440];

export const useMarineMeisterStructure = () => {
  const { lang } = useContext(LangContext);
  const ROW_OPTIONS = { wallstanding: `${Localizations['singleRow'][lang]} ${Localizations['wallStanding'][lang]}`, double: Localizations['doubleRow'][lang] };
  const { configuration, setHasMarineMeister, setCanAdvanceMarineMeister, selectedMarineMeister, setSelectedMarineMeister } = useContext(EditorContext);
  const [row, setRow] = useState<string>(ROW_OPTIONS.wallstanding);
  const [voltage, setVoltage] = useState<number>(400);

  const handleRowSelection = (newRow: string) => {
    if (selectedMarineMeister) {
      setRow(newRow);
      if (newRow === ROW_OPTIONS.double) {
        if (selectedMarineMeister.getType() !== 'Double') {
          selectedMarineMeister.setType('Double');
          selectedMarineMeister.getRowTop().setDepth(850);
        }
      } else {
        if (selectedMarineMeister.getType() !== 'Single') {
          selectedMarineMeister.setType('Single', 'Wall');
        }
      }
    }
  };

  const handleVoltageSelection = (newVoltage: number) => {
    if (selectedMarineMeister) {
      selectedMarineMeister.setVoltage(newVoltage);
      setVoltage(selectedMarineMeister.getVoltage());
    }
  };

  const handleAddMarineMeister = () => {
    const newBlock = new Block({ blockType: 'MarineMeister' });
    newBlock.setId('MarineMeisterBlock-' + (configuration.getMarineMeister() ? configuration.getMarineMeister().length + 1 : 1));

    setRow(ROW_OPTIONS.wallstanding);

    newBlock.setShowLabels(configuration.isShowLabels());
    newBlock.setShowCutLabels(configuration.isShowCutLabels());
    newBlock.setShowConnections(configuration.isShowConnections());

    const marineMeisterBlocks = configuration.getMarineMeister();
    marineMeisterBlocks.push(newBlock);
    newBlock?.getNode()?.getMark()?.setEnabled(true);
    if (Array.isArray(marineMeisterBlocks) && marineMeisterBlocks.length) {
      setSelectedMarineMeister(marineMeisterBlocks[marineMeisterBlocks.length - 1]);
      setHasMarineMeister(true);
      setCanAdvanceMarineMeister(true);
    }
  };

  useEffect(() => {
    const initializeValues = () => {
      if (selectedMarineMeister) {
        setVoltage(selectedMarineMeister.getVoltage());
        setRow(selectedMarineMeister.getType() === 'Double' ? ROW_OPTIONS.double : ROW_OPTIONS.wallstanding);
      }
    };
    initializeValues();
  }, [selectedMarineMeister]);

  useEffect(() => {
    const marineMeisters = configuration.getMarineMeister();
    if (marineMeisters?.length > 0) {
      setSelectedMarineMeister(marineMeisters[0]);
    }
  }, []);

  return {
    row,
    handleRowSelection,
    handleAddMarineMeister,
    ROW_OPTIONS: Object.values(ROW_OPTIONS),
    voltage,
    handleVoltageSelection,
    VOLTAGE_OPTIONS
  };
};
