import React from 'react';

import './style.scss';

interface Props {
  pose: 'exit' | 'enter';
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  isSpacer?: boolean;
}

export const ATableRow: React.FC<Props> = ({ children, isSpacer, pose, onMouseLeave, onMouseOver }) => {
  if (isSpacer) {
    return (
      <tr className={pose === 'exit' ? 'TableRow' : 'TableRow-Enter'} style={{ height: '2px', background: 'white' }}>
        {children}
      </tr>
    );
  }

  return (
    <tr
      className={pose === 'exit' ? 'TableRow' : 'TableRow-Enter' + ` ${onMouseOver ? 'TableRow-Hover' : ''}`}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </tr>
  );
};

/* style={{ height: '2px', background: color }} */
