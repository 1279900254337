import { Button } from 'atoms';
import { LoadingSpinner } from 'components';
import { LangContext } from 'context';
import Localizations from 'languages';
import { useContext, useEffect, useRef } from 'react';
import { useOrganizationContext } from '../../context/OrganizationDataProvider';
import { useDisclosure, useUpdateOrganization } from '../../hooks';
import { EditDialog, TableBody, TableHeader } from './partials';

import './style.scss';

export const UsersTable: React.FC = () => {
  const mounted = useRef(false);
  const { loading } = useUpdateOrganization();
  const { selectedUser, setSelectedUser, refetchData } = useOrganizationContext();
  const { isOpen, handleClose, handleOpen } = useDisclosure();
  const { lang } = useContext(LangContext);

  const isEditDialogOpen = !!selectedUser;

  useEffect(() => {
    if (!isEditDialogOpen && mounted.current) {
      refetchData();
    } else {
      mounted.current = true;
    }
  }, [isEditDialogOpen]);

  return (
    <>
      <table className="UsersTable">
        <TableHeader />
        {loading ? <LoadingSpinner /> : <TableBody />}
      </table>
      <br /> <br />
      <Button btnType="hollow" onClick={handleOpen}>
        {Localizations['addUser'][lang]}
      </Button>
      {isEditDialogOpen && <EditDialog abort={() => setSelectedUser(null)} user={selectedUser} />}
      {isOpen && <EditDialog abort={handleClose} user={null} />}
    </>
  );
};
