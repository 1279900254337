import React, { FC, useContext } from 'react';

// Context:
import { LangContext } from 'context';

// Styles
import { colors } from 'styles/theme';

// Atoms:
import { Heading } from 'atoms';

// Languages:
import Localizations from 'languages';

// ===================================================================
interface Props {
  tab: 'share' | 'send' | 'download';
  setTab: (tab: 'share' | 'send' | 'download') => void;
}

// ===================================================================
export const TabBar: FC<Props> = ({ tab, setTab }) => {
  const { lang } = useContext(LangContext);

  const TabBar = {
    width: '60%',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  };

  const TabBarItem = {
    padding: '0 0 0.25rem',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer'
  };

  return (
    <div style={TabBar}>
      <button
        style={{
          ...TabBarItem,
          borderBottom: tab === 'share' ? '2px solid ' + colors.dark : 'none'
        }}
        onClick={() => setTab('share')}
      >
        <Heading fontSize="0.9rem" color="dark" fontWeight="bold" margin="0 0.5rem">
          {Localizations['share'][lang].toUpperCase()}
        </Heading>
      </button>
      <button
        style={{
          ...TabBarItem,
          borderBottom: tab === 'send' ? '2px solid ' + colors.dark : 'none'
        }}
        onClick={() => setTab('send')}
      >
        <Heading fontSize="0.9rem" color="dark" fontWeight="bold" margin="0 0.5rem">
          {Localizations['send'][lang].toUpperCase()}
        </Heading>
      </button>
      <button
        style={{
          ...TabBarItem,
          borderBottom: tab === 'download' ? '2px solid ' + colors.dark : 'none'
        }}
        onClick={() => setTab('download')}
      >
        <Heading fontSize="0.9rem" color="dark" fontWeight="bold" margin="0 0.5rem">
          {Localizations['download'][lang].toUpperCase()}
        </Heading>
      </button>
    </div>
  );
};
