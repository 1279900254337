import { EditorContext, LangContext } from 'context';
import Localizations from 'languages';
import { flexiChefSubtypes } from 'page/Editor/Menu/Menus/Flexichef/Hooks';
import { UsedMasterlineSubtypeDevices } from 'page/Editor/Menu/Menus/Masterline/Partials/Substructures';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import FreeItem from 'page/Editor/configuration/FreeItem';
import { useContext, useEffect, useState } from 'react';
import { Device, Subtype } from 'types';
import { post } from 'utils';

type Args = {
  setSelectedOven: React.Dispatch<React.SetStateAction<any>>;
  setMergeMode: React.Dispatch<React.SetStateAction<boolean>>;
  setUsedDevices: React.Dispatch<React.SetStateAction<UsedMasterlineSubtypeDevices>>;
  somethingSelected: React.MutableRefObject<boolean>;
};

export const useSelectionChange = ({ setMergeMode, setSelectedOven, setUsedDevices, somethingSelected }: Args) => {
  const { lang, langId } = useContext(LangContext);

  const { selection } = useContext(EditorContext);

  const [isLoading, setIsLoading] = useState(false);
  const [selectionType, setSelectionType] = useState<'BlockItem' | 'BlockGroup'>(null);

  const existingSubtypes = [
    'WingedDoor',
    'Hygiene',
    'AngularRail',
    'LowerDrawer',
    'HeatingCabinet',
    'HeatingDrawer',
    'EmptyingSystem',
    'EOven',
    'GOven',
    'Cooling',
    'OpenSubstructure',
    'WasteDrawer',
    'GNFrame',
    'WarmingDrawer',
    'WarmingDevice',
    'IntermediatePlate',
    ...flexiChefSubtypes
  ];

  const [availableSubtypes, setAvailableSubtypes] = useState<Subtype[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);

  // Called when SELECTION changes
  // Fetches Available Subtypes
  // Updates usedDevices
  // Updates selctedOven
  useEffect(() => {
    const width = selection instanceof BlockItem ? selection.getWidthSubstructure() : selection instanceof BlockGroup ? selection.getWidthSubstructure() : 0;
    const depth = selection instanceof BlockItem ? selection.getBlockRow().getDepth() : selection instanceof BlockGroup ? selection.getParent().getDepth() : 0;

    // Special case of 1200 width && E- or GOven available:
    const caseWideOven =
      (selection instanceof BlockItem || selection instanceof BlockGroup) &&
      width === 1200 &&
      (selection.compatibleWithEquipment(Subtype.EOven) || selection.compatibleWithEquipment(Subtype.GOven));

    const checkSubtypesSpecial = async () => {
      setIsLoading(true);
      // Subtypes
      const tempAvailableSubtypes: Subtype[] = [];
      const tempAvailableDevices: Device[] = [];

      const ovenRequest = async () => {
        const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
          data: {
            type: 'masterline',
            width: 800,
            depth,
            subtypes: ['EOven', 'GOven']
          }
        });
        if (data) {
          data.forEach((result: Device) => {
            if (
              !tempAvailableSubtypes.includes(result.subtype) &&
              (selection instanceof BlockItem || selection instanceof BlockGroup) &&
              // @ts-ignore
              selection.compatibleWithEquipment(Subtype[result.subtype])
            ) {
              // @ts-ignore
              tempAvailableSubtypes.push(Subtype[result.subtype]);
              tempAvailableDevices.push(result);
            }
          });
        }
        if (error) {
          console.log(error);
        }
      };
      const otherRequest = async () => {
        const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
          data: {
            type: 'masterline',
            width: 1200,
            depth,
            subtypes: existingSubtypes.filter(e => e !== 'EOven' && e !== 'GOven')
          }
        });
        if (data) {
          data.forEach((result: Device) => {
            if (
              !tempAvailableSubtypes.includes(result.subtype) &&
              (selection instanceof BlockItem || selection instanceof BlockGroup) &&
              // @ts-ignore
              selection.compatibleWithEquipment(Subtype[result.subtype])
            ) {
              // @ts-ignore
              tempAvailableSubtypes.push(Subtype[result.subtype]);
              tempAvailableDevices.push(result);
            }
          });
        }
        if (error) {
          console.log(error);
        }
      };

      Promise.all([await otherRequest(), await ovenRequest()])
        .then(() => {
          setIsLoading(false);
          setAvailableDevices(tempAvailableDevices);
          setAvailableSubtypes(tempAvailableSubtypes);
        })
        .catch(() => setIsLoading(false));
    };

    const checkAvailableSubtypes = async () => {
      setIsLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
        data: {
          type: 'masterline',
          width,
          depth,
          subtypes: existingSubtypes
        }
      });
      if (data) {
        // Subtypes
        const tempAvailableSubtypes: Subtype[] = [];
        const tempAvailableDevices: Device[] = [];
        data.forEach((result: Device) => {
          // @ts-ignore
          console.log(selection, result.subtype, selection.compatibleWithEquipment(Subtype[result.subtype]));
          if (
            !tempAvailableSubtypes.includes(result.subtype) &&
            (selection instanceof BlockItem || selection instanceof BlockGroup) &&
            // @ts-ignore
            selection.compatibleWithEquipment(Subtype[result.subtype])
          ) {
            // @ts-ignore
            tempAvailableSubtypes.push(Subtype[result.subtype]);
            tempAvailableDevices.push(result);
          }
        });
        setAvailableDevices(tempAvailableDevices);
        setAvailableSubtypes(tempAvailableSubtypes);
        setIsLoading(false);
      }
      if (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    if (!selection) {
      setMergeMode(false);
      setSelectionType(null);
    } else {
      if (selection instanceof BlockItem) {
        setSelectionType('BlockItem');
        !caseWideOven ? checkAvailableSubtypes() : checkSubtypesSpecial();
        setUsedDevices({
          WingedDoor: EquipmentHelper.getWingedDoor(selection) ? true : false,
          Hygiene: EquipmentHelper.getHygiene(selection) ? true : false,
          AngularRail: EquipmentHelper.getAngularRail(selection) ? true : false,
          LowerDrawer: EquipmentHelper.getLowerDrawer(selection) ? true : false,
          HeatingCabinet: EquipmentHelper.getHeatingCabinet(selection) ? true : false,
          HeatingDrawer: EquipmentHelper.getHeatingDrawer(selection) ? true : false,
          EmptyingSystem: EquipmentHelper.getEmptyingSystem(selection) ? true : false,
          Cooling: EquipmentHelper.getCooling(selection) ? true : false,
          OpenSubstructure: EquipmentHelper.getOpenSubstructure(selection) ? true : false,
          WasteDrawer: EquipmentHelper.getWasteDrawer(selection) ? true : false,
          GNFrame: EquipmentHelper.getGNFrame(selection) ? true : false,
          GNFrameSecond: EquipmentHelper.getGNFrame(selection, 1) ? true : false,
          WarmingDrawer: EquipmentHelper.getWarmingDrawer(selection) ? true : false,
          WarmingDevice: EquipmentHelper.getWarmingDevice(selection) ? true : false,
          IntermediatePlate: EquipmentHelper.getIntermediatePlate(selection) ? true : false,
          baseCoverLeft: EquipmentHelper.getBaseCover(selection) ? true : false,
          baseCoverRight: EquipmentHelper.getBaseCover(selection, 1) ? true : false,
          readyXPressLeft: EquipmentHelper.getReadyXpress(selection) ? true : false,
          readyXPressRight: EquipmentHelper.getReadyXpress(selection, 1) ? true : false,
          spaceClean: EquipmentHelper.getSpaceClean(selection) ? true : false
        });
      } else if (selection instanceof BlockGroup) {
        setSelectionType('BlockGroup');
        !caseWideOven ? checkAvailableSubtypes() : checkSubtypesSpecial();

        setUsedDevices({
          WingedDoor: EquipmentHelper.getWingedDoor(selection) ? true : false,
          Hygiene: EquipmentHelper.getHygiene(selection) ? true : false,
          AngularRail: EquipmentHelper.getAngularRail(selection) ? true : false,
          LowerDrawer: EquipmentHelper.getLowerDrawer(selection) ? true : false,
          HeatingCabinet: EquipmentHelper.getHeatingCabinet(selection) ? true : false,
          HeatingDrawer: EquipmentHelper.getHeatingDrawer(selection) ? true : false,
          EmptyingSystem: EquipmentHelper.getEmptyingSystem(selection) ? true : false,
          Cooling: EquipmentHelper.getCooling(selection) ? true : false,
          OpenSubstructure: EquipmentHelper.getOpenSubstructure(selection) ? true : false,
          WasteDrawer: EquipmentHelper.getWasteDrawer(selection) ? true : false,
          GNFrame: EquipmentHelper.getGNFrame(selection) ? true : false,
          GNFrameSecond: EquipmentHelper.getGNFrame(selection, 1) ? true : false,
          WarmingDrawer: EquipmentHelper.getWarmingDrawer(selection) ? true : false,
          WarmingDevice: EquipmentHelper.getWarmingDevice(selection) ? true : false,
          IntermediatePlate: EquipmentHelper.getIntermediatePlate(selection) ? true : false,
          baseCoverLeft: EquipmentHelper.getBaseCover(selection) ? true : false,
          baseCoverRight: EquipmentHelper.getBaseCover(selection, 1) ? true : false,
          readyXPressLeft: EquipmentHelper.getReadyXpress(selection) ? true : false,
          readyXPressRight: EquipmentHelper.getReadyXpress(selection, 1) ? true : false,
          spaceClean: EquipmentHelper.getSpaceClean(selection) ? true : false
        });
      }
    }

    if (selection && (selection instanceof BlockItem || selection instanceof BlockGroup) && EquipmentHelper.getEOven(selection)) {
      setSelectedOven(EquipmentHelper.getEOven(selection).getDeviceObject().translations[langId].name);
    }

    if (selection && (selection instanceof BlockItem || selection instanceof BlockGroup) && EquipmentHelper.getGOven(selection)) {
      setSelectedOven(EquipmentHelper.getGOven(selection).getDeviceObject().translations[langId].name);
    }

    if (
      selection &&
      (selection instanceof BlockItem || selection instanceof BlockGroup) &&
      !EquipmentHelper.getEOven(selection) &&
      !EquipmentHelper.getGOven(selection)
    ) {
      setSelectedOven(Localizations['none'][lang]);
    }

    if (selection && !somethingSelected.current) {
      somethingSelected.current = true;
    }
  }, [selection]);

  const isTeamDevice = (selection instanceof FreeItem || selection instanceof BlockItem) && selection.getDeviceObject()?.style.includes('-');

  return {
    availableDevices,
    availableSubtypes,
    isLoading,
    isTeamDevice,
    selectionType
  };
};
