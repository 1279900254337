import React, { FC, useContext, useEffect, useMemo, useState, Fragment } from 'react';

// Assets
import Collapse from 'assets/svg/Icon_View_Collapse.svg';

// Components
import { Selectbox } from 'atoms';
import { DeviceTileFull, LoadingSpinner } from 'components';
import { ContextMenu, Subcategory } from 'page/Editor/Menu/Shared';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localization from 'languages';

// Styles
import './style.scss';

// Types:
import { Category, Device, DevicePosition } from 'types';

// Utils
import { createDeviceList, post } from 'utils';

// ===================================================================

interface Props {
  selectedCategory: Category;
  setSelection: (device: Device) => void;
  setSelectedCategory: (category: Category) => void;
}

// ===================================================================
export const SelectedCategoryFull: FC<Props> = ({ selectedCategory }) => {
  const { lang, langId } = useContext(LangContext);

  const { menuView, setFullscreen, seriesFilter, setSeriesFilter, selectedMarineMeister, selectedMasterline, selectedModular } = useContext(EditorContext);
  const block = menuView === 'modular' ? selectedModular : menuView === 'masterline' ? selectedMasterline : null;

  const connectionOptions = [Localization['all'][lang], '1 NPE AC', '2 NPE AC', '3 NPE AC'];
  const operatingModeOptions = [Localization['all'][lang], 'Electro', 'Gas', 'Steam'];
  const seriesOptions = [Localization['all'][lang], '700', '850'];

  const [connectionFilter, setConnectionFilter] = useState(connectionOptions[0]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceList, setDeviceList] = useState<DevicePosition[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(false);
  const [operatingFilter, setOperatingFilter] = useState(operatingModeOptions[0]);
  const subCategories = selectedCategory.children ? selectedCategory.children : [];

  const voltage = useMemo(() => (menuView === 'marineMeister' ? selectedMarineMeister?.getVoltage() : 0), [menuView, selectedMarineMeister]);
  const key = `devices-category-${selectedCategory ? selectedCategory.id : ''}${menuView}-${voltage}`;

  const closeFullscreen = () => {
    setFullscreen(null);
  };

  useEffect(() => {
    setLoading(true);
    const fetchDevices = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/devices`, {
        data: {
          type: menuView,
          category: {
            id: selectedCategory.id
          },
          voltage: menuView === 'marineMeister' ? selectedMarineMeister?.getVoltage() : undefined
        }
      });
      if (data) {
        setDevices(data);
        setFilteredDevices(data);
        sessionStorage.setItem(key, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedDevices = sessionStorage.getItem(key);
    if (cachedDevices) {
      setDevices(JSON.parse(cachedDevices));
      setFilteredDevices(JSON.parse(cachedDevices));
    } else {
      fetchDevices();
    }
    createDeviceList(block, setDeviceList);

    setLoading(false);
  }, []);

  useEffect(() => {
    if (devices) {
      let newFilteredDevices = [...devices];
      if (connectionFilter != connectionOptions[0]) {
        newFilteredDevices = newFilteredDevices.filter(d => d.energy.electroConditions.includes(connectionFilter));
      }
      if (operatingFilter != operatingModeOptions[0]) {
        newFilteredDevices = newFilteredDevices.filter(d => d.energy.source == operatingFilter);
      }
      if (seriesFilter === '850' || seriesFilter === '700') {
        newFilteredDevices = newFilteredDevices.filter(d => d.model.depths.includes(parseInt(seriesFilter)));
      }
      setFilteredDevices(newFilteredDevices);
    }
  }, [connectionFilter, devices, operatingFilter, seriesFilter]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <div style={{ paddingBottom: '54px' }}>
        <ContextMenu>
          <div className="Functional-View-ContextMenu">
            <div className="Functional-View-ContextMenu-Series"></div>
          </div>
        </ContextMenu>
      </div>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <img
          alt="close fullscreen"
          onClick={closeFullscreen}
          src={Collapse}
          style={{ cursor: 'pointer', height: '35px', marginTop: '-9px', marginRight: '-9px', width: '35px' }}
        />
      </div>
      <div className="SelectedCategoryFull">
        <div className="SelectedCategoryFull-Filters" style={{ overflow: 'hidden', maxHeight: '500px' }}>
          <span className="SelectedCategoryFull-Filters-Header">{`${selectedCategory.translations[langId].name} (${
            selectedCategory.deviceCount[seriesFilter === '700' ? '_700' : seriesFilter === '850' ? '_850' : 'total']
          })`}</span>
          {menuView !== 'spaceCombi' &&
            menuView !== 'flexiChef' &&
            selectedCategory.translations[langId].name &&
            !selectedCategory.translations[langId].name.toLowerCase().includes('flexichef') && (
              <>
                <div className="SelectedCategoryFull-Filters-Wrapper">
                  <span>Serie</span>
                  <Selectbox type="second" state={seriesFilter} setState={setSeriesFilter} options={seriesOptions} />
                </div>
                <div className="SelectedCategoryFull-Filters-Wrapper">
                  <span>Anschlusswerte</span>
                  <Selectbox type="second" state={connectionFilter} setState={setConnectionFilter} options={connectionOptions} />
                </div>
                <div className="SelectedCategoryFull-Filters-Wrapper">
                  <span>Betriebsart</span>
                  <Selectbox type="second" state={operatingFilter} setState={setOperatingFilter} options={operatingModeOptions} />
                </div>
              </>
            )}
        </div>
        <div className="SelectedCategoryFull-Devices" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
          {filteredDevices &&
            filteredDevices.map((device, index) => {
              const deviceIndex = deviceList.findIndex(dL => dL.device.id === device.id);
              return (
                <DeviceTileFull
                  key={`${device.id}-Tile`}
                  deselectCategory={closeFullscreen}
                  device={device}
                  amount={deviceIndex >= 0 ? deviceList[deviceIndex].amount : 0}
                />
              );
            })}
          {subCategories &&
            subCategories.length > 0 &&
            subCategories.map((sC: Category) => {
              if (
                sC.deviceCount &&
                ((seriesFilter === '850' && sC.deviceCount._850 > 0) ||
                  (seriesFilter === '700' && sC.deviceCount._700 > 0) ||
                  (seriesFilter === 'all' && sC.deviceCount.total > 0))
              ) {
                return (
                  <Subcategory
                    key={`${sC.id}-Subcategory`}
                    operatingFilter={operatingFilter}
                    connectionFilter={connectionFilter}
                    category={sC}
                    setSeries={setSeriesFilter}
                  />
                );
              } else {
                return <div key={`${sC.id}-Subcategory`}></div>;
              }
            })}
        </div>
      </div>
    </Fragment>
  );
};
