import { useEffect, useState } from 'react';
import { Device } from 'types';
import { post } from 'utils';

export const useHandrailDevices = () => {
  const [handrails, setHandrails] = useState<Device[]>([]);
  const [handrailNames, setHandrailNames] = useState<string[]>([]);
  // Fetch options for Handrails and Borders
  useEffect(() => {
    const handrailKey = `glbl-hndrl-dvcs-mm`;
    const getHandrails = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'marineMeister',
          device: {
            subtype: 'HandRail'
          }
        }
      });
      if (data) {
        setHandrails(data);
        setHandrailNames([...data.map((d: Device) => d.translations)]);
        sessionStorage.setItem(handrailKey, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };

    const cachedHandrails = sessionStorage.getItem(handrailKey);
    if (cachedHandrails) {
      const parsedHandrails = JSON.parse(cachedHandrails);
      setHandrails(parsedHandrails);
      setHandrailNames([...parsedHandrails.map((d: Device) => d.translations)]);
    } else {
      getHandrails();
    }
  }, []);

  return {
    handrailNames,
    handrails
  };
};
